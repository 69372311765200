import { ArrowUpRightIcon, EnvelopeIcon, StarIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import Badge from "../Components/ui/Badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee } from "@fortawesome/pro-duotone-svg-icons";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const DefaultIconColors = {
  category: "default",
  bgColor: "bg-gray-100",
  iconColor: "text-gray-700",
};

const SpecialIconColors = {
  category: "special",
  bgColor: "bg-special-100",
  iconColor: "text-special-700",
};

const IconColors = [
  {
    category: "blog",
    bgColor: "bg-blog-100",
    iconColor: "text-blog-700",
  },
  {
    category: "website",
    bgColor: "bg-website-100",
    iconColor: "text-website-700",
  },
  {
    category: "copywriting",
    bgColor: "bg-website-100",
    iconColor: "text-website-700",
  },
  {
    category: "social",
    bgColor: "bg-social-100",
    iconColor: "text-social-700",
  },
  {
    category: "marketing",
    bgColor: "bg-marketing-100",
    iconColor: "text-marketing-600",
  },
  {
    category: "ecommerce",
    bgColor: "bg-ecommerce-100",
    iconColor: "text-ecommerce-700",
  },
  {
    category: "more",
    bgColor: "bg-more-100",
    iconColor: "text-more-700",
  },
  {
    category: "special",
    bgColor: "bg-special-100",
    iconColor: "text-special-700",
  },
];

export default function ToolIcon(props) {
  const { category, size, className, customColor, ...otherProps } = props;

  // Start from default colors
  let iconColors = DefaultIconColors;

  if (customColor) {
    iconColors = SpecialIconColors;
  } else {
    // Find the colors for the category
    let categoryColors = IconColors.find((color) => {
      return color.category === category;
    });

    // Set category colors, else use default gray colors
    if (categoryColors) iconColors = categoryColors;
  }

  let iconPadding;
  let iconSizeStyle;

  switch (size) {
    case "sm":
      iconPadding = "p-2 px-3";
      iconSizeStyle = "h-4 w-4";
      break;
    case "md":
      iconPadding = "p-3 px-4";
      iconSizeStyle = "h-5 w-5";
      break;
    case "lg":
      iconPadding = "p-4";
      iconSizeStyle = "h-6 w-6";
      break;
    default:
      iconPadding = "p-3";
      iconSizeStyle = "h-5 w-5";
      break;
  }

  return (
    <>
      {props.icon && (
        <span {...otherProps} className={classNames(iconColors.bgColor, iconPadding, className, "self-start rounded-md aspect-square")}>
          <FontAwesomeIcon icon={props.icon} className={iconColors.iconColor}></FontAwesomeIcon>
          {/*   <props.icon className={classNames(iconColors.iconColor, iconSizeStyle, "flex-shrink-0")} aria-hidden="true" /> */}
        </span>
      )}
    </>
  );
}
