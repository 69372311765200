import React from "react";
import ReactDOM from "react-dom";

import Notification, { Color } from "./Notification";
import createContainer from "./createContainer";
import NotificationsManager from "./NotificationManager";

const containerElement = createContainer();
let notify;

ReactDOM.render(
  <NotificationsManager
    setNotify={(notifyFn) => {
      notify = notifyFn;
    }}
  />,
  containerElement
);

export { Notification, Color };

export function info(children, autoClose) {
  return notify({
    color: Color.info,
    children: <div>{children}</div>,
    autoClose,
  });
}

export function success(children, autoClose) {
  return notify({
    color: Color.success,
    children: <div>{children}</div>,
    autoClose,
  });
}

export function warning(children, autoClose) {
  return notify({
    color: Color.warning,
    children: <div>{children}</div>,
    autoClose,
  });
}

export function error(children, autoClose) {
  return notify({
    color: Color.error,
    children: <div>{children}</div>,
    autoClose,
  });
}
