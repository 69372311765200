import { PlusIcon } from "@heroicons/react/20/solid";

export default function SectionTitle(props) {
  return (
    <div>
      <div className="pb-6">
        <h3 className="text-xl font-extrabold leading-6 text-gray-900 dark:text-gray-100">{props.title}</h3>
        <p className="hidden mt-2 max-w-4xl text-lg font-normal text-gray-400">These are the tools we recommend based on your interests.</p>
      </div>
    </div>
  );
}
