import { Bars3Icon, ExclamationTriangleIcon, MinusIcon, PlusIcon } from "@heroicons/react/20/solid";
import React, { Fragment, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Label from "./Label";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { PAGE_STRUCTURE_VALUES } from "../../data/languages";
import "./DynamicDropdownList.css";

export default function DynamicDropdownList(props) {
  const placeholder = props.placeholder ? props.placeholder : "Subheading";
  const values = props.values ? props.values : PAGE_STRUCTURE_VALUES;
  const [selected, setSelected] = useState(values[0]);
  const [closeAll, setCloseAll] = useState(false);

  const maxItems = props.maxItems ? props.maxItems : 99;

  const defaultList = [{ id: "0", value: "" }];

  const [itemList, setItemList] = useState(defaultList);
  const [nextId, setNextId] = useState(itemList.length + 1); // Keep track of the new IDs (start at the lenght of the list)

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  // Function to update list on drop
  const handleDrop = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    var updatedList = [...itemList];
    // Remove dragged itemn
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State
    setItemList(updatedList);
  };

  function addEmptyItem() {
    var updatedList = [...itemList];
    let newIdString = nextId.toString();
    setNextId(nextId + 1);
    updatedList.push({ id: newIdString, value: "" });
    setItemList(updatedList);
  }

  function removeItemHandler(index) {
    var updatedList = [...itemList];
    updatedList.splice(index, 1);
    setItemList(updatedList);
  }

  return (
    <>
      {/* Label */}
      <div className="mb-0 flex items-center justify-start gap-1">
        <Label name={props.name} required={props.required} labelHelpToolTip={props.labelHelpToolTip}>
          {props.label}
        </Label>
        {props.minItems && props.maxItems && (
          <div className="text-sm text-gray-400 font-semibold">
            (min {props.minItems} / max {props.maxItems})
          </div>
        )}
      </div>

      {/* Dynamic drag and drop list */}
      <div className="flex items-center  justify-center text-center">
        <DragDropContext onDragStart={() => setCloseAll(true)} onDragEnd={handleDrop}>
          <Droppable droppableId="list-container">
            {(provided) => (
              <div className="flex w-full flex-col" {...provided.droppableProps} ref={provided.innerRef}>
                {itemList.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <div className="flex group items-center w-100 my-1" ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps}>
                        {itemList.length > 1 && <Bars3Icon className="h-5 w-5 mr-3" aria-hidden="true" />}

                        <div className="grow">
                          <div className="relative w-100 rounded-md shadow-sm">
                            <div className="select-clean relative mt-1">
                              <select id="sectionSelect" name="sectionSelect" className="appearance-none mt-1 block w-full rounded-md bg-gray-200 border-gray-300 py-2 pl-3 pr-10 text-base focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm" defaultValue="-- Select section --">
                                {values.map((item) => (
                                  <option key={item.id} value={item}>
                                    {item.name}
                                  </option>
                                ))}
                              </select>

                              <div className="absolute inset-y-0 right-0 flex items-center pr-2">
                                <div className="flex">
                                  {index === itemList.length - 1 && index < maxItems - 1 && (
                                    <button type="button" className="inline-flex items-center rounded border border-transparent p-1 text-gray-800 hover:cursor-pointer hover:bg-gray-200 hover:text-gray-900">
                                      <PlusIcon onClick={addEmptyItem} className="h-5 w-5" aria-hidden="true" />
                                    </button>
                                  )}

                                  {itemList.length > 1 && (
                                    <button type="button" className="inline-flex items-center rounded border border-transparent p-1 text-gray-800 hover:cursor-pointer hover:bg-gray-200 hover:text-gray-900">
                                      <MinusIcon
                                        onClick={() => {
                                          removeItemHandler(index);
                                        }}
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>

                            <input type="text" name="account-number" id="account-number" placeholder={placeholder} className="hidden" defaultValue={item.value} />
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      {/* Minimum entries message */}
      {props.minItems && itemList.length < props.minItems && (
        <div className="flex items-center mt-1 text-gray-500 text-xs font-medium">
          <ExclamationTriangleIcon className="h-4 w-4 text-orange-400 mt-0.5 mr-1" />
          Select minimum {props.minItems} {props.itemType ? props.itemType : "option"}s.
        </div>
      )}
    </>
  );
}
