import ToolPanel from "./ToolPanel";
import { TOOLS } from "../data/tools";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ToolsGrid(props) {
  return (
    <>
      {/* Tool panels */}
      <ul className="grid pb-8 grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {props.toolList.map((tool) => (
          <ToolPanel key={tool.id} id={tool.id} url={tool.url} category={tool.category} icon={tool.icon} name={tool.name} description={tool.description} favorite={tool.favorite} tool={tool} />
        ))}
      </ul>
    </>
  );
}
