export default function SectionTitle(props) {
  return (
    <div className="mt-1">
      <div className="pb-2">
        <div className="-ml-2 -mt-2 md:flex flex-wrap items-baseline">
          <h3 className="ml-2 mt-2 font-extrabold text-2xl lg:text-3xl leading-6 text-gray-900 font-jakarta dark:text-white">{props.title}</h3>
          {props.subtitle && (
            <p className="ml-2 mt-1 truncate font-semibold text-lg lg:text-2xl text-gray-400 dark:text-slate-400">
              <span className="hidden md:inline mr-1">—</span>
              {props.subtitle}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
