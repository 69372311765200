import { useState } from "react";
import Label from "./Label";

const inputStyleClasses = "block w-full placeholder:text-gray-400 placeholder:font-medium bg-gray-100 rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm dark:bg-slate-800 dark:border-slate-700 dark:placeholder:text-gray-500 dark:text-white dark:hover:bg-slate-700 dark:focus:bg-slate-700 dark:hover:border-slate-600 dark:focus:border-slate-600";

export default function Input(props) {
  const [touched, setTouched] = useState(false);
  const isValid = props.value && props.value !== "";

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <>
      <div className="mb-1 block">
        <Label name="title" required="true" labelHelpToolTip={props.labelHelpToolTip}>
          {props.label}
        </Label>
      </div>

      <input value={props.value || ""} onChange={props.onChange} type="text" required name={props.name} id={props.id} className={classNames(!isValid && touched && "border-red-500", inputStyleClasses)} placeholder={props.placeholder} aria-describedby="title-required" ref={props.ref} onBlur={() => setTouched(true)} />
    </>
  );
}
