import { Fragment, useState, useContext } from "react";
import { NavLink, Link } from "react-router-dom";
import { ArrowRightIcon, Squares2X2Icon, XMarkIcon } from "@heroicons/react/24/solid";
import { DocumentTextIcon, BookmarkIcon, DocumentCheckIcon, ShieldCheckIcon, HeartIcon, Square3Stack3DIcon, HomeIcon } from "@heroicons/react/24/outline";
import Panel from "../Components/Panel";
import ProjectSelect from "./ProjectSelect";
import ProgressBar from "../Components/ui/ProgressBar";
import TOOLS from "../data/tools";
import { useCreditsContext } from "../data/CreditsContext";
import { AppDataContext } from "../data/AppHelpers";

const navigation = [
  { name: "Dashboard", href: "#", icon: Squares2X2Icon, current: true },
  { name: "All Tools", href: "#", icon: Square3Stack3DIcon, current: false },
  { name: "My Content", href: "#", icon: DocumentTextIcon, current: false },
];

const footerNavigation = [
  // { name: "Blog", href: "#", icon: BookmarkIcon, current: true },
  // { name: "Privacy", href: "#", icon: ShieldCheckIcon, current: false },
  // { name: "Terms", href: "#", icon: DocumentCheckIcon, current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Sidebar(props) {
  const { credits, totalCredits } = useCreditsContext();
  const { paidUserStatus } = useContext(AppDataContext);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const percentCreditsUsed = (credits * 100) / totalCredits;

  return (
    <div className="hidden md:flex h-full md:w-64 md:flex-col">
      <div className="flex overflow-x-hidden  min-h-0 flex-1 flex-col bg-gray-100 dark:bg-black">
        {/* Free credits counter */}
        {!paidUserStatus ?
            <div className="px-4 mt-8">
              <div className="flex justify-between mb-2 dark:text-white" aria-labelledby="free-trial-credits-label">
                <div className="font-bold text-sm">{props.account.description}</div>
                <div className="text-sm text-gray-500 dark:text-gray-400">
                  <b className="text-gray-900 dark:text-gray-200">{credits}</b> words left
                </div>
              </div>
              <ProgressBar bgColor="bg-green-600" completed={percentCreditsUsed}/>
            </div>
        :
            <div className="px-4 mt-8">
              <div className="flex justify-between mb-2 dark:text-white" aria-labelledby="free-trial-credits-label">
                <div className="font-bold text-sm">{props.account.description}</div>
                <div className="text-sm text-gray-500 dark:text-gray-400">
                  <b className="text-gray-900 dark:text-gray-200">Unlimited</b> words
                </div>
              </div>
            </div>
        }

        {/* Project select dropdown */}
        {/*<div className="px-3 mt-8 flex hidden lg:flex flex-shrink-0 items-center">/!*<ProjectSelect user={props.user}></ProjectSelect>*!/</div>*/}

        {/* Navigation*/}
        <div className="flex flex-1 flex-col overflow-y-auto pt-2 pb-4">
          {/* Sidebar navigation*/}
          <nav className="mt-6 flex-1 space-y-1 px-2">
            <ul className="space-y-2">
              <li>
                <NavLink to="/" className={({ isActive }) => (isActive ? "bg-white font-extrabold group text-gray-700 flex items-center px-2 py-2 text-sm font-medium rounded-md duration-200 dark:bg-slate-800 dark:text-white" : "group text-gray-600 flex items-center px-2 py-2 hover:bg-gray-200 text-sm font-medium rounded-md duration-200 dark:hover:bg-slate-900 dark:hover:text-white dark:text-slate-300")}>
                  <Squares2X2Icon className="mr-3 text-gray-700 text-primary-500 flex-shrink-0 h-6 w-6 dark:text-primary-400" aria-hidden="true" />
                  Dashboard
                </NavLink>
              </li>
              <li>
                <NavLink to="/tools" className={({ isActive }) => (isActive ? "bg-white font-extrabold group text-gray-700 flex items-center px-2 py-2 text-sm font-medium rounded-md duration-200 dark:bg-slate-800 dark:text-white" : "group flex text-gray-600  items-center px-2 py-2 hover:bg-gray-200 text-sm font-medium rounded-md duration-200  dark:hover:bg-slate-900 dark:hover:text-white dark:text-slate-300")}>
                  <Square3Stack3DIcon className="mr-3 text-gray-700 text-primary-500 flex-shrink-0 h-6 w-6 dark:text-primary-400" aria-hidden="true" />
                  All Tools ({TOOLS.length})
                </NavLink>
              </li>
              <li>
                <NavLink to="/content" className={({ isActive }) => (isActive ? "bg-white font-extrabold group  text-gray-700 flex items-center px-2 py-2 text-sm font-medium rounded-md duration-200 dark:bg-slate-800 dark:text-white" : "group flex text-gray-600  items-center px-2 py-2 hover:bg-gray-200 text-sm font-medium rounded-md duration-200  dark:hover:bg-slate-900 dark:hover:text-white dark:text-slate-300")}>
                  <DocumentTextIcon className="mr-3 text-gray-700 text-primary-500 flex-shrink-0 h-6 w-6 dark:text-primary-400" aria-hidden="true" />
                  My Content
                </NavLink>
              </li>
            </ul>
          </nav>
          <nav className="hidden mt-6 flex-1 space-y-1 px-2">
            {navigation.map((item) => (
              <a key={item.name} href={item.href} className={classNames(item.current ? "bg-white text-primary-500 font-bold" : "text-gray-700 hover:bg-gray-50 hover:text-gray-900", "group flex items-center px-2 py-2 text-sm font-medium rounded-md  duration-200")}>
                <item.icon className={classNames(item.current ? "text-primary-500" : "text-gray-500 group-hover:text-gray-500", "mr-3 flex-shrink-0 h-6 w-6")} aria-hidden="true" />
                {item.name}
              </a>
            ))}
          </nav>
        </div>

        {/* Closeable panel */}
        {/*<Panel isCloseable>
          <HeartIcon className="h-6 w-6 mb-4 text-primary-500 dark:text-primary-400" aria-hidden="true" />
          <h3 className="mb-3 text-sm font-bold">Friends of copymatic</h3>
          <p className="mb-4 text-sm text-gray-500 dark:text-gray-300">Receive a 30% commission on all payments for referals</p>
          <button type="button" className="inline-flex text-primary-500 font-bold items-center border border-transparent text-sm font-medium leading-4  hover:text-primary-700 focus:outline-none dark:text-primary-300">
            Join program
            <ArrowRightIcon className="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true" />
          </button>
        </Panel>*/}

        <div className="flex flex-shrink-0 border-t border-gray-200 bg-gray-100 p-4 dark:bg-transparent dark:border-slate-700">
          <nav className="flex-1 space-y-1">
            {footerNavigation.map((item) => (
              <a key={item.name} href={item.href} className="group flex items-center py-1 text-sm font-medium rounded-md text-gray-800 hover:text-gray-900 hover:underline dark:text-white dark:hover:text-white">
                <item.icon className="mr-2 flex-shrink-0 text-primary-500 h-4 w-4 dark:text-primary-400 dark:group-hover:text-primary-300 duration-200" aria-hidden="true" />
                <span className="font-semibold">{item.name}</span>
              </a>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}
