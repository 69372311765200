import { BoltIcon } from "@heroicons/react/24/solid";
import { useContext } from "react";
import { AppDataContext } from "./../data/AppHelpers";


export default function HeaderBanner(props) {

  const { appDataContext } = useContext(AppDataContext);

  let banner = getDefaultBanner();

  if (props.appData.user) {
    banner = getBanner(props.appData.user, appDataContext, props.displayInModal);
  }
  return banner;
}

function getBanner(user, appDataContext, displayInModal = false) {

  function purchaseMainProductHandle() {
    if (appDataContext.isDependencyLoaded) {
      /* global SM */
      // if (user.meta.products.includes("main_product")) {
      //   SM.client('requestInAppPurchase', ["83266c47-65f8-434e-9013-2867aa27b942"]);
      // } else {
        SM.client('requestMainProductPurchase');
      // }
    }
  }

  // const dataDiff = user.userRegistered.getTime() - Date.now().getTime();
  let banner;

  // if the user has registered in the last 24 hours
  if (user.registeredAt && user.type === "native") {
    banner = renderNativeUserFreeUpgradePromotion();
  }
  else if (user.type === "semrush") {
    banner = renderSemReushPurchaseAddon(purchaseMainProductHandle, displayInModal);
  } else {
    banner = renderNativeUserFreeUpgradePromotion();
  }

  return banner;
}

function getDefaultBanner() {

  return (
    <div>
      <div className="rounded-lg bg-gradient-to-r from-primary-500 to-primary-700 mb-8">
        <div className="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-4">
          <div className="flex flex-wrap md:flex-no-wrap items-center justify-between">
            <div className="flex w-full justify-center sm:justify-start flex-1 items-center">
              <BoltIcon className="h-6 w-6 text-white fill-yellow-500" aria-hidden="true" />

              <p className="ml-2 truncate font-medium text-white">
                <span className="lg:hidden">
                  Get 20% off if you upgrade!
                  <a href="#" className="duration-200 ml-1 hidden font-extrabold hover:text-primary-700">
                    Upgrade Now
                  </a>
                </span>
                <span className="hidden lg:inline font-light">
                  <b className="font-bold">Get 20% off</b> if you upgrade within 24h, use the code <b className="font-bold">20TODAY</b> at checkout
                </span>
              </p>
            </div>
            <div className="order-3 mt-2 w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto">
              <a href="#" className="flex duration-200 items-center justify-center rounded-md border border-transparent bg-white px-4 py-2 text-sm font-medium text-black-600 shadow-sm hover:bg-primary-50 hover:text-primary-700">
                Upgrade Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function renderNativeUserFreeUpgradePromotion() {
  return (
    <div>
      <div className="rounded-lg bg-gradient-to-r from-primary-500 to-primary-700 mb-8">
        <div className="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-4">
          <div className="flex flex-wrap md:flex-no-wrap items-center justify-between">
            <div className="flex w-full justify-center sm:justify-start flex-1 items-center">
              <BoltIcon className="h-6 w-6 text-white fill-yellow-500" aria-hidden="true" />

              <p className="ml-2 truncate font-medium text-white">
                <span className="lg:hidden">
                  Get 20% off if you upgrade!
                  <a href="#" className="duration-200 ml-1 hidden font-extrabold hover:text-primary-700">
                    Upgrade Now
                  </a>
                </span>
                <span className="hidden lg:inline font-light">
                  <b className="font-bold">Get 20% off</b> if you upgrade within 24h, use the code <b className="font-bold">20TODAY</b> at checkout
                </span>
              </p>
            </div>
            <div className="order-3 mt-2 w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto">
              <a href="#" className="flex duration-200 items-center justify-center rounded-md border border-transparent bg-white px-4 py-2 text-sm font-medium text-black-600 shadow-sm hover:bg-primary-50 hover:text-primary-700">
                Upgrade Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function renderSemReushPurchaseAddon(purchaseAddon, displayInModal = false) {
  return (
    <div>
      <div className="rounded-lg bg-gradient-to-r from-primary-500 to-primary-700 mb-8">
        <div className="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-4">
          <div className={displayInModal ? "flex flex-col p-1 items-center justify-between" : "flex flex-wrap md:flex-no-wrap items-center justify-between"}>
            <div className={displayInModal ? "flex w-full justify-center sm:justify-start flex-1 items-center mb-3" : "flex w-full justify-center sm:justify-start flex-1 items-center"}>
              <BoltIcon className="h-6 w-6 text-white fill-yellow-500" aria-hidden="true" />

              <p className="ml-2 truncate font-medium text-white">
                {/*<span className="lg:hidden">*/}
                {/*  Get 20% off if you upgrade!*/}
                {/*  <a href="#" className="duration-200 ml-1 hidden font-extrabold hover:text-primary-700">*/}
                {/*    Upgrade Now*/}
                {/*  </a>*/}
                {/*</span>*/}
                <span className="hidden lg:inline font-light">
                  Upgrade your plan to get <b className="font-bold">unlimited words</b>.
                </span>
              </p>
            </div>
            <div className="order-3 mt-2 w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto">
              {/* clicking this should bring up the in app purchase menu from semrush SDK */}
              <a onClick={purchaseAddon} href="#" className="flex duration-200 items-center justify-center rounded-md border border-transparent bg-white px-4 py-2 text-sm font-medium text-black-600 shadow-sm hover:bg-primary-50 hover:text-primary-700">
                Upgrade Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
