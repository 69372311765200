import { EnvelopeIcon } from "@heroicons/react/20/solid";
import ToolPanel from "./ToolPanel";
import { TOOLS, toolImage } from "../data/tools";

export default function ToolsRow(props) {
  const tools = props.tools.map((tool) => {
    tool.icon = toolImage(tool.name);
    return tool;
  }); // props.tools ? props.tools : TOOLS.slice(0, 4);

  return (
    <div>
      {/* Stats panels */}
      <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {tools.map((tool, i) => (
          <ToolPanel key={i} icon={toolImage(tool.name)} name={tool.name} description={tool.description} favorite={tool.favorite} category={tool.category} tool={tool} />
        ))}
      </ul>
    </div>
  );
}
