import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import { useContext, useState } from "react";
import SavedOutputsContext from "../store/saved-outputs";
import OutputList from "../Components/OutputList";
import ToolIcon from "./ToolIcon";
import { info, success, warning, error } from "../notify/Notify";
import OutputGallery from "../Components/OutputGallery";
import { ArrowUpIcon } from "@heroicons/react/24/outline";
import { SafetyDivider } from "@mui/icons-material";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function OutputPanel(props) {
  const outputType = props.outputType || "text";
  const [activeTab, setActiveTab] = useState("new");
  const [allSelected, setAllSelected] = useState(false);
  const [isCopying, setIsCopying] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const itemCount = props.formFields.find(f => f.field.name === "n").value;

  const savedOutputsCtx = useContext(SavedOutputsContext);
  savedOutputsCtx.setActiveTab("new");

  let content;

  if (props.outputList.length === 0) {
    content = <p className="text-slate-500 text-center font-medium max-w-2xl mx-auto mt-6">Generate new ideas by adding info on the left side. Hover an idea and click on "💙" to mark it as favorite.</p>;
  } else {
    content = <OutputList outputList={props.outputList} />;
  }

  function onChangeTab(tabId) {
    props.outputList.activeTab = tabId;
    console.log("Output panel tab change: ", tabId);
    savedOutputsCtx.setActiveTab(tabId);
    setActiveTab(tabId);
  }

  function selectAllHandler() {
    setAllSelected(true);
  }

  function deselectAllHandler() {
    setAllSelected(false);
  }

  //
  async function copyHandler() {
    // setIsCopying(true);
    // success("Item copied", true);
  }

  async function copyAllHandler() {
    setIsCopying(true);
    success("All items copied", true);
    setIsCopying(false);
  }

  async function saveAllHandler(event) {
    setIsSaving(true);
    const saves = props.outputList
      .filter((item) => !item.isSaved)
      .map(async (item, i) => {
        return props.eventHandlers
          .saveHandler({ target: { id: item.id } })
          .then((promise) => {
            if (promise.data.success === true) {
              return item.id;
            } else {
              return null;
            }
          });
      });

    Promise.all(saves).then((savedIds) => {
      const saveCount = savedIds.length;
      const saveSuccessCount = savedIds.filter(i => i != null).length;

      if (saveCount === saveSuccessCount) {
        success("All Items Saved", true);
      } else {
        warning("Issue with saving", true)
      }

      props.outputList.map(item => {
        if (savedIds.includes(item.id)) {
          return item.isSaved = true;
        }
      })
      setIsSaving(false);
    })

  }

  const eventHandlers = {
    copyHandler: copyHandler,
    saveHandler: props.eventHandlers.saveHandler,
    plagiarismHandler: props.eventHandlers.plagiarismHandler,
    saveAllHandler: saveAllHandler,
  };

  // TODO: Show the number of images being generated
  function imageLoadingState() {
    return (
      <>
        <div className="grid grid-cols-2 gap-4 mb-4">
          {Array.from(Array(itemCount).keys()).map((item) => (
            <div key={item} role="status" className="aspect-square  h-full w-full space-y-8 animate-pulse grid place-items-center">
              <div className="flex justify-center items-center w-full h-full bg-gray-300 rounded dark:bg-gray-700">
                <svg className="w-12 h-12 text-gray-200" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" viewBox="0 0 640 512">
                  <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                </svg>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  }

  function defaultLoadingState() {
    return (
      <>
        <div className="h-full grid place-items-center px-8 py-8 pb-12 text-center text-gray-700">
          <div className="mb-4">
            <div className="scale-[2] mb-4">
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 inline text-primary-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </div>
          </div>
          <div className="text-2xl font-extrabold mb-2 text-gray-700">Generating Ideas</div>
          <p className="text-base text-gray-400 font-medium">Please wait, AI can take up to 30 seconds!</p>
        </div>
      </>
    );
  }

  return (
    <section className="h-full" aria-labelledby="tool-output-panel">
      <div className="flex flex-col h-full bg-white min-h-full shadow sm:rounded-lg">
        {/* Header tabs + action buttons */}

        <div className="flex px-2 lg:px-4 py-3 gap-4 justify-between">
          <nav className="flex space-x-4" aria-label="Tabs">
            <button onClick={() => onChangeTab("new")} className={classNames(activeTab === "new" ? "bg-primary-50 text-primary-700" : "text-gray-500 hover:text-gray-700", "px-3 py-2 font-medium text-sm rounded-md")} aria-current={true ? "page" : undefined}>
              All
            </button>

            <button onClick={() => onChangeTab("saved")} className={classNames(activeTab === "saved" ? "bg-primary-50 text-primary-700" : "text-gray-500 hover:text-gray-700", "px-3 py-2 font-medium text-sm rounded-md")} aria-current={false ? "page" : undefined}>
              Saved&nbsp;({props.outputList.filter((i) => i.isSaved).length})
            </button>
          </nav>
          <div className="flex space-x-3">
            {allSelected && (
              <>
                <button onClick={deselectAllHandler} type="button" className="inline-flex items-center rounded-md border border-gray-300 bg-white px-2 py-1 lg:px-4 lg:py-2 text-xs lg:text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
                  Deselect&nbsp;All
                </button>
                {/* Image gallery doesn't have a copy all */}
                {outputType !== "images" && (
                  <button onClick={copyAllHandler} type="button" className="inline-flex min-w-24 justify-center items-center rounded-md border border-gray-300 bg-white px-2 py-1 lg:px-4 lg:py-2 text-xs lg:text-sm  font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
                    {isCopying ? "Copied!" : <>Copy&nbsp;All</>}
                  </button>
                )}
                {props.outputList.filter((item) => item.isSaved).length !== props.outputList.length && (
                  <button onClick={saveAllHandler} type="button" className="inline-flex items-center rounded-md border border-gray-300 bg-white px-2 py-1 lg:px-4 lg:py-2 text-xs lg:text-sm  font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
                    {isSaving ? "Saved!" : <>Save&nbsp;All</>}
                  </button>
                )}
              </>
            )}
            {!allSelected && props.outputList.length > 0 && (
              <button onClick={selectAllHandler} type="button" className="inline-flex items-center rounded-md border border-gray-300 bg-white px-2 py-1 lg:px-4 lg:py-2 text-xs lg:text-sm  font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
                Select All
              </button>
            )}
          </div>
        </div>

        <div className="border-t grow border-b lg:h-full sm:rounded-b-lg bg-gray-150 border-gray-200  px-4 py-5 sm:px-6 h-min">
          {/* NEW tab ---------------------------------------------------------------------------------------- */}
          {activeTab === "new" && (
            <div>
              {/* Is loading state */}
              {props.isLoading && (
                <>
                  {outputType === "images" && imageLoadingState()}

                  {outputType !== "images" && defaultLoadingState()}
                </>
              )}
              {/* Empty state */}
              {!props.isLoading && props.outputList.length === 0 && (
                <div className="h-full grid place-items-center text-center">
                  <p className="mt-8 mb-24 text-gray-400">Generate results by filling up the form on the left and clicking on "Generate".</p>
                  <ToolIcon className="mr-3 shadow-md" size="lg" category={props.tool.category} icon={props.tool.icon} customColor={props.tool.customIconColor} />

                  <div className="mb-24">
                    <h4 className="font-extrabold text-2xl text-gray-700 my-4">{props.tool.name}</h4>
                    <p className="text-gray-600 font-medium mb-8">{props.subtitle}</p>
                    {props.showExampleButton && (
                      <button onClick={props.onWriteExample} type="button" className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-semibold text-gray-500 duration-200 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
                        <ArrowUpIcon className="-ml-0.5 mr-2 h-4 w-4 xl:hidden" aria-hidden="true" />
                        <ArrowLeftIcon className="-ml-0.5 mr-2 h-4 w-4 hidden xl:inline-block" aria-hidden="true" />
                        Write me an example
                      </button>
                    )}
                  </div>
                </div>
              )}
              {/* List state */}
              {props.outputList.length > 0 && (
                <>
                  {outputType === "images" && <OutputGallery eventHandlers={eventHandlers} isLoading={props.isLoading} isSelected={allSelected} outputList={props.outputList} />}
                  {outputType !== "images" && <OutputList eventHandlers={eventHandlers} isSelected={allSelected} outputList={props.outputList} />}
                </>
              )}
            </div>
          )}
          {/* /END: NEW tab ---------------------------------------------------------------------------------------- */}

          {/* SAVED tab ---------------------------------------------------------------------------------------- */}
          {activeTab === "saved" && (
            <>
              {props.outputList.filter((i) => i.isSaved).length > 0 && (
                <>
                  {outputType === "images" && <OutputGallery eventHandlers={eventHandlers} isLoading={props.isLoading} isSelected={allSelected} outputList={props.outputList.filter((i) => i.isSaved)} />}
                  {outputType !== "images" && <OutputList eventHandlers={eventHandlers} isSelected={allSelected} outputList={props.outputList.filter((i) => i.isSaved)} />}
                </>
              )}
              {props.outputList.filter((i) => i.isSaved).length === 0 && <p>No content has been saved yet. Use the tool to generate content and hit "save"!</p>}
            </>
          )}
          {/* /END: SAVED tab ---------------------------------------------------------------------------------------- */}
        </div>
      </div>
    </section>
  );
}
