import Tippy from "@tippyjs/react";
import React, { useState } from "react";
import { TagsInput } from "react-tag-input-component";
import Label from "./Label";
import "./TagsInput.css";

export default function MyTagsInput(props) {

  const [selected, setSelected] = useState(props.value ? props.value : []);
  const [existing, setExisting] = useState(false);
  const background = props.background ? props.background : "bg-gray-100";

  function delay(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  async function onExistingHandler(tag) {
    setExisting(true);
    await delay(2000);
    setExisting(false);
  }

  function hanldeChange(data) {
    setSelected(data);
    // this component doesn't yield an event when onChange is triggered. it yields data.
    // set the event to null to `prioritize` explicit data submission.
    props.onChange(data);
  }

  return (
    <>
      {/* Label */}
      <div className="mb-1 block">
        <Label name={props.name} required={props.required}>
          {props.label}
        </Label>
      </div>

      <div className={classNames(selected.length === 0 && "empty", existing && "invalid", "relative border border-gray-300 text-sm focus:ring-0 ring-0 focus-within:ring-0 outline-none rounded-md " + background)}>
        <TagsInput value={selected} onChange={hanldeChange} onExisting={(event) => onExistingHandler(event)} separators={[",", "Enter"]} name={props.name} placeHolder={props.placeholder} />
        {existing && <div className="absolute hidden bg-red-100 text-red-800 py-1 px-2 rounded-md right-0 top-1/2 -mr-2 -translate-y-1/2 translate-x-full">Double entry!</div>}
      </div>
      <p className="text-gray-400 font-semibold mt-1 text-xs">Press enter to add a new {props.name}</p>
    </>
  );
}
