import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { default as App, AppEffects } from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById("root"));
const basename = window.location.hostname.includes("semrush.com")
    ? "/app/ai-writing-assistant"
    : "/";



root.render(
  /*<React.StrictMode>*/
    <Router basename={basename}>
    <App />
    </Router>
  /*</React.StrictMode>*/
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
