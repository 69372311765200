import React, { useEffect, useState } from "react";
import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import { decodeToken, isExpired } from "react-jwt";
import DashboardPage from "./Pages/Dashboard";
import ContentPage from "./Pages/Content";
import AllTools from "./Pages/AllTools";
import MainLayout from "./Components/layout/MainLayout";
import ToolDetailsPageSingleCol from "./Pages/ToolDetailsSingleCol";
import EditContent from "./Pages/EditContent/EditContent";
import BlogWriter from "./Pages/BlogWriter/BlogWriter";
import BlogWriterPage from "./Pages/BlogWriter/BlogWriterPage";
import axios from "axios";
import DevButtonOverview from "./Pages/DevButtonOverview";
import ToolFactory from "./Components/tools/ToolFactory";
import { AppDataProvider, parseUrlParams, renderInIframe } from "./data/AppHelpers";
import ArticleRewriterPage from "./Pages/ArticleRewriter/ArticleRewriter";
import { config } from "./config";
import { CreditsContextProvider } from "./data/CreditsContext";
import Loading from './Pages/Loading';
import Unauthorized from './Pages/Unauthorized';
import test_user from './data/test_user.json';


const getTheme = (host) => {
  if (host.includes("semrush")) {
    return "semrush";
  } else {
    return "standard";
  }
};

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

// Place this at the top of your App.js or the relevant component file
const decodeJWT = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

// need this line for it to recognize the SM object
/* global SM */

// this component assumes a authentication handshake has already occurred.
class App extends React.Component {
  constructor(props) {
    super(props);

    const params = parseUrlParams();
    const host = window.location != window.parent.location ? document.referrer : document.location.href;
    const appState = {
      inIframe: renderInIframe(),
      isLoaded: false,
      isLoading: true,
      isDependencyLoaded: host.includes("semrush"),
      params: params,
      ENV: process.env.NODE_ENV,
      theme: getTheme(host),
      jwt: params.jwt,
      auth: params.jwt,
      host: host,
      issuer: renderInIframe() ? "iframe" : "host",
        sdkInitialized: false,
    };

    this.loadScripts = this.loadScripts.bind(this);
    this.state = appState;
  }

    async componentDidMount() {
        console.log("Component mounted, checking environment...");

        /**
         * if the env is local for development load a mock user payload into
         * the AppDataContext and carry on
         */

        // just block all new tab opening behavior
        document.addEventListener('click', this.handleLinkClick);
        document.addEventListener('contextmenu', this.handleRightClick);

            // Check if app is in iframe
        const isInIframe = renderInIframe();
        this.setState({ inIframe: isInIframe });

        // Handle development environment
        if (process.env.NODE_ENV === "development") {
            console.log("Development environment detected, loading test user...");
            return this.setState({
                ...test_user,
                isLoaded: true,
                sdkInitialized: true,
                isLoading: false,
                isDependencyLoaded: false
            });
        }

        let jwt = this.state.jwt || localStorage.getItem('jwt');
        if (!jwt) {
            console.error("No JWT found in URL or local storage, redirecting to unauthorized page...");
            this.setState({ error: "No JWT found", isLoading: false });
            return;
        }

        // Authenticate and get the JWT
        try {
            console.log("Authenticating to get the JWT...");
            const authResponse = await axios.post(config(this.state.ENV).copymatic_api + "auth", {
                jwt: this.state.jwt,  // Existing JWT if available
                issuer: this.state.issuer,
                env: 'semrush',
            });

            // Check if the response contains a JWT
            if (authResponse && authResponse.data && authResponse.data.jwt) {
                const jwt = authResponse.data.jwt;
                // Store JWT in localStorage and state
                localStorage.setItem('jwt', jwt);
                this.setState({ jwt });

                if (!isInIframe) {
                    const relativePath = `/app/ai-writing-assistant`;
                    SM.client('pushUrl', relativePath);
                    return;
                }

            try {
                const appDataResponse = await axios.post(config(this.state.ENV).copymatic_api + "load_app2", {
                    jwt: jwt,
                    env: 'copymatic',
                });

                const appData = appDataResponse.data;
                this.setState({
                    isLoaded: true,
                    isLoading: false,
                    jwt: jwt,
                    isDependencyLoaded: true,
                    ...appData,
                });
            } catch (error) {
                if (error.response) {
                    console.error("Error response data:", error.response.data); // Server's response
                    console.error("Error response status:", error.response.status); // HTTP status code
                    this.setState({ error: error.response.data.message || "Unauthorized Access" });
                } else {
                    console.error("Error:", error.message);
                    this.setState({ error: "Unauthorized Access" });
                }
            }

                // Load SDK only after app data is loaded
                if (this.state.isDependencyLoaded) {
                    console.log("Dependencies loaded, loading Semrush SDK...");
                    await this.loadScripts("https://static.semrush.com/app-center/sdk.js");
                }

            } else {
                console.error("Failed to authenticate. No JWT received.");
            }

        } catch (error) {
            console.error("Error during authentication:", error);
            this.setState({ error, isLoading: false });
        }
    }

    async loadScripts(url) {
        let script = document.querySelector(`script[src="${url}"]`);

        const initializeSDK = async () => {
            console.log("Initializing Semrush SDK...");

            try {
                await SM.init();
                console.log("SDK initialized successfully!");

                this.startTokenCheck();
                this.setState({ sdkInitialized: true }, () => {
                    console.log("State after SDK initialization:", this.state);
                });

            } catch (error) {
                console.error("Error during SDK initialization:", error);
            }
        };

        if (!script) {
            script = document.createElement("script");
            script.type = "application/javascript";
            script.src = url;
            script.async = true;
            document.body.appendChild(script);

            script.onload = initializeSDK;

            script.onerror = (error) => {
                console.error("Error loading Semrush SDK script:", error);
            };
        } else {
            console.log("Semrush SDK script already loaded, initializing...");
            await initializeSDK();
        }
    }

    startTokenCheck() {
        setInterval(async() => {
                            try {
                                const token = await SM.client("getAccessToken");
                                console.log("Access token received:");

                                const hasTokenExpired = isExpired(token);

                                if (hasTokenExpired) {
                                    console.log('Token expired, logging out...');
                                    this.handleTokenExpiration();
                                } else {
                                    console.log('Token still valid');
                                }
                            } catch (error) {
                                console.error('Error getting token:', error);
                            }
        }, 250000); // Every ~4 minutes
    }


    handleTokenExpiration() {
        console.log("Handling token expiration...");
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleLinkClick);
        document.removeEventListener('contextmenu', this.handleRightClick);
    }

    handleLinkClick = (event) => {
        const target = event.target.closest('a');

        if (target && target.tagName === 'A') {
            // Check for middle-click (button === 1), block it
            if (event.button === 1) {
                event.preventDefault();
                console.log("Blocked middle-click on link.");
                return;
            }

            target.setAttribute('href', '#');

            // Use data-url to store the actual URL for SM.client navigation
            const url = target.getAttribute('data-url') || '/app/ai-writing-assistant';

            if (event.button === 0 || event.button === 2) {
                // Left-click or right-click navigation within the iframe
                event.preventDefault();
                console.log(`Intercepted ${event.button === 0 ? 'left-click' : 'right-click'}, pushing URL via SM.client:`, url);
                SM.client('pushUrl', url);
            }
        }
    };

    handleRightClick = (event) => {
        const target = event.target.closest('a');
        if (target && target.tagName === 'A') {
            event.preventDefault();
            const url = target.getAttribute('data-url') || '/app/ai-writing-assistant';
            SM.client('pushUrl', url);
        }
    };

  render() {
      const { isLoaded, sdkInitialized} = this.state;

    if (isLoaded && sdkInitialized) {
      return (
        <>
          <ScrollToTop />

          <AppDataProvider value={this.state}>
            <CreditsContextProvider credits={this.state.account.remainingCredits} totalCredits={this.state.account.totalCredits}>
              {this.state.error && <div>"There's been an error"</div>}
              <div className="hidden">
                <ul>
                  <li>jwt: {this.state.jwt}</li>
                  <li>auth: {this.state.auth}</li>
                  <li>ENV: {this.state.ENV} </li>
                  <li>host: {this.state.host} </li>
                  <li>issuer: {this.state.issuer} </li>
                </ul>
              </div>
              <div className={this.state.theme}>
                <Routes>
                  <Route
                    exact
                    path="/"
                    element={
                      <MainLayout user={this.state.user}>
                        <DashboardPage />
                      </MainLayout>
                    }
                  />
                  <Route
                    path="/tools"
                    element={
                      <MainLayout user={this.state.user}>
                        <AllTools />
                      </MainLayout>
                    }
                  />
                  <Route
                    path="/content"
                    element={
                      <MainLayout user={this.state.user}>
                        <ContentPage />
                      </MainLayout>
                    }
                  />
                  <Route
                    path="/tool-details"
                    element={
                      <MainLayout user={this.state.user}>
                        <ToolFactory />
                      </MainLayout>
                    }
                  />
                  <Route path="/tools/blog-writer" element={<BlogWriter user={this.state.user} />} />

                  <Route
                    path="/blog-writer"
                    element={
                      <MainLayout user={this.state.user}>
                        <BlogWriterPage />
                      </MainLayout>
                    }
                  />

                  <Route path="/article-rewriter-page" element={<ArticleRewriterPage user={this.state.user} />} />

                  <Route path="/edit-content" element={<EditContent user={this.state.user} />} />


                  {/* -------Tool Factory with tool id as url param ----------------------------*/}
                  <Route
                    path=":toolId"
                    element={
                      <MainLayout user={this.state.user}>
                        <ToolFactory />
                      </MainLayout>
                    }
                  />

                  {/* -------TEMP: DEV ROUTES --------------------------------------------------*/}

                  <Route
                    path="/toolfactory"
                    element={
                      <MainLayout user={this.state.user}>
                        <ToolFactory />
                      </MainLayout>
                    }
                  />
                  <Route
                    path="/one-col/"
                    element={
                      <MainLayout user={this.state.user}>
                        <ToolDetailsPageSingleCol />
                      </MainLayout>
                    }
                  />
                  <Route
                    path="/two-col/"
                    element={
                      <MainLayout user={this.state.user}>
                        <ToolFactory />
                      </MainLayout>
                    }
                  />
                  <Route
                    path="/buttons/"
                    element={
                      <MainLayout user={this.state.user}>
                        <DevButtonOverview />
                      </MainLayout>
                    }
                  />
                  <Route
                    path="/401/"
                    element={
                      <p>
                        Unauthorized
                      </p>
                    }
                  />
                  {/* -------TEMP: DEV ROUTES --------------------------------------------------*/}
                </Routes>
              </div>
            </CreditsContextProvider>
          </AppDataProvider>
        </>
      );
    }

    else if (this.state.isLoading) {
      return (
        <Loading />
      );
    } else {
      return (
        <Unauthorized />
      )
    }
  }
}

export default App;
