import { useState } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ToolsTabs(props) {
  const [toggleState, setToggleState] = useState("all");
  const tabs = [
    { id: "all", name: "All Tools", href: "#", count: props.categories.all.length, current: false },
    { id: "blog", name: "Blog Content", href: "#", count: props.categories.blog.length, current: true },
    { id: "website", name: "Website Copy & SEO", href: "#", count: props.categories.website.length + props.categories.copywriting.length, current: false },
    { id: "social", name: "Social Media & Ads", href: "#", count: props.categories.social.length, current: false },
    { id: "marketing", name: "Marketing & eCommerce", href: "#", count: (props.categories.marketing.length + props.categories.ecommerce.length), current: false },
    { id: "more", name: "More Tools", href: "#", count: props.categories.more.length, current: false },
  ];

  function toggleHandler(tabId) {
    setToggleState(tabId);
    props.onToggle(tabId);
  }

  function changeHandler(event) {
    toggleHandler(event.target.value)
  }

  return (
    <>
      {/* Dropdown for smaller screens */}
      <div className="min-[1500px]:hidden">
        <label htmlFor="tabs" className="text-sm font-bold text-gray-700 mb-1 block">
          Select a category:
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select id="tabs" onChange={changeHandler} name="tabs" className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm" defaultValue={tabs.find((tab) => tab.current).name}>
          {tabs.map((tab) => (
            <option value={tab.id} key={tab.id} selected={tab.name === toggleState ? "true" : "false" }>{tab.name}</option>
          ))}
        </select>
      </div>

      {/* Tabs for larger screens */}
      <div className="hidden min-[1501px]:block">
        <div className="border-b border-gray-200 dark:border-gray-700">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <span id={tab.id} key={tab.name} onClick={() => toggleHandler(tab.id)} className={classNames(toggleState === tab.id ? "border-primary-500 text-primary-600 hover:text-primary-600 dark:text-slate-100 dark:hover:text-white" : "border-transparent text-gray-500 dark:text-slate-300 hover:text-gray-700 dark:hover:text-slate-200 hover:border-primary-200 dark:hover:border-gray-600", "whitespace-nowrap flex py-4 px-1 border-b-2 hover:cursor-pointer duration-200 hover:text-gray-800 font-medium text-sm group")} aria-current={toggleState === tab.id ? "page" : undefined}>
                {tab.name}
                {tab.count ? <span className={classNames(toggleState === tab.id ? "bg-primary-500 group-hover:bg-primary-500 dark:group-hover:bg-primary-500 text-white font-bold" : "bg-gray-200 font-medium group-hover:bg-gray-300 dark:bg-slate-800 text-gray-600 dark:text-gray-400 dark:group-hover:text-slate-200", "hidden ml-3 py-0.5 px-2.5 duration-200 rounded-full text-xs font-medium md:inline-block dark:group-hover:bg-slate-700 ")}>{tab.count}</span> : null}
              </span>
            ))}
          </nav>
        </div>
      </div>
    </>
  );
}
