import React, { useEffect } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import styles from "./Notification.module.css";
import createContainer from "./createContainer";
import { createPortal } from "react-dom";
import { CheckCircleIcon, ExclamationTriangleIcon, InformationCircleIcon, XCircleIcon, XMarkIcon } from "@heroicons/react/20/solid";

const container = createContainer();

// used for the slideout animation when deleting a notification
const timeToDelete = 300;
const timeToClose = 5000;

export default function Notification({ color = Color.info, autoClose = false, onDelete, children }) {
  const [isClosing, setIsClosing] = React.useState(false);

  function getIcon() {
    switch (color) {
      case "info":
        return <InformationCircleIcon className="h-6 w-6 text-blue-500" aria-hidden="true" />;
      case "error":
        return <XCircleIcon className="h-6 w-6 text-red-500" aria-hidden="true" />;
      case "success":
        return <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />;
      case "warning":
        return <ExclamationTriangleIcon className="h-6 w-6 text-amber-500" aria-hidden="true" />;
      default:
        return null;
    }
  }

  useEffect(() => {
    if (autoClose) {
      const timeoutId = setTimeout(() => setIsClosing(true), timeToClose);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [autoClose]);

  useEffect(() => {
    if (isClosing) {
      const timeoutId = setTimeout(onDelete, timeToDelete);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isClosing, onDelete]);

  return createPortal(
    <div className={cn([styles.container, { [styles.shrink]: isClosing }])}>
      <div className={cn("w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5", [styles.notification, styles[color], { [styles.slideIn]: !isClosing }, { [styles.slideOut]: isClosing }])}>
        <div className="flex items-start">
          <div className="flex-shrink-0">{getIcon()}</div>
          <div className="ml-3 w-0 flex-1 pt-0.5 text-sm font-medium">{children}</div>
        </div>
        <button onClick={() => setIsClosing(true)} className={styles.closeButton}>
          <span className="sr-only">Close</span>
          <XMarkIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </div>,
    container
  );
}

export const Color = {
  info: "info",
  success: "success",
  warning: "warning",
  error: "error",
};

Notification.propTypes = {
  notificationType: PropTypes.oneOf(Object.keys(Color)),
  children: PropTypes.element,
};
