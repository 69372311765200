import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import Tippy from "@tippyjs/react";
import { Children } from "react";

export default function Label(props) {
  return (
    <div className="flex flex-wrap items-center justify-start gap-1">
      <label htmlFor={props.name} className="block text-sm font-bold text-gray-600 dark:text-white" onChange={props.onChange}>
        {props.children}
        {props.required && <span className="text-sm ml-0.5 text-red-800 dark:text-gray-300 dark:opacity-50">*</span>}
      </label>
      {props.labelHelpToolTip && (
        <Tippy allowHTML={true} placement="top" theme={"copymatic"} content={<div className="p-2" dangerouslySetInnerHTML={{ __html: props.labelHelpToolTip }} />}>
          <QuestionMarkCircleIcon className="h-4 w-4 opacity-50 hover:opacity-100 duration-300 ring-0 outline-0 hover:cursor-pointer text-gray-500" />
        </Tippy>
      )}
    </div>
  );
}
