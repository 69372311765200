

export const LANGUAGES = [
  {
    id: 1,
    name: "🇺🇸  English (US)",
  },
  {
    id: 2,
    name: "🇬🇧  English (UK)",
  },
  {
    id: 3,
    name: "🇳🇱  Dutch",
  },
  {
    id: 4,
    name: "🇫🇷  French",
  },
  {
    id: 5,
    name: "🇪🇸 Spanish"
  },
  {
    id: 6,
    name: "🇩🇪 German"
  },
  {
    id: 7,
    name: "🇮🇹 Italian"
  },
  {
    id: 8,
    name: "🇵🇹 Portuguese"
  },
  {
    id: 9,
    name: "🇸🇪 Swedish"
  },
  {
    id: 10,
    name: "🇳🇴 Norwegian"
  },
  {
    id: 11,
    name: "🇩🇰 Danish"
  },
  {
    id: 12,
    name: "🇫🇮 Finnish"
  },
  {
    id: 13,
    name: "🇷🇴 Romanian"
  },
  {
    id: 14,
    name: "🇨🇿 Czech"
  },
  {
    id: 15,
    name: "🇸🇰 Slovak"
  },
  {
    id: 16,
    name: "🇸🇮 Slovenian"
  },
  {
    id: 17,
    name: "🇭🇺 Hungarian"
  },
  {
    id: 18,
    name: "🇭🇷 Croatian"
  },
  {
    id: 19,
    name: "🇵🇱 Polish"
  },
  {
    id: 20,
    name: "🇬🇷 Greek"
  },
  {
    id: 21,
    name: "🇹🇷 Turkish"
  },
  {
    id: 22,
    name: "🇷🇺 Russian"
  },
  {
    id: 23,
    name: "🇮🇳 Hindi"
  },
  {
    id: 24,
    name: "🇹🇭 Thai"
  },
  {
    id: 25,
    name: "🇯🇵 Japanese"
  },
  {
    id: 26,
    name: "🇨🇳 Chinese (Simplified)"
  },
  {
    id: 27,
    name: "🇰🇷 Korean"
  },
  {
    id: 28,
    name: "🇮🇩 Indonesian"
  }
];

export const CREATIVITY_VALUES = [
  {
    id: 1,
    name: "Regular",
  },
  {
    id: 2,
    name: "High",
  },
];

export const TONE_OF_VOICE_VALUES = [
  {
    id: 1,
    name: "Professional",
  },
  {
    id: 2,
    name: "Childish",
  },
  {
    id: 3,
    name: "Luxurious",
  },
  {
    id: 4,
    name: "Friendly",
  },
  {
    id: 5,
    name: "Confident",
  },
  {
    id: 6,
    name: "Exciting",
  }
];

export const COMPANY_TYPE_VALUES = [
  {
    id: 1,
    name: "Software / SaaS",
  },
  {
    id: 2,
    name: "Ecommerce",
  },
  {
    id: 3,
    name: "Services",
  },
];

export const COLOR_VALUES = [
  {
    id: 1,
    name: "🟦 Blue",
  },
  {
    id: 2,
    name: "🟥 Red",
  },
  {
    id: 3,
    name: "🟩 Green",
  },
];

export const PAGE_STRUCTURE_VALUES = [
  {
    id: 0,
    name: "-- Select section --",
  },
  {
    id: 1,
    name: "Navigation/Menu",
  },
  {
    id: 2,
    name: "Hero Section/Header",
  },
  {
    id: 3,
    name: "Services",
  },
  {
    id: 4,
    name: "Logos",
  },

  {
    id: 5,
    name: "How It Works",
  },
  {
    id: 6,
    name: "Features",
  },
  {
    id: 7,
    name: "Services",
  },
  {
    id: 8,
    name: "Pricing",
  },
  {
    id: 9,
    name: "Recent Blog Posts",
  },
  {
    id: 10,
    name: "FAQ (Frequently Asked Questions",
  },
  {
    id: 11,
    name: "Testimonials",
  },
  {
    id: 12,
    name: "Content",
  },
  {
    id: 13,
    name: "Statistics",
  },
  {
    id: 14,
    name: "Calls To Action",
  },
  {
    id: 15,
    name: "About Us",
  },
  {
    id: 16,
    name: "Footer",
  },
];

/*=================================================================================*/
// Image Generator Dropdown Values
/*=================================================================================*/

// Image Generator Styles
export const IMAGE_STYLE_VALUES = [
  {
    id: 1,
    name: "None",
  },
  {
    id: 2,
    name: "3d Render",
  },
  {
    id: 3,
    name: "Abstract",
  },
  {
    id: 4,
    name: "Anime",
  },
  {
    id: 5,
    name: "Art Deco",
  },
  {
    id: 6,
    name: "Cartoon",
  },
  {
    id: 7,
    name: "Digital Art",
  },
  {
    id: 8,
    name: "Illustration",
  },
  {
    id: 9,
    name: "Line Art",
  },
  {
    id: 10,
    name: "One Line Drawing",
  },
  {
    id: 11,
    name: "Origami",
  },
  {
    id: 12,
    name: "Pixel Art",
  },
  {
    id: 13,
    name: "Photography",
  },
  {
    id: 14,
    name: "Pop Art",
  },
  {
    id: 15,
    name: "Retro",
  },
  {
    id: 16,
    name: "Unreal Engine",
  },
];

// Image Generator Medium
export const IMAGE_MEDIUM_VALUES = [
  {
    id: 1,
    name: "None",
  },
  {
    id: 2,
    name: "Acrylics",
  },
  {
    id: 3,
    name: "Canvas",
  },
  {
    id: 4,
    name: "Chalk",
  },
  {
    id: 5,
    name: "Charcoal",
  },
  {
    id: 6,
    name: "Classic Oil",
  },
  {
    id: 7,
    name: "Crayon",
  },
  {
    id: 8,
    name: "Glass",
  },
  {
    id: 9,
    name: "Ink",
  },
  {
    id: 10,
    name: "Modern Oil Painting",
  },
  {
    id: 11,
    name: "Pastel",
  },
  {
    id: 12,
    name: "Pencil",
  },
  {
    id: 13,
    name: "Spray Paint",
  },
  {
    id: 14,
    name: "Water Color Painting",
  },
  {
    id: 15,
    name: "Wood Panel",
  },
];

// Image Generator Artists
export const IMAGE_ARTIST_VALUES = [
  {
    id: 1,
    name: "None",
  },
  {
    id: 2,
    name: "Andy Warhol",
  },
  {
    id: 3,
    name: "Ansel Adams",
  },
  {
    id: 4,
    name: "Claude Monet",
  },
  {
    id: 5,
    name: "Dr. Seuss",
  },
  {
    id: 6,
    name: "Pablo Picasso",
  },
  {
    id: 7,
    name: "Pixar",
  },
  {
    id: 8,
    name: "Salvador Dali",
  },
  {
    id: 9,
    name: "South Park",
  },
  {
    id: 10,
    name: "Van Gogh",
  },
];

// Image Generator Mood
export const IMAGE_MOOD_VALUES = [
  {
    id: 1,
    name: "None",
  },
  {
    id: 2,
    name: "Aggressive",
  },
  {
    id: 3,
    name: "Angry",
  },
  {
    id: 4,
    name: "Boring",
  },
  {
    id: 5,
    name: "Bright",
  },
  {
    id: 6,
    name: "Calm",
  },
  {
    id: 7,
    name: "Cheerful",
  },
  {
    id: 8,
    name: "Chilling",
  },
  {
    id: 9,
    name: "Colorful",
  },
  {
    id: 10,
    name: "Dark",
  },
  {
    id: 11,
    name: "Neutral",
  },
];

// Image Generator Mood
export const IMAGE_DETAILS_VALUES = [
  {
    id: 1,
    name: "None",
  },
  {
    id: 2,
    name: "Ambient light",
  },
  {
    id: 3,
    name: "Black & White",
  },
  {
    id: 4,
    name: "Close-up",
  },
  {
    id: 5,
    name: "Full face portrait",
  },
  {
    id: 6,
    name: "High resolution",
  },
  {
    id: 7,
    name: "Highly-detailed",
  },
  {
    id: 8,
    name: "Photorealistic",
  },
  {
    id: 9,
    name: "Realistic",
  },
  {
    id: 10,
    name: "Sharp",
  },
];
