function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Badge(props) {
  let colorClasses = "bg-gray-100 text-gray-800";
  let sizeClasses = "text-[11px] px-1.5 py-1 ";

  switch (props.color) {
    case "primary":
      colorClasses = "bg-primary-500 text-white";
      break;
    case "light":
      colorClasses = "bg-primary-50 text-primary-500 dark:bg-slate-700 dark:text-primary-200";
      break;
    default:
      break;
  }

  switch (props.size) {
    case "xs":
      sizeClasses = "text-sm px-2 py-1.5";
      break;
    case "sm":
      sizeClasses = "text-13 px-2 py-1.5";
      break;
    case "md":
      sizeClasses = "text-base px-2 py-1.5";
      break;
    default:
      break;
  }

  return <span className={classNames(colorClasses, sizeClasses, "inline-flex font-bold items-center rounded text-xs")}>{props.children}</span>;
}
