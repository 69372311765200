import { useState } from "react";
import OutputItem from "./OutputItem";

export default function OutputList(props) {
    console.log("Rendering OutputList with items:", props.output);
    return (
        <>
          {/* List of output items */}
              <ul>
                  {props.output.map((item) => {
                      console.log('Rendering content:', item.content); // Log content here
                      return (
                  <OutputItem
                      eventHandlers={props.eventHandlers}
                      key={item.id}
                      id={item.id}
                      isNew={item.isNew}
                      isSaved={item.isSaved}
                      isSelected={props.isSelected}
                      isLoading={props.isLoading}
                      content={item.content}>
                  </OutputItem>
                      );
                  })}
              </ul>
        </>
    );
}

