import { Fragment, useRef, useState } from "react";
import { Combobox, Dialog, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import ToolIcon from "../Partials/ToolIcon";
import TOOLS from "../data/tools";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";

const allTools = [
  ...TOOLS.filter((item) => {
    return item.formFields && item.formFields.length > 0;
  }),
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ToolSwitchCommandPanel(props) {
  const [query, setQuery] = useState("");
  const dialogRef = useRef(null);
  let id = 0;

  const handleToolSelect = (toolId, url) => {
    console.log(url);
    props.onToolSelect(toolId);
  };

  const filteredTools =
    query === allTools
      ? []
      : allTools.filter((tool) => {
          return tool.name.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <Transition.Root show={props.isOpen} as={Fragment} afterLeave={() => setQuery("")} appear>
      <Dialog ref={dialogRef} as="div" className="relative z-10" onClose={props.onClose}>
        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
            <Dialog.Panel className="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <Combobox
              // onChange={(tool) => (window.location = tool.url)}
              >
                {/*tool.url */}
                <div className="relative">
                  <MagnifyingGlassIcon className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400" aria-hidden="true" />
                  <Combobox.Input className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm" placeholder="Search..." onChange={(event) => setQuery(event.target.value)} />
                </div>

                {filteredTools.length > 0 && (
                  <Combobox.Options static className="max-h-96 scroll-py-3 overflow-y-auto p-3">
                    {filteredTools.map((tool) => (
                      <Combobox.Option key={tool.id} value={tool} className={({ active }) => classNames("flex cursor-default hover:cursor-pointer select-none rounded-xl p-3", active && "bg-gray-50")}>
                        {({ active }) => (
                          <>
                            <ToolIcon size="sm" category={tool.category} icon={tool.icon} customColor={tool.customIconColor} />
                            <Link to={tool.url} onClick={() => handleToolSelect(tool.id, tool.url)}>
                              <div className="ml-4 flex-auto">
                                <p className={classNames("text-base font-semibold", active ? "text-gray-900" : "text-gray-700")}>{tool.name}</p>
                                <p className={classNames("text-sm", active ? "text-gray-600" : "text-gray-500")}>{tool.description}</p>
                              </div>
                            </Link>
                          </>
                        )}
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                )}

                {query !== "" && filteredTools.length === 0 && (
                  <div className="py-14 px-6 text-center text-sm sm:px-14">
                    <ExclamationCircleIcon type="outline" name="exclamation-circle" className="mx-auto h-6 w-6 text-gray-400" />
                    <p className="mt-4 font-semibold text-gray-900">No results found</p>
                    <p className="mt-2 text-gray-500">No tools found for this search term. Please try again.</p>
                  </div>
                )}
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
