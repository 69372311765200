import { useState } from "react";

const tabs = [
  { id: 1, name: "Details", href: "#", count: "1", current: false },
  { id: 2, name: "Title", href: "#", count: "2", current: true },
  { id: 3, name: "Intro", href: "#", count: "3", current: false },
  { id: 4, name: "Outline", href: "#", count: "4", current: false },
  { id: 5, name: "Content", href: "#", count: "5", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function BlogWriterSteps(props) {
  const [toggleState, setToggleState] = useState(props.activeTab);

  function toggleHanlder(tabId) {
    setToggleState(tabId);
    props.onToggle(tabId);
  }

  return (
    <>
      <div className="block">
        <div className="rounded-t-md overflow-hidden">
          <nav className="flex divide-x divide-gray-100" aria-label="Tabs">
            {tabs.map((tab) => (
              <span id={tab.id} key={tab.name} onClick={() => toggleHanlder(tab.id)} className={classNames(props.activeTab === tab.id ? "border-white bg-white border-b-0 text-primary-600 hover:text-primary-600" : "bg-gray-50 border-gray-100 border-b text-gray-500 hover:text-gray-700 hover:border-gray-100", "whitespace-nowrap flex justify-center py-4 px-1 border-b-1 hover:cursor-pointer duration-200 hover:text-gray-800 font-medium text-sm group grow")} aria-current={props.activeTab === tab.id ? "page" : undefined}>
                {tab.count ? <span className={classNames(props.activeTab === tab.id ? "bg-primary-500 group-hover:bg-primary-500 text-white font-bold" : "bg-gray-200 font-medium group-hover:bg-gray-300 text-gray-600", "hidden mr-2 py-0.5 px-2 duration-200 rounded-full text-xs font-medium md:inline-block")}>{tab.count}</span> : null}
                {tab.name}
              </span>
            ))}
          </nav>
        </div>
      </div>
    </>
  );
}
