import { useContext, useEffect, useState } from 'react';
import SectionTitle from "../Components/ui/SectionTitle";
import { statsHttp } from './../utils/http';
import { AppDataContext } from "./../data/AppHelpers";

export default function StatsSection(props) {

  const [stats, setStats] = useState([]);
  const { appDataContext } = useContext(AppDataContext);
  // console.log('appDataContext', appDataContext);

  useEffect(() => {

    const dataFetch = async () => {
      const data = await statsHttp({
        action: "get_user_details",
        key: appDataContext.user.cf_key
      }).then((response) => response.data);

      // console.log('get_user_details data', data)
      // console.log(appDataContext.user.stats)
      const newStats = [
        { name: "Items Generated", stat: data.total_items + " items" },
        { name: "Words Generated", stat: data.total_words + " words" }
      ]
      setStats(newStats);
    };

    dataFetch();
  }, []);

  return (
    <div className="pb-8">
      {/* Section Title */}
      <SectionTitle title={<div>Hello</div>} subtitle="Let's create something today!" />

      {/* Stats panels */}
      <div>
        <dl className="mt-5 grid grid-cols-1 gap-5  grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4">
          {stats.map((item) => (
            <div key={item.name} className="overflow-hidden rounded-lg bg-white px-4 py-5 sm:p-6 dark:bg-slate-900">
              <dt className="truncate text-sm font-bold text-gray-400 dark:text-slate-400">{item.name}</dt>
              <dd className="mt-1 text-lg md:text-2xl font-bold tracking-tight text-gray-900 dark:text-slate-300 flex justify-between flex-wrap">
                <span>{item.stat}</span>
                {item.hasLink && (
                  <a href={item.linkURL} className="text-sm flex items-center text-primary-500 hover:text-primary-700 duration-200 hover:cursor-pointer">
                    <item.linkIcon className="h-4 w-4 mr-1" />
                    <span>{item.linkLabel}</span>
                  </a>
                )}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}
