import { Fragment, useState, useEffect, useContext } from "react";
import { Menu, Popover, Transition } from "@headlessui/react";
import { ArrowLeftIcon, ArrowPathIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import { Bars3Icon, BellIcon, XMarkIcon, PlusCircleIcon, DocumentDuplicateIcon, QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import Tippy from "@tippyjs/react";
import "tippy.js/themes/light.css";
import StickyHeader from "./StickyHeader";
import styles from "./BlogWriter.module.css";
import ExportButton from "./ExportButton";
import Button from "../../Components/ui/Button";
import ArticleRewriterSection from "./ArticleRewriterSection";
import ProfileMenuDropdown from "../../Components/ProfileMenuDropdown";
import { useSearchParams } from "react-router-dom";
import { AppDataContext, classNames } from "./../../data/AppHelpers";
import { useCreditsContext } from "./../../data/CreditsContext";

const user = {
  name: "Chelsea Hagon",
  email: "chelsea.hagon@example.com",
  imageUrl: "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
};
const navigation = [{ name: "Dashboard", href: "#", current: true }];
const userNavigation = [
  { name: "Your Profile", href: "#" },
  { name: "Settings", href: "#" },
  { name: "Sign out", href: "#" },
];

const HARDCODED_SECTIONS = [
  {
    id: 1,
    content: "If you're new to React, or even if you're not, adding state management to your project can be a daunting task. In this article, we'll walk you through the basics of adding state management to a React project using the context API and the useReducer hook. We'll start by creating a simple context for our app that will hold our state.\r\n\nThen, we'll create a reducer function that will take our state and action objects as arguments and return a new state based on the action type.",
  },
];

// TODO: Add copy content and help button to header for small screens

export default function ArticleRewriterPage({ user, output, requestText }) {

  console.log(user, output)

  const [searchParams] = useSearchParams();
  const [wordcount, setWordcount] = useState(null);
  const { appDataContext } = useContext(AppDataContext);
  const { credits } = useCreditsContext();
  console.log('requestText', requestText);

  const initArticleSections = (text) => {
    return text.split("\n\n").map((content, index) => ({
      id: index,
      content: content,
      generated: ""
    }));
  };

  const [articleSections, setArticleSections] = useState(() => {
    return requestText ? initArticleSections(requestText) : [];
  });

  useEffect(() => {
    if (requestText) {
      setArticleSections(initArticleSections(requestText));
    }
  }, [requestText]);


  // useEffect(() => {
  //   let countTotal = 0;

  //   const sectionWordCounts = articleSections.forEach((section, i) => {
  //     if (section.generated && section.generated !== "") {
  //       countTotal += section.generated.split(" ").length;
  //     }
  //   });

  //   setWordcount(countTotal);
  // }, [articleSections]);

  function saveHandler() { }

  function addSectionHandler() {
    const newSections = [...articleSections, { id: articleSections.length, content: "", generated: "" }];
    setArticleSections(newSections);
  }

  function rewriteHandler(index, newContent, newGenerated, currentTool) {
    const updatedSections = [...articleSections];
  if (currentTool === 'rewrite-paragraph') {
    updatedSections[index] = {...updatedSections[index], content: newContent, generated: newGenerated};
  } else {
      updatedSections[index] = { id: index, content: newContent, generated: newGenerated };
    }
    setArticleSections(updatedSections);
  }


  useEffect(() => {
    if (appDataContext.isDependencyLoaded) {
      const body = document.body;
      const html = document.getElementById("root");
      const screenHeight = Math.max(body.scrollHeight, body.offsetHeight,
        html.clientHeight, html.scrollHeight, html.offsetHeight);
      /* global SM */
      SM.client("resizeWindow", { height: screenHeight });
    }
  });

  return (
    <div className="border-0 z-10 relative px-3 md:px-6 py-6 h-full w-full">
      <div >
        {articleSections.map((section, i) => (
            <ArticleRewriterSection
                key={section.id}
                id={section.id}
                content={section.content}
                generated={section.generated}
                onRewrite={rewriteHandler}
            />
        ))}

        <div className="relative p-8 mx-auto text-center pb-24">
          <button onClick={addSectionHandler} className="border mx-auto border-gray-300 bg-white flex items-center font-bold text-gray-500 hover:text-gray-700 text-sm py-2 px-8 rounded-md hover:bg-gray-50 duration-200">
            <PlusCircleIcon className="h-5 h-5 mr-2" />
            Add section below
          </button>
        </div>
      </div>
    </div>
  );
}
