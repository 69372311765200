import { Fragment, useState } from "react";
import ReactDOM from "react-dom";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import "./Modal.css";
import YoutubeEmbed from "./YoutubeEmbed";
import { XMarkIcon } from "@heroicons/react/20/solid";

export default function VideoModal(props) {
  const [open, setOpen] = useState(true);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <>
      {ReactDOM.createPortal(
        <Fragment>
          <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={props.onClose}>
              <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                <div className="fixed inset-0 bg-slate-900 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-8 text-center items-center sm:p-0">
                  <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <Dialog.Panel className="relative transform rounded-lg grow bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl p-3">
                      <div className="absolute -top-11 -right-11 pt-4 pr-4 sm:block">
                        <button type="button" className="text-white duration-300 opacity-50 ring-0 outline-0 hover:opacity-100" onClick={props.onClose}>
                          <span className="sr-only">Close</span>
                          <XMarkIcon className="h-8 w-8" aria-hidden="true" />
                        </button>
                      </div>
                      <YoutubeEmbed embedId={props.youtubeEmbedId} />
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </Fragment>,
        document.getElementById("modal-root")
      )}
    </>
  );
}
