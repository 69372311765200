import { Fragment, useState } from "react";
import ReactDOM from "react-dom";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import "./Modal.css";

export default function Modal(props) {
  const [open, setOpen] = useState(true);
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  console.log(props);

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={props.onClose}>
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="mx-auto relative transform overflow-hidden rounded-lg bg-white px-6 pt-8 pb-6 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-6">
                {props.type !== "error" && (
                  <div className="text-center">
                    <div className="sa mx-auto">
                      <div className="sa-success">
                        <div className="sa-success-tip"></div>
                        <div className="sa-success-long"></div>
                        <div className="sa-success-placeholder"></div>
                        <div className="sa-success-fix"></div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="text-center">
                  <Dialog.Title as="h3" className="text-2xl font-extrabold leading-6 text-gray-900">
                    {props.title}
                  </Dialog.Title>
                  <div className="mt-4 mb-4">
                    <p className="text-base font-medium text-gray-500">{props.content}</p>
                  </div>
                </div>

                {props.buttonLabel ? (
                  <div className="mt-5 sm:mt-6 text-center">
                    <button type="button" className={classNames(props.type === "error" ? "bg-red-600 hover:bg-red-700" : "bg-primary-600 hover:bg-primary-700", "inline-flex w-min mx-auto justify-center rounded-md focus:outline-none border-transparent duration-200 px-6 py-2 text-base font-bold text-white shadow-sm sm:text-sm")} onClick={props.onClose}>
                      {props.buttonLabel}
                    </button>
                  </div>
                ) : null}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
