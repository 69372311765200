import React, { useContext, useEffect } from "react";
import StatsSection from "../Partials/StatsSection";
import ToolsRow from "../Partials/ToolsRow";
import HeaderBanner from "../Partials/HeaderBanner";
import SectionTitle from "../Partials/SectionTitle";
import { AppDataContext } from "../data/AppHelpers";
import { TOOLS } from "../data/tools";

function DashboardPage(props) {

  const { appDataContext, getRecentTools, paidUserStatus } = useContext(AppDataContext);

  function filterTools(recentTools, tools) {
    return tools.reverse().filter(t => recentTools.has(t.id)).slice(0, 4);
  }

  useEffect(() => {

    if (appDataContext.isDependencyLoaded) {
      const body = document.body;
      const html = document.getElementById('root');
      const screenHeight = Math.max(html.scrollHeight, html.offsetHeight);

      /* global SM */
      SM.client('resizeWindow', { height: screenHeight });
    }
  })

  const recentTools = filterTools(getRecentTools(), TOOLS);

  return (
    <div className="w-full">
      {/* Upgrade Banner for Free Trial Users */}
        {!paidUserStatus ?
            <div className="mx-auto max-w-7xl md:px-6 md:px-8 ">
                <HeaderBanner appData={appDataContext}/>
            </div>
        : <></>}

      {/* Stats Section */}
      <div className="mx-auto max-w-7xl md:px-6 md:px-8 mb-5 pt-4 mb-8">
        <StatsSection user={appDataContext.user} />
      </div>

      {/* Recent tools */}
      {recentTools && recentTools.length > 0 &&
        <div className="mx-auto max-w-7xl md:px-6 md:px-8 mb-14">
          <SectionTitle title="Recent Tools" />
          <ToolsRow tools={recentTools} />
        </div>
      }


      {/* New tools */}
      <div className="mx-auto max-w-7xl sm:px-6 md:px-8 mb-14">
        <div className="-ml-8 -mr-8 bg-secondary rounded-lg p-8 bg-topology-pattern bg-secondary bg-right">
          <SectionTitle title="Recommended Tools" />
          <ToolsRow tools={TOOLS.filter(t => t.category === 'blog' || t.id.includes('image')).sort((a, b) => 0.5 - Math.random()).slice(0, 4)} />
        </div>
      </div>

      {/* Recommended tools */}
      <div className="mx-auto max-w-7xl sm:px-6 md:px-8 mb-8 pb-12">
        <div className="pb-6">

          <div className="flex flex-wrap items-center">
            <span className="inline-flex mr-3 my-2 items-center rounded-md bg-primary-500 px-2.5 py-1 text-xs   font-medium uppercase font-bold text-white">New</span>
            <h3 className="text-lg my-2 font-extrabold leading-6 text-primary-600">Check out these new tools we have to offer!</h3>
          </div>
          <p className="hidden mt-2 max-w-4xl text-lg font-normal text-gray-400">These are the tools we recommend based on your interests.</p>

        </div>
        <ToolsRow tools={TOOLS.filter(x => x.isNew)} />
      </div>
    </div>
  );
}

export default DashboardPage;
