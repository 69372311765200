import { Fragment, useState, useRef, useEffect, useContext } from "react";
import Tippy from "@tippyjs/react";
import { ArrowPathIcon, ChevronDownIcon, ChevronUpIcon, MagnifyingGlassIcon, TrashIcon } from "@heroicons/react/20/solid";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import axios from 'axios';
import {AppDataContext, classNames} from "./../../data/AppHelpers";
import {config} from "./../../config";

export default function ArticleSection(props) {

  const [isHover, setIsHover] = useState(false);
  const [isLoading, setLoading] = useState(false)
  const [currentValue, setCurrentValue] = useState(props.content);
  const [currentTitle, setCurrentTitle] = useState(props.title);
  const appDataContext = useContext(AppDataContext);
  const inputRef = useRef(null);
  const textareaRef = useRef(null);
  const isIntro = props.isIntro;
  const shouldGenerate = props.shouldGenerate;

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const menuStyle = {
    opacity: isHover ? "100" : "0",
    pointerEvents: isHover ? "auto" : "none",
    transition: "200ms ease-in all",
  };

  useEffect(() => {
    textareaRef.current.style.height = "0px";
    const scrollHeight = textareaRef.current.scrollHeight;
    textareaRef.current.style.height = scrollHeight + "px";
  }, [currentValue]);

  // function handleResize() {
  //   textareaRef.current.style.height = "0px";
  //   const scrollHeight = textareaRef.current.scrollHeight;
  //   textareaRef.current.style.height = scrollHeight + "px";
  // }

  // useEffect(() => {
  //   window.addEventListener("resize", handleResize, false);
  // }, []);

  useEffect(() => {

    if(shouldGenerate && props.content === "" && props.title !== "") {
      setLoading(true)
      const url = config(appDataContext.ENV).copymatic_api + "proxy_submit_request";
      const request = {
        jwt: appDataContext.jwt,
        env: "copymatic",
        key: appDataContext.user.cf_key,
        model: "paragraph-generator",
        topic: currentTitle,
        language: props.post_language.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, ''),
        creativity: "high",
        tone: "descriptive",
        n: 1,
      };
      axios.post(url, request)
      .then((response) => {
          const data = JSON.parse(response.data);
          const content = data.results[1];
          if(content) {
            const generatedContext = content.replace(/(<([^>]+)>)/gm, "\n");
            setCurrentValue(generatedContext);
            props.onContentCreate(generatedContext, currentTitle);
            setLoading(false);
          }
      })
    }
  }, [shouldGenerate])

  function createHandler(e) {
    setLoading(true);
    const url = config(appDataContext.ENV).copymatic_api + "proxy_submit_request";
    const request = {
      jwt: appDataContext.jwt,
      env: "copymatic",
      key: appDataContext.user.cf_key,
      model: "paragraph-generator",
      topic: currentTitle,
      language: props.language.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, ''),
      creativity: "high",
      tone: "descriptive",
      n: 1,
    };
    axios.post(url, request)
    .then((response) => {
        const data = JSON.parse(response.data);
        const content = data.results[1];
        if(content) {
          const generatedContext = content.replace(/(<([^>]+)>)/gm, "\n");
          setCurrentValue(generatedContext);
          props.onContentCreate(generatedContext, currentTitle);
          setLoading(false);
        }
    })
    setLoading(false);
  }

  function expandHandler() {
    setLoading(true);
    const url = config(appDataContext.ENV).copymatic_api + "proxy_submit_request";
    const request = {
      jwt: appDataContext.jwt,
      env: "copymatic",
      key: appDataContext.user.cf_key,
      model: "sentence-expander",
      topic: currentValue,
      language: props.language.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, ''),
      creativity: "high",
      tone: "descriptive",
      n: 1,
    };
    axios.post(url, request)
    .then((response) => {
        const data = JSON.parse(response.data);
        const content = data.results[1];
        if(content) {
          const generatedContext = content.replace(/(<([^>]+)>)/gm, "\n");
          setCurrentValue(generatedContext);
          props.onContentCreate(generatedContext, currentTitle);
          setLoading(false);
        }
    })
    setLoading(false);
  }

  return (
    <>
      <section onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className={classNames(isIntro ? "sm:hover:mb-0 mb-0 xl:hover:mb-6" : "xl:hover:py-6", "relative articleSection group mx-auto hover:py-0 duration-500 hover:border border border-transparent hover:border-gray-200 xl:border-0 xl:hover:border-0 duration-400")}>
        {/* Left menu with buttons */}
        <div style={menuStyle} className="menuHolder duration-400 inset-y-1/2 -translate-y-1/2 -translate-x-full top-50 hidden xl:block left-0 absolute h-fit w-56 pr-4">
          {!isLoading &&
            <div className="bg-white p-4 rounded-lg h-full shadow-xl flex flex-col space-y-4">
              {currentTitle !== "" &&
                <Tippy theme="copymatic" allowHTML={true} placement="top" content={<div className="px-2 py-2 text-center w-56">Generate content based on the post title and section headline</div>}>
                  <button onClick={createHandler} className="inline-flex group w-full justify-center items-center rounded-md bg-primary-500 text-white px-2 py-2 text-[13px] font-bold shadow-sm duration-200 hover:bg-primary-700">Generate</button>
                </Tippy>
              }
              {currentValue !== "" &&
                <Tippy theme="copymatic" allowHTML={true} placement="bottom" content={<div className="px-2 py-2 text-center w-56">Complete your existing text</div>}>
                  <button onClick={expandHandler} className="inline-flex group w-full justify-center items-center rounded-md bg-white border-2 border-primary-500 text-primary-500 px-2 py-2 text-[13px] font-bold shadow-sm duration-200 hover:bg-primary-50">Write&nbsp;more</button>
                </Tippy>
              }
              {currentTitle === "" &&
                <button className="inline-flex group w-full justify-center items-center rounded-md bg-white border-2 border-primary-500 text-primary-500 px-2 py-2 text-[13px] font-bold shadow-sm duration-200">write a title section to generate content!</button>
              }
            </div>
            }
        </div>

        {/* Right menu with icons */}
        {false && !isIntro && !isLoading && (
          <div style={menuStyle} className="menuHolder xl:block hidden duration-400 inset-y-1/2 -translate-y-1/2 translate-x-full top-50 right-0 absolute h-fit w-56 pl-2">
            <div className="h-min w-min flex flex-col">
              <Tippy theme="copymatic" allowHTML={true} placement="right" content={<div className="">Plagiarism check</div>}>
                <div className="p-2 hover:bg-primary-50  text-primary-500 duration-200 hover:cursor-pointer rounded-full">
                  <MagnifyingGlassIcon className="h-5 w-5" />
                </div>
              </Tippy>
              {props.index !== 0 && 
                <Tippy theme="copymatic" allowHTML={true} placement="right" content={<div className="">Move section up</div>}>
                  <div onClick={props.onMoveUp} className="p-2 hover:bg-primary-50  text-primary-500  duration-200 hover:cursor-pointer rounded-full">
                    <ChevronUpIcon className="h-5 w-5" />
                  </div>
                </Tippy>
              }
              {props.index !== (props.post_content.length - 2) &&
                <Tippy theme="copymatic" allowHTML={true} placement="right" content={<div className="">Move section down</div>}>
                  <div onClick={props.onMoveDown} className="p-2 hover:bg-primary-50 text-primary-500 duration-200 hover:cursor-pointer rounded-full">
                    <ChevronDownIcon className="h-5 w-5" />
                  </div>
                </Tippy>
              }
              <Tippy theme="copymatic" allowHTML={true} placement="right" content={<div className="">Delete section</div>}>
                <div className="p-2 hover:bg-primary-50 text-primary-500 duration-200 hover:cursor-pointer rounded-full">
                  <TrashIcon onClick={props.onDelete} className="h-5 w-5" />
                </div>
              </Tippy>
            </div>
          </div>
        )}

        {!isIntro && (
          <header className="border-b bg-white border-gray-100  px-4 xl:px-8 py-4 font-bold text-lg">
            <input
              onChange={(e) => {
                setCurrentTitle(e.target.value);
              }}
              ref={inputRef}
              type="text"
              placeholder="Write a subheading to add to this article..."
              className="p-2 h-auto text-xl focus:ring-0 w-full outline-0 focus:bg-gray-100 hover:bg-gray-50 border-0 focus:border-gray-500"
              value={currentTitle}
            />
          </header>
        )}

        <div className="bg-white group-hover:shadow-sm pb-3 pt-5 px-4 xl:px-8 group duration-500">
          {!isLoading && <textarea
            ref={textareaRef}
            style={{ resize: "none" }}
            onChange={(e) => {
              setCurrentValue(e.target.value);
              //to do something with value, maybe callback?
            }}
            name={props.name}
            className="p-2 h-auto focus:ring-0 text-base w-full outline-0 focus:bg-gray-100 hover:bg-gray-50 border-0 focus:border-gray-500"
            value={currentValue}
          >
          </textarea>}

          {/* Left menu with buttons for mobile */}
          <div className="menuHolder pr-4 group-focus-within:h-min group-hover:h-min duration-100 xl:hidden">
            <div className="bg-white pt-4 pl-2 pb-4 h-full flex sm:flex-row justify-start flex-col w-full gap-4">
              <Tippy theme="copymatic" allowHTML={true} placement="bottom" content={<div className="px-2 py-2 text-center w-56">Generate content based on the post title and section headline</div>}>
                <button className="inline-flex group justify-center items-center rounded-md bg-white text-primary-700 border px-4 py-2 text-xs font-extrabold shadow-md duration-200 hover:bg-gray-50">
                  <ArrowPathIcon className="h-4 w-4 mr-1" />
                  Generate
                </button>
              </Tippy>

              {currentValue != "" &&
                <Tippy theme="copymatic" allowHTML={true} placement="bottom" content={<div className="px-2 py-2 text-center w-56">Complete your existing text</div>}>
                  <button className="inline-flex group justify-center items-center rounded-md bg-white text-primary-700 border px-4 py-2 text-xs font-extrabold shadow-md duration-200 hover:bg-gray-50">
                    <PlusCircleIcon className="h-4 w-4 mr-1" />
                    Write&nbsp;more
                  </button>
                </Tippy>
              }
              <button className="inline-flex group justify-center items-center rounded-md bg-white text-primary-700 border px-4 py-2 text-xs font-extrabold shadow-md duration-200 hover:bg-gray-50">
                <TrashIcon className="h-4 w-4 mr-1" />
                <span className="sm:block">Remove</span>
              </button>
              <div className="flex justify-center">
                <Tippy theme="copymatic" allowHTML={true} placement="bottom" content={<div className="">Move section up</div>}>
                  <div className="p-2 hover:bg-primary-50 text-primary-500  duration-200 hover:cursor-pointer rounded-full">
                    <ChevronUpIcon className="h-5 w-5" />
                  </div>
                </Tippy>
                <Tippy theme="copymatic" allowHTML={true} placement="bottom" content={<div className="">Move section down</div>}>
                  <div className="p-2 hover:bg-primary-50 text-primary-500 duration-200 hover:cursor-pointer rounded-full">
                    <ChevronDownIcon className="h-5 w-5" />
                  </div>
                </Tippy>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
