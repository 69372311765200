import { createContext, useState } from "react";

const SavedOutputsContext = createContext({
  saveOutputs: [],
  totalSavedOutputs: 0,
  addOutputItem: (outPutItem) => {},
  removeSavedItem: (outPutItemId) => {},
  isSavedItem: (outPutItemId) => {},
  activeTab: "",
  setActiveTab: (tab) => {},
});

export function SavedOutputsContextProvider(props) {
  const [savedOutputs, setSavedOutputs] = useState(props.savedOutputs);
  const [activeTab, setActiveTab] = useState("new");

  const context = {
    saveOutputs: savedOutputs,
    totalSavedOutputs: savedOutputs.length,
    addOutputItem: addSavedHandler,
    removeSavedItem: removeSavedHandler,
    isSavedItem: itemIsSavedHandler,
    activeTab: activeTab,
    setActiveTab: setActiveTabHandler,
  };

  function setActiveTabHandler(tab) {
    console.log("Setting active tab in context: ", tab);
    setActiveTab(tab);
  }

  function addSavedHandler(outPutItem) {
    setSavedOutputs((prev) => {
      return prev.concat(outPutItem);
    });

    //console.log("Saved items: ", savedOutputs);
  }
  function removeSavedHandler(outPutItemId) {
    setSavedOutputs((prev) => {
      //console.log("Removing item, total:", savedOutputs.length - 1);
      return prev.filter((item) => item.id !== outPutItemId);
    });
    //console.log("Saved items: ", savedOutputs);
  }
  function itemIsSavedHandler(outPutItemId) {
    return savedOutputs.some((item) => item.id === outPutItemId);
  }

  return <SavedOutputsContext.Provider value={context}>{props.children}</SavedOutputsContext.Provider>;
}

export default SavedOutputsContext;
