import { useState, useContext, useEffect, useRef } from "react";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import BlogWriterForm from "../../Components/tools/BlogWriterForm";
import Button from "../../Components/ui/Button";
import Modal from "../../Components/ui/Modal";
import BlogWriterSteps from "./BlogWriterSteps";
import axios from "axios";
import { config } from "../../config";
import { AppDataContext } from "../../data/AppHelpers";
import { generateUUID } from "./../../utils/uuid";
import { useNavigate } from "react-router-dom";
import { CREATIVITY_VALUES, LANGUAGES } from "../../data/languages";
import { useCreditsContext } from "../../data/CreditsContext";
import { modelHttp } from "../../utils/http";

/**
 * language
 * creativity
 * blog_description
 * keyword
 */
const Tab1 = (props) => {
  return (
    <section aria-labelledby="Tool-title" className="text-left">
      <BlogWriterForm onChange={props.onChange} {...props} />

      <div className="py-8 text-center">
        <div className="flex items-center text-md group justify-center text-gray-400 hover:text-gray-600 duration-200 hover:cursor-pointer">
          <QuestionMarkCircleIcon className="h-5 h-5 mr-1 group-hover:text-gray-500" />
          <p>Not sure how this works? Watch the explainer video!</p>
        </div>
      </div>
    </section>
  );
};

/**
 * title
 */
const Tab2 = (props) => {
  const blogTitle = props.formFields.find((ff) => ff.field.id === "blog_title");

  const [titles, setTitles] = useState(blogTitle.value ? [{ id: 0, data: blogTitle.value, selected: true }] : []);
  const [isLoading, setLoading] = useState(false);
  const [writeOwn, setWriteOwn] = useState(false);
  const [valid, setValid] = useState(false);
  const writeOwnRef = useRef();
  const { setCredits } = useCreditsContext();

  useEffect(() => {
    let screenHeight;

    if(props.appDataContext.isDependencyLoaded) {

      const body = document.body;
      const html = document.documentElement;

      screenHeight = Math.max(body.scrollHeight, body.offsetHeight,
        html.clientHeight, html.scrollHeight, html.offsetHeight);

      /* global SM */
      SM.client('resizeWindow', {height: screenHeight});
    }
  })

  async function generateHandler(event) {
    event.preventDefault();
    setWriteOwn(false);
    setLoading(true);

    const language = props.formFields.find((ff, i) => ff.field.id == "language").value;
    const blogDescription = props.formFields.find((ff, i) => ff.field.id == "blog_description").value;
    const request = {
      model: "blog-titles",
      topic: blogDescription,
      language: language,
      creativity: "Low",
      n: 4,
    };

    modelHttp(props.appDataContext.user.cf_key, request)
      .then((response) => {
        console.log(response)
        const titlesCount = titles.length;
        const newTitles = [];
        const data = response.data;
        setCredits(data.balance)
        for (const key in data.results) {
          newTitles.push({ id: titlesCount + parseInt(key), data: data.results[key] });
        }
        setTitles([...newTitles, ...titles]);
        setLoading(false);
      })
      .catch((error) => {
        // handle error
        // the app cannot do anything without data...
        setLoading(false);
      });
  }

  function writeOwnHandler(event) {
    event.preventDefault();
    event.target = writeOwnRef.current;
    const title = writeOwnRef.current.value;

    console.log(event.target)

    if (title === "") {
      writeOwnRef.current.style.borderColor = "red";
    } else {
      props.onSelect(event);
    }
  }

  return (
    <section>
      <div className="bg-white p-6 border-0 -mt-1 text-left rounded-b-md mb-8">
        <h2 className="font-bold text-md mb-4">Generate a post title or write your own</h2>
        <p className="font-medium text-gray-500 text-sm">Let's generate or write a title for your blog post. The description you previously filled and the title you choose will have an influence on the generated content.</p>

        <div className="flex space-x-4 mt-4">
          <Button className="w-full" onClick={generateHandler}>
            {!isLoading && "Generate titles"}
            {isLoading && (
              <span>
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 inline text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </span>
            )}
          </Button>
          <button
            onClick={(e) => {
              e.preventDefault();
              setWriteOwn(true);
            }}
            className="w-full border border-gray-200 font-semibold rounded-md text-gray-500 hover:bg-gray-50 duration-200"
          >
            I'll write my own
          </button>
        </div>

        {writeOwn === false && titles.length > 0 && (
          <div className="p-6 bg-gray-150 mt-4 flex flex-col space-y-6 rounded-lg">
            {titles.map((item) => (
              <div key={item.id} className="flex bg-white p-6 rounded-lg shadow-sm items-start justify-between">
                <span className="text-lg font-bold">{item.data}</span>
                <button onClick={props.onSelect} value={item.data} name="blog_title" className="border border-gray-200 ml-4 font-semibold rounded-md text-gray-500 hover:bg-gray-50 duration-200 px-4 py-1.5 bg-white text-xs">
                  {item.selected ? "Selected" : "Select"}
                </button>
              </div>
            ))}
          </div>
        )}

        {writeOwn && (
          <div className="flex flow-root">
            <hr className="my-5" />
            <input ref={writeOwnRef} className={"w-full rounded-lg border-1 border-gray-400 mb-8"} placeholder="Blog title" type="text" name="blog_title" value={blogTitle.value} />
            <div className="flex flex-col items-center justify-center">
              <Button onClick={writeOwnHandler} className="mx-auto">
                Next
              </Button>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

/**
 * blog_intro
 */
const Tab3 = (props) => {
  const blogIntro = props.formFields.find((ff) => ff.field.id === "blog_intro");
  const [intros, setIntros] = useState(blogIntro.value ? [{ id: 0, data: blogIntro.value, selected: true }] : []);
  const [isLoading, setLoading] = useState(false);
  const { setCredits } = useCreditsContext();

  useEffect(() => {
    let screenHeight;

    if(props.appDataContext.isDependencyLoaded) {

      const body = document.body;
      const html = document.documentElement;

      screenHeight = Math.max(body.scrollHeight, body.offsetHeight,
        html.clientHeight, html.scrollHeight, html.offsetHeight);

      /* global SM */
      SM.client('resizeWindow', {height: screenHeight});
    }
  })

  async function generateHanlder(event) {
    event.preventDefault();
    setLoading(true);
    const language = props.formFields.find((ff, i) => ff.field.id == "language").value;
    const creativity = props.formFields.find((ff, i) => ff.field.id == "creativity").value;
    const blogDescription = props.formFields.find((ff, i) => ff.field.id == "blog_description").value;

    const request = {
      model: "blog-intros",
      blog_title: blogDescription,
      language: language,
      creativity: creativity,
      n: 4,
      key: props.appDataContext.user.cf_key,
    };
    modelHttp(props.appDataContext.user.cf_key, request)
      .then((response) => {
        const introsCount = intros.length;
        const newIntros = [];
        const data = response.data;
        setCredits(data.balance)
        for (const key in data.results) {
          newIntros.push({ id: introsCount + parseInt(key), data: data.results[key] });
        }
        setIntros([...newIntros, ...intros]);
        setLoading(false);
      })
      .catch((error) => {
        // handle error
        // the app cannot do anything without data...
        setLoading(false);
      });
  }

  return (
    <section>
      <div className="bg-white p-6 border-0 -mt-1 text-left rounded-b-md mb-8">
        <h2 className="font-bold text-md mb-4">Generate an intro paragraph or write your own</h2>
        <p className="font-medium text-gray-500 text-sm">Let's now write your blog introduction which will be the beginning of an amazing blog post. You will be able to edit it afterwards.</p>
        <div className="flex space-x-4 mt-4">
          <Button onClick={generateHanlder} className="w-full">
            {!isLoading && "Generate intros"}
            {isLoading && (
              <span>
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 inline text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </span>
            )}
          </Button>
          <button onClick={props.onSelect} name="blog_intro" className="w-full border border-gray-200 font-semibold rounded-md text-gray-500 hover:bg-gray-50 duration-200">
            I'll write my own
          </button>
        </div>
        {intros.length > 0 && (
          <div className="p-6 bg-gray-150 mt-4 flex flex-col space-y-6 rounded-lg">
            {intros.map((item, i) => (
              <div key={item.id} className="flex bg-white p-6 rounded-lg shadow-sm items-start justify-between">
                <span className="text-lg font-bold">{item.data}</span>
                <button onClick={props.onSelect} name="blog_intro" value={item.data} className="border border-gray-200 ml-4 font-semibold rounded-md text-gray-500 hover:bg-gray-50 duration-200 px-4 py-1.5 bg-white text-xs">
                  {item.selected ? "Selected" : "Select"}
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

/**
 * blog_outline
 */
const Tab4 = (props) => {
  const blogOutline = props.formFields.find((ff) => ff.field.id === "blog_outline");
  const [outlines, setOutlines] = useState(blogOutline.value ? [{ id: 0, data: blogOutline.value, selected: true }] : []);
  const [isLoading, setLoading] = useState(false);
  const { setCredits } = useCreditsContext();

  useEffect(() => {
    let screenHeight;

    if(props.appDataContext.isDependencyLoaded) {

      const body = document.body;
      const html = document.documentElement;

      screenHeight = Math.max(body.scrollHeight, body.offsetHeight,
        html.clientHeight, html.scrollHeight, html.offsetHeight);

      /* global SM */
      SM.client('resizeWindow', {height: screenHeight});
    }
  })

  function generateHandler(event) {
    event.preventDefault();
    setLoading(true);
    const language = props.formFields.find((ff, i) => ff.field.id == "language").value;
    const blogDescription = props.formFields.find((ff, i) => ff.field.id == "blog_description").value;
    const blogTitle = props.formFields.find((ff, i) => ff.field.id == "blog_title").value;

    const request = {
      model: "blog-outline",
      blog_title: blogTitle,
      blog_description: blogDescription,
      language: language,
      creativity: "High",
      n: 4,
      key: props.appDataContext.user.cf_key,
    };
    modelHttp(props.appDataContext.user.cf_key, request)
      .then((response) => {
        const outlinesCount = outlines.length;
        const newOutlines = [];
        const data = response.data;
        setCredits(data.balance)
        for (const key in data.results) {
          newOutlines.push({ id: outlinesCount + parseInt(key), data: data.results[key].replace(/(<([^>]+)>)/gm, "\n") });
        }
        setOutlines([...newOutlines, ...outlines]);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  return (
    <section>
      <div className="bg-white p-6 border-0 -mt-1 text-left rounded-b-md mb-8">
        <h2 className="font-bold text-md mb-4">Generate an outline (subheadings) for your post</h2>
        <p className="font-medium text-gray-500 text-sm">Let's now write your blog outline which will be the structure of your article. You will be able to edit it afterwards.</p>
        <div className="flex space-x-4 mt-4">
          {/*<Button onClick={generateHandler} className="w-full">Generate outlines</Button>*/}
          <Button onClick={generateHandler} className="w-full">
            {!isLoading && "Generate outlines"}
            {isLoading && (
              <span>
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 inline text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </span>
            )}
          </Button>
          <button onClick={props.onSelect} className="w-full border border-gray-200 font-semibold rounded-md text-gray-500 hover:bg-gray-50 duration-200">
            I'll write my own
          </button>
        </div>
        {outlines.length > 0 && (
          <div className="p-6 bg-gray-150 mt-4 flex flex-col space-y-6 rounded-lg">
            {outlines.map((item) => (
              <div key={item.id} className="flex bg-white p-6 rounded-lg shadow-sm items-start justify-between">
                <span className="text-lg font-bold">{item.data}</span>
                <button onClick={props.onSelect} name="blog_outline" value={item.data} className="border border-gray-200 ml-4 font-semibold rounded-md text-gray-500 hover:bg-gray-50 duration-200 px-4 py-1.5 bg-white text-xs">
                  {item.selected ? "Selected" : "Select"}
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

/**
 * paragraph for each subtitle
 */
const Tab5 = (props) => {
  return (
    <section>
      <div className="bg-white p-6 border-0 -mt-1 text-left rounded-b-md mb-8">
        <h2 className="font-bold text-md mb-4">Would you like to generate a paragraph for each subtitle of your outline?</h2>
        <p className="font-medium text-gray-500 text-sm">You now have the possibility to generate a single paragraph for each subheading of your outline.</p>
        <div className="flex space-x-4 mt-4">
          <Button onClick={props.onSelect} name="subheading_paragraphs" value="yes" className="w-full">
            Yes,&nbsp;please
          </Button>
          <Button buttonStyle="outlined" onClick={props.onSelect} name="subheading_paragraphs" value="no" className="w-full">
            No,&nbsp;thanks
          </Button>
        </div>
      </div>
    </section>
  );
};

export default function BlogWriterPage(props) {
  const formConfigDefault = [
    { field: { id: "language", order: 1, default: 0 }, value: LANGUAGES[0].name, tabId: 1 },
    { field: { id: "creativity", order: 1, default: 0 }, value: CREATIVITY_VALUES[0].name, tabId: 1 },
    { field: { id: "blog_description", order: 1 }, value: null, tabId: 1, required: true },
    { field: { id: "keyword", order: 1 }, value: [], tabId: 1, required: true },
    { field: { id: "blog_title", order: 2 }, value: null, tabId: 2, required: true },
    { field: { id: "blog_intro", order: 3 }, value: null, tabId: 3 },
    { field: { id: "blog_outline", order: 4 }, value: null, tabId: 4 },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [formFields, setFormFields] = useState(formConfigDefault);
  const [modal, setModal] = useState(null);
  const {appDataContext} = useContext(AppDataContext);
  const navigate = useNavigate();

  function toggleHanlder(currentTabId, nextTabId) {
    if (nextTabId > currentTabId) {
      const validateFields = formFields
        .filter((f) => f.tabId === currentTabId && f.required === true)
        .map((f) => {
          console.log(f);
          // return f.value !== "" || f.value !== undefined || f.value !== null
          switch (f.field.id) {
            case "keyword":
              return f.value.length > 0;
            default:
              return f.value !== null;
          }
        });

      const invalidField = validateFields.find((e) => e === false);

      if (validateFields.length > 0 && invalidField === false) {
        // console.log("no dice");
        // do an error
      } else {
        setActiveTab(nextTabId);
      }
    } else {
      setActiveTab(nextTabId);
    }
  }

  async function formSubmitHandler(event) {
    const sessionId = generateUUID();
    const sessionData = [...formFields, { field: { id: "generate" }, value: event.target.value }];
    localStorage.setItem(sessionId, JSON.stringify(sessionData));
    navigate("/tools/blog-writer?sessionId=" + sessionId);
  }

  function changeHandler(event) {

    const newFields = formFields.map((ff) => {

      console.log(event.target.name);

      if (ff.field.id == event.target.name) {
        if (event.target.value !== "") {
          ff.value = event.target.value;
        } else {
          ff.value = null;
        }
        return ff;
      } else {
        return ff;
      }
    });
    setFormFields(newFields);
  }

  function handleTabChange(n, event) {
    event.preventDefault();
    const newFields = formFields.map((ff) => {
      if(ff.field.id === event.target.name) {
        ff.value = event.target.value;
        return ff;
      }
      return ff;
    });
    setFormFields(newFields);
    const tabValues = newFields.filter(f => f.tabId === (n - 1));
    const hasEmptyFields = tabValues.find(f =>{
      if(Array.isArray(f.value)) {
        return f.value.length === 0;
      } else {
        return f.value === null || f.value === undefined
      }
    });

    if(hasEmptyFields) {
      setModal({title: "Error", message: "Fill out missing fields", type: "error"})
    } else {
      setActiveTab(n)
    }

  }

  useEffect(() => {
    if (appDataContext.isDependencyLoaded) {
      const body = document.body;
      const html = document.getElementById("root");
      const screenHeight = Math.max(body.scrollHeight, body.offsetHeight,
        html.clientHeight, html.scrollHeight, html.offsetHeight);

      /* global SM */
      SM.client("resizeWindow", { height: screenHeight });
    }
  });

  return (
    <form onSubmit={formSubmitHandler}>
      <div className="mx-auto max-w-7xl pb-8 px-4 sm:py-4 sm:pb-8 sm:px-6 lg:px-8">
        <div className="text-center">
          <h1 className="mt-1 text-xl font-extrabold tracking-tight text-gray-900 sm:text-xl lg:text-4xl">Write quality blog posts with AI</h1>
          <p className="mx-auto mt-5 max-w-7xl font-medium text-base text-gray-600">Go from a blog idea to an engaging blog post in minutes by following the steps below.</p>
        </div>
      </div>
      {modal && (
        <Modal
          onClose={() => {
            setModal(null);
          }}
          title={modal.title}
          type={modal.type}
          content={modal.message}
          buttonLabel="OK"
        />
      )}

      <div className="mx-auto min-h-full w-full text-center max-w-2xl bg-white shadow sm:rounded-lg">
        <BlogWriterSteps activeTab={activeTab} onToggle={toggleHanlder.bind(this, activeTab)} />

        {activeTab === 1 && <Tab1 onChange={changeHandler} formFields={formFields} />}

        {activeTab === 2 && <Tab2 onChange={changeHandler} onSelect={handleTabChange.bind(this, 3)} formFields={formFields} appDataContext={appDataContext} />}

        {activeTab === 3 && <Tab3 onChange={changeHandler} onSelect={handleTabChange.bind(this, 4)} formFields={formFields} appDataContext={appDataContext} />}

        {activeTab === 4 && <Tab4 onChange={changeHandler} onSelect={handleTabChange.bind(this, 5)} formFields={formFields} appDataContext={appDataContext} />}

        {activeTab === 5 && <Tab5 onChange={changeHandler} onSelect={formSubmitHandler} formFields={formFields} />}

        <div className="px-4 pb-5 mt-2 flex flow-root">
          {activeTab === 1 && (
            <Button onClick={handleTabChange.bind(this, activeTab + 1)} buttonSize="md" type="button">
              {isLoading && (
                <span>
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 inline text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </span>
              )}
              {!isLoading && <span>{"Next"}</span>}
            </Button>
          )}
        </div>
      </div>
    </form>
  );
}
