import React, { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import HeaderBanner from "../Partials/HeaderBanner";
import { AppDataContext, classNames } from "../data/AppHelpers";
import { config } from "../config";
import axios from "axios";
import { saveAs } from 'file-saver';
import { TOOLS } from "./../data/tools";
import { ScrollableDiv } from "../Components/ScrollableDiv";
import { TrashIcon, PencilIcon, ArrowDownTray } from "@heroicons/react/24/outline";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional

const tableViews = ["blog-post-writer", "blog writer", "article writer", "article generator"];

function getToolById(id) {
  return TOOLS.find((t) => t.id == id);
}

// TODO: Verify download handler in this context
function downloadHandler(url, e) {

  console.log(url);

  e.stopPropagation();

  fetch('https://copymatic.ai/image-fetcher/', {
    method: "POST",
    body: JSON.stringify({ "url": url },)
  }).then(response => {
    response.arrayBuffer().then(function (buffer) {
      const blobUrl = window.URL.createObjectURL(new Blob([buffer]));
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", "image.png");
      document.body.appendChild(link);
      link.click();
    });
  })
}

function TableImageGallery(props) {
  return (
    <>
      <ul className="container grid w-full grid-cols-2 gap-x-4 px-5 gap-y-8 md:grid-cols-2 sm:gap-x-6 lg:grid-cols-2 xl:grid-cols-3 xl:gap-x-8">
        {props.content.map((image) => (
          <>
            <li className="relative">
              <div className="group block aspect-square w-full cursor-pointer duration-200 overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                <img src={image.image_name} alt="generated" className="pointer-events-none object-cover h-full w-full group-hover:opacity-75" />
                <div className={classNames(props.isExpanded ? "top-0 right-0" : "bottom-0 right-0 ", "text-right absolute p-4 opacity-0 duration-200 pointer-events-none group-hover:pointer-events-auto group-hover:opacity-100 flex space-x-3")}>
                  <button onClick={(e) => { downloadHandler(image.image_name, e) }} type="button" className="inline-flex shadow-sm active:scale-[0.98] duration-100 items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50">
                    {/* <ArrowDownTrayIcon className="h-3 w-3 mr-1" /> */}
                    <span>Download</span>
                  </button>

                </div>
              </div>
            </li>
          </>
        ))}
      </ul>
    </>
  );
}

// documents are specifically for smart editor so these need to link to the smart editor
//
function DocumentTable(props) {
  return (
    <table className="bg-white table-auto w-full rounded-lg shadow-2xl">
      <thead>
        <tr className="h-20 text-gray-400">
          <th className="w-6/12 text-left p-5">{props.name}</th>
          <th className="w-2/12 text-left p-5">Word Count</th>
          <th className="w-2/12 text-left p-5">Date</th>
          <th className="w-2/12 text-left p-5">Actions</th>
        </tr>
      </thead>
      <tbody>
        {props.content.length > 0 &&
          props.content.map((item, i) => (
            <tr className="h-10 even:bg-white odd:bg-slate-100 shadow hover:shadow-lg border-b-gray-900" key={i}>
              <td className="text-left p-5 hover:text-primary">{item.title}</td>
              <td className="text-left p-5">{item.wordcount}</td>
              <td className="text-left p-5">{item.date}</td>
              <td className="text-left p-5">
             <span className="mx-5">
                 <a href="/edit-content/">View</a>
             </span>
                <span>
                 <a href="/edit-content/">Edit</a>
              </span>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}

function PostTableRow(props) {
  function getPageName(post_type, id) {
    let path;
    switch (post_type) {
      case "user_articles":
        path = "/tools/blog-writer?id=" + id;
        break;
      case "article-generator":
        path = "/edit-content/?id=" + id;
        break;
    }

    return path;
  }

  const editLink = getPageName(props.item.post_type, props.item.ID);
  const [isDownloadingCSV, setDownloadingCSV] = useState(false);
  console.log('props.item', props.item);

  function deleteHandler(event) {
    isDeleting(true);
    const deleteResult = props.onDelete(event);
    if(deleteResult && typeof deleteResult.then === 'function') {
      deleteResult.then((response) => {
        isDeleting(false);
        window.location.reload();
      }).catch(error => {
        console.error("Error during delete:", error);
        isDeleting(false);
      });
    } else {
      console.warn("onDelete did not return a promise.");
      isDeleting(false);
    }
  }

  const downloadCSVHandler = async (event) => {

    setDownloadingCSV(true);
    const contentToDownload = props.item.post_content;

    try {
      if (contentToDownload) {
        const blob = new Blob([contentToDownload], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'content.csv');
      } else {
        throw new Error("No data received");
      }
    } catch (error) {
      console.error('error while downloading content:', error);
    } finally {
      setDownloadingCSV(false);
    }
  };


  const [deleting, isDeleting] = useState(false);
  return (
    <tr key={"post-" + props.item.ID} className="h-10 even:bg-white odd:bg-slate-100 shadow  border-b-gray-900">
      <td className="text-left p-5 hover:text-primary">{props.item.post_name}</td>
      <td className="text-left p-5">{props.item.post_content.split(" ").length}</td>
      <td className="text-left p-5">{props.item.post_date}</td>
      <td className="text-left py-5 px-0">
        <div className="flex gap-2">
          <Tippy theme={"copymatic"} content="Edit">
            <div className="rounded-md border hover:bg-gray-50 cursor-pointer duration-100 border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 hover:text-primary-500 shadow-sm hover:text-primary-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm ">
              <NavLink to={editLink}>
                <PencilIcon className="h-4 w-4" />
              </NavLink>
            </div>
          </Tippy>
          <Tippy theme={"copymatic"} content="DownloadCSV">
            <button onClick={downloadCSVHandler}
                    className="rounded-md border hover:bg-gray-50 cursor-pointer duration-100 border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 hover:text-primary-500 shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"/>
              </svg>
            </button>
          </Tippy>
          <Tippy theme={"copymatic"} content="Delete">
            <button onClick={deleteHandler} value={props.item.ID}
                    className="mt-3 text-center cursor-pointer group hover:bg-gray-50 duration-300 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm">
              {deleting ? (
                <>
                  <svg className="animate-spin h-4 w-4 inline text-red-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </>
              ) : (
                <>
                  <TrashIcon className="h-4 w-4 group-hover:text-red-600" />
                </>
              )}
            </button>
          </Tippy>
        </div>
      </td>
    </tr>
  );
}

function PostsTable(props) {
  const [content, setContent] = useState(props.content);

  async function deleteHandler(event) {
    const itemId = event.currentTarget.value;
    console.log('itemId', itemId);

    try {
      const response = await props.onDelete({ target: { id: itemId } });
      if (response.status === 200) {
        setContent(c => c.filter(e => e.ID !== parseInt(itemId)));
      } else {
        console.log("Could not delete or missing data in response");
      }
    } catch (error) {
      console.error("Error during deletion:", error);
    }
  }

  const downloadCSVHandler = async () => {
    try {
      const contentToDownload = content.post_content;

      if (contentToDownload) {
        const blob = new Blob([contentToDownload], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'content.csv');
      } else {
        throw new Error("No data received");
      }
    } catch (error) {
      console.error("Error during download:", error);
    }
  };

  return (
    <table className="bg-white w-full rounded-lg  px-5">
      <thead>
        <tr className="h-20 text-gray-400">
          <th className="w-6/12 text-left p-5">Title</th>
          <th className="w-2/12 text-left p-5">Wordcount</th>
          <th className="w-2/12 text-left p-5">Date</th>
          <th className="w-2/12 text-left p-5">Actions</th>
        </tr>
      </thead>
      <tbody>{content && content.length > 0 && content.map((item, i) =>
          <PostTableRow
              item={item}
              index={i}
              key={"post-" + item.ID}
              onDelete={deleteHandler}
              onDownloadCSV={downloadCSVHandler}
          />
      )}
      </tbody>
    </table>
  );
}

function ListTable(props) {
  return (
    <ul className="container">
      {props.content.map((item, i) => (
        <li key={i} className="bg-white first:mt-0 p-5 m-5 rounded-lg shadow shadow-inner flex justify-between items-start">
          <p className='w-9/10 p-1'>{item.post_content}</p>
          <p onClick={(e) => props.onDelete(e)} className='text-right cursor-pointer w-1/10 p-1' id={item.ID}>
            <TrashIcon className="h-4 w-4 hover:text-red-600" id={item.ID} />
          </p>
        </li>
      ))}
    </ul>
  );
}

function TableContentView(props) {
  return (
    <>
      {props.type === "documents" && <DocumentTable {...props} />}
      {props.type === "posts" && <PostsTable {...props} />}
      {props.type === "list" && <ListTable {...props} />}
    </>
  );
}

function getContentCategories(posts, images) {
  const categories = {};
  posts
    .filter((post) => post.post_type === "ideas")
    .map((post, i) => {
      const tags = post.tag;

      if (tags && tags.length > 0) {
        const slug = tags[0].slug;
        if (categories.hasOwnProperty(tags[0].slug)) {
          const soFar = categories[tags[0].slug];
          categories[slug] = [...soFar, post];
        } else {
          categories[slug] = [post];
        }
      } else {
        categories["more"] = [...categories["more"], post];
      }
    });
  const generatedArticles = posts.filter((post) => post.post_type === "article-generator");
  const userArticles = posts.filter((post) => post.post_type === "user_articles");
  const landingPages = posts.filter((post) => post.post_type === "landing-pages");

  // if (generatedArticles.length > 0) categories["blog writer"] = generatedArticles;
  if (generatedArticles.length > 0) categories["article generator" || "blog writer"] = generatedArticles;
  if (userArticles.length > 0) categories["article writer"] = userArticles;

  if (Array.isArray(images) && images.length > 0) {
    categories["image generator"] = images;
  }

  return categories;
}

export default function ContentPage(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [content, setContent] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [error, setError] = useState(null);
  const { appDataContext, paidUserStatus } = useContext(AppDataContext);

  const fetchContent = async () => {
    console.log('fetching content');
    const url = config(appDataContext.ENV).copymatic_api + "content";
    const request = {
      jwt: appDataContext.jwt,
      env: "copymatic",
    };
    axios
        .post(url, request)
        .then((response) => {
          const data = response.data;
          console.log('response data:', data);
          const contentCategories = getContentCategories(data.posts, data.images);
          setContent(contentCategories);
          setIsLoading(false);
        })
        .catch((error) => {
          setError({ error: error });
        });
  };

  useEffect(() => {
    if (isLoading && !content) {
      fetchContent().then(r => console.log('fetched content'));
    }
  }, [isLoading, content]);

  useEffect(() => {
    if (appDataContext.isDependencyLoaded) {
      const body = document.body;
      const html = document.getElementById("root");
      const screenHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);

      /* global SM */
      SM.client("resizeWindow", { height: screenHeight });
    }
  });

  async function deleteHandler(e) {
    const url = config(appDataContext.ENV).copymatic_api + "del";
    const request = {
      jwt: appDataContext.jwt,
      env: "copymatic",
      id: e.target.id,
    };
    try {
      const response = await axios.post(url, request);

      if (response.status === 200) {
        return response.data;
      } else {
        console.error("Failed to delete, status code:", response.status);
        return null;
      }
    } catch (error) {
      console.error("Error during delete:", error);
      throw error;
    } finally {
      await fetchContent();
    }
  }

  return (
    <div className="mx-auto max-w-7xl">
      <div className="pb-0">
        {/* Upgrade Banner for Free Trial Users */}
        {!paidUserStatus ?
            <div className="mx-auto max-w-7xl md:px-6 md:px-8 ">
              <HeaderBanner appData={appDataContext}/>
            </div>
        : <></>}
        <div className="mx-auto max-w-7xl w-full px-4 sm:px-6 md:px-8 ">
          <div className="mx-auto max-w-7xl pb-8 px-4 pt-2 sm:pb-8 sm:px-6 lg:px-8">
            <div className="text-center">
              <h1 className="mt-1 text-xl font-extrabold tracking-tight text-gray-900 sm:text-3xl lg:text-4xl dark:text-white">My Content</h1>
              <p className="mx-auto mt-5 max-w-xl font-semibold text-base text-gray-700 dark:text-slate-400">Find all your articles and any saved content below.</p>
            </div>
          </div>
        </div>

        {/* Horizontal tabs */}
        <div className="lg:hidden block">
          <ScrollableDiv>
            {/* Temp tabs for making it overflow */}
            {!isLoading && content && (
              <ul className="flex items-center justify-start p-0 space-y-0  mr-4 border-gray-700 font-bold">
                {Object.keys(content).map((k, i) => {
                  const tool = getToolById(k);
                  const bg = activeTab === i ? "bg-gray-200" : "bg-transparent";
                  let className = "p-3 pb-1 text-left capitalize  text-gray-500 duration-200 rounded-md text-sm whitespace-nowrap hover:text-gray-900 hover:cursor-pointer" + bg;
                  return (
                    <li title={tool && tool.name} onClick={setActiveTab.bind(this, i)} key={i} className={classNames("w-min ml-0", className)}>
                      {tool && tool.name}
                      {!tool && k}
                    </li>
                  );
                })}
              </ul>
            )}
          </ScrollableDiv>
        </div>

        {/* Container with vertical tabs + content */}

        <div className="bg-gray-150 mb-6 h-[60vh] mx-8 md:mx-6 scroll overflow-y-auto shadow-inner flex items-start rounded-md shadow-sm border-gray-300 text-center dark:bg-slate-900">
          {/* Vertical tabs scroller */}
          <div className="hidden lg:block w-56">
            <div className="h-[60vh] ui-scroll pl-4 py-3 pr-1 overflow-y-scroll disable-scrollbars">
              <div className="border-green-500 ">
                {/* Vertical tabs */}
                {!isLoading && content && (
                  <ul className="flex-col p-0 w-full space-y-0 border-gray-700 font-bold">
                    {Object.keys(content).map((k, i) => {
                      const tool = getToolById(k);
                      const bg = activeTab === i ? "bg-gray-200" : "bg-transparent";
                      let className = "p-3 text-left capitalize w-full text-gray-500 truncate duration-200 w-80 rounded-md text-sm hover:text-gray-900 hover:cursor-pointer " + bg;
                      return (
                        <li title={tool && tool.name} onClick={setActiveTab.bind(this, i)} key={i} className={classNames("max-w-full truncate", className)}>
                          {tool && tool.name}
                          {!tool && k}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            </div>
          </div>

          {/* Content: Table / Posts / List  */}
          <div className="text-left lg:-ml-2 py-6 overflow-x-scroll overflow-y-scroll ui-scroll border-red-500 p-0 flex-grow disable-scrollbars">
            {!isLoading &&
              Object.keys(content).map((k, i) => {
                return (
                  <>
                    {activeTab === i && (
                      <>
                        {tableViews.includes(k) && <TableContentView content={content[k]} onDelete={deleteHandler} key={i} type="posts" />}
                        {!tableViews.includes(k) && k !== "image generator" && <TableContentView content={content[k]} onDelete={deleteHandler} key={i} type="list" />}
                        {k === "image generator" && <TableImageGallery content={content[k]} />}
                        {k === "documents" && <TableContentView content={content[k]} onDelete={deleteHandler} key={i} type="documents" />}
                      </>
                    )}
                  </>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}
