function getWords(content) {
    return content.split(" ").map(w => w.replace(/[^a-zA-Z0-9 ]/g, ''));
}

const hasShortParagraphs = (content) => {
    const paragraphs = content.split('\n');
    const paragraphWords = paragraphs.map(p => p.replace(/[^a-zA-Z0-9 ]/g, '').split(" "));
    const hasShorts = paragraphWords.map(wa => !(wa.length > 120))
    return !hasShorts.find(x => x === false)
};

const hasMinimumHeadlines = (headlines) => {
    return headlines.length >= 3;
};

const hasSubheadingKeyword = (headlines, keywords) => {
    const matches = headlines.map(h => keywords.includes(h));
    return matches.find(m => m === true) ? true : false;
};

// needs to be first 2 paragraphs
const hasKeywordInFirstParagraph = (content, keywords) => {
    const firstParagraph = content.split('<p>')[0];
    const matchingKeyword = keywords.map(k => firstParagraph.includes(k));
    return matchingKeyword.find(m => m === true) ? true : false;
};

const hasKeywordInContent = (content, keywords) => {
    const words = getWords(content);
    const keywordIsIncluded = keywords.map(keyword => words.includes(keyword));
    return keywordIsIncluded.filter(isKeywordIncluded => isKeywordIncluded).length > 0;
}

const getKeywordDensityScore = (content, keywords) => {
    let kwAppearances = 0;
    const totalWords = getWords(content).length;

    keywords.forEach(kw => {
        const matches = content.toLowerCase().match(new RegExp(kw, "g"));
        if (matches) {
            kwAppearances += matches.length;
        }
    });
    return (kwAppearances / totalWords) * 100;
}

const hasNumberInTitle = (title) => {
    const matches = title.match(/[0-9]/);

    if (matches) {
        return matches.length > 0;
    }
    return false;
}

const countRegex = (content, reg) => {
    const matches = content.match(reg)
    if (matches) {
        return matches.length;
    }
    return 0;
}

const countLinks = (content) => {
    return countRegex(content, new RegExp("<a", "g"));
}

const countParagraphs = (content) => {
    return countRegex(content, new RegExp("<p", "g"))
}

const hasKeywordInTitle = (keywords, title) => {
    const matches = keywords.map(keyword => {
        const regex = new RegExp(keyword, "g");
        const matches = title.match(regex);
        return matches ? true : false;
    })
    return matches.find(match => match === true) ? true : false;
}

export function getSEOAnalysis(content, headlines, title, keywords = []) {
    const linkCount = countLinks(content);
    let analysisData =  {
        contentOver800Words: getWords(content).length > 800,
        shortParagraphs: hasShortParagraphs(content),
        minimumHeadlines: hasMinimumHeadlines(headlines),
        titleContainsNumber: hasNumberInTitle(title),
        plagiarismScore: null, // since plagiarism is a paid feature default to a null score to signal downstream that it needs to be calculated
        countLinks: linkCount,
        countParagraphs: countParagraphs(content),
        countHeadlines: headlines.length,
        nineParagraphs: content.split("<p>").length > 15,
        fiveLinks: linkCount >= 5,
        keywordCount: keywords.length,
    }

    if (!keywords.length) {
        // no keyword data provided - remove keyword metrics from seo analysis data
        return analysisData;
    } else {
        return {
            ...analysisData,
            titleContainsKeyword: hasKeywordInTitle(keywords, title),
            subheadingKeyword: hasSubheadingKeyword(headlines, keywords),
            keywordInContent: hasKeywordInContent(content, keywords),
            keywordInFirstParagraphs: hasKeywordInFirstParagraph(content, keywords),
            keywordDensity: getKeywordDensityScore(content, keywords) > .63,
            threeKeywords: keywords.length <= 3,
        }
    }
};

export function getSEOScore({ minimumHeadlines, shortParagraphs, titleContainsNumber, keywordInContent = false, keywordInFirstParagraphs = false,  subheadingKeyword = false }) {
    const stats = [
        keywordInContent,
        keywordInFirstParagraphs,
        minimumHeadlines,
        shortParagraphs,
        subheadingKeyword,
        titleContainsNumber
    ];

    const positives = stats.filter(s => s === true);
    return positives.length * 12;
}

