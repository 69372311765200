import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { useEffect, useRef, useState } from "react";
import Label from "./Label";

export default function Textarea(props) {
  const textAreaRef = useRef(0);
  const [touched, setTouched] = useState(false);
  const isValid = props.value !== "" && props.value && props.value.length >= props.minLength;

  const hasMinLength = props.minLength;
  const textAreaStyle = "block w-full placeholder:text-gray-400 placeholder:font-medium rounded-md bg-gray-100 border-gray-300 shadow-sm focus:border-gray-400 focus:ring-0 focus:ring-primary-500 sm:text-sm  dark:bg-slate-800 dark:border-slate-700 dark:placeholder:text-gray-500  dark:text-white min-h-[58px]";

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <>
      <div className="mb-1 flex items-end justify-between gap-4">
        <Label name={props.name} required={props.required} labelHelpToolTip={props.labelHelpToolTip}>
          {props.label}
        </Label>
        {props.maxLength && (
          <div className={classNames(hasMinLength && props.value && props.value.length < hasMinLength ? "text-amber-600" : "text-teal-500", "font-bold text-xs text-gray-400")}>
            {props.value ? props.value.length : 0}/{props.maxLength}
          </div>
        )}
      </div>

      <textarea onChange={props.onChange} minLength={props.minLength} maxLength={props.maxLength} required={props.required} value={props.value || ""} rows={props.rows} name={props.name} id={props.name} className={classNames(hasMinLength && touched && !isValid && "border-amber-500  focus:border-amber-500", textAreaStyle)} placeholder={props.placeholder} ref={textAreaRef} onBlur={() => setTouched(true)} />

      {props.value && props.minLength && props.value.length < props.minLength && (
        <div className="flex items-center mt-1 text-amber-600 text-xs font-medium">
          <ExclamationTriangleIcon className="h-4 w-4 text-orange-400 mt-0.5 mr-1" />
          {props.minLength - props.value.length} more characters needed in your description.
        </div>
      )}
    </>
  );
}
