import { useContext, useState } from "react";
import ArticleGeneratorForm from "../Components/tools/ArticleGenerator";
// import axios from 'axios';
import { AppDataContext } from "./../data/AppHelpers";
import { TOOLS } from "./../data/tools";
import { modeHttp, buildRequest } from './../utils/http';

export default function ToolDetailsPageSingleCol(props) {

  const [isLoading, setIsLoading] = useState(false);
  const [toolData, setToolData] = useState({});
  const { appDataContext } = useContext(AppDataContext);

  async function formSubmitHanlder(toolFormData) {

    console.log(toolFormData);

    return {};
    // const request = {
    //   ...toolFormData,
    //   key: appDataContext.account.cfKey // in hopes I can go right to /temp-api eventually
    // };

    const formFields = {

    };

    const request = buildRequest(appDataContext, formFields, TOOLS.find(t => t.id === 'article-generator'))

    // TODO find out if we can cut this out and go right to the source
    // const response = await axios.post('https://copymatic.ai/wp-json/copymatic/proxy_submit_request', request)
    //   .then((response) => {
    //     // responses from the API are always 200 OK
    //     // need to parse out error(s) if any and/or real data
    //     const responseData = JSON.parse(response.data);
    //     // setIsLoading(false);
    //     // set App state to the data which should merge with the existing data
    //     setToolData(responseData);
    //     return responseData;
    //   })
    //   .catch((error) => {
    //     // handle error
    //     // the app cannot do anything without data...
    //     // this.setState({error: error});
    //     // console.log(error);
    //   });
  }

  // function handleChange(event) {
  //   console.log(event)
  // }

  let display;

  if (toolData.results) {
    display = (<div>Results</div>);
  } else if (toolData.success === false && toolData.error) {
    display = (
      <div>{toolData.error}</div>
    );
  } else {
    display = (
      <>
        <div className="mx-auto max-w-7xl py-8 px-4 sm:py-8 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className="mt-1 text-xl font-extrabold tracking-tight text-gray-900 sm:text-xl lg:text-4xl dark:text-white">Generate Articles With AI</h1>
            <p className="mx-auto mt-5 max-w-xl font-semibold text-base text-gray-700 dark:text-slate-400">Turn a title and outline into a long and engaging article.</p>
          </div>
        </div>

        {/* Tool form */}
        <div className="mx-auto min-h-full w-full text-center max-w-2xl">
          <section aria-labelledby="Tool-title" className="text-left">
            <div className="bg-white shadow sm:rounded-lg dark:bg-slate-900">
              <ArticleGeneratorForm onChange={() => { console.log("changed form") }} isLoading={isLoading} onFormSubmit={formSubmitHanlder} />
            </div>
          </section>
        </div>
      </>
    );
  }

  return display;
}
