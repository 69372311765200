import { Fragment } from "react";
import ReactDOM from "react-dom";
import { Transition } from "@headlessui/react";
import "./LoadingModal.css";

export default function LoadingModal(props) {
  return (
    <>
      {ReactDOM.createPortal(
        <Fragment>
          <Transition.Root show={true} as={Fragment}>
            <div className="relative z-50">
              <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                <div className="fixed inset-0 bg-slate-900 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <div className="relative transform overflow-hidden rounded-lg bg-white px-6 pt-8 pb-6 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-6">
                      <div>
                        <div className="relative flex py-4 items-center">
                          <div className="w-fit mt-4 mx-auto scale-[1.7]">
                            <div className="dot-pulse"></div>
                          </div>
                        </div>

                        <div className="mt-3 text-center sm:mt-5">
                          <h3 className="text-2xl font-extrabold leading-6 text-gray-900">{props.title}</h3>
                          <div className="mt-4 mb-4">
                            <p className="text-base font-medium text-gray-500"> {props.content}</p>
                          </div>
                        </div>
                      </div>

                      {props.buttonLabel ? (
                        <div className="mt-5 sm:mt-6 text-center">
                          <button type="button" className="inline-flex w-min mx-auto justify-center rounded-md focus:outline-none border-transparent bg-primary-600 duration-200 px-6 py-2 text-base font-bold text-white shadow-sm hover:bg-primary-700 sm:text-sm" onClick={props.onClose}>
                            {props.buttonLabel}
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Transition.Root>
        </Fragment>,
        document.getElementById("modal-root")
      )}
    </>
  );
}
