import { useState, useRef, useEffect, useContext } from "react";
import Button from "../../Components/ui/Button";
import SaveButton from "../../Components/ui/SaveButton";
import Accordion from "./Accordion";
import ProgressBar from "../../Components/ui/ProgressBar";
import CopyContentButton from "./CopyContentButton";
import LoadingModal from "../../Components/ui/LoadingModal";
import Modal from "../../Components/ui/Modal";
import { Editor } from "@tinymce/tinymce-react";
import "./EditContent.css";
import { config } from "../../config";
import { AppDataContext, countWords } from "../../data/AppHelpers";
import { getSEOAnalysis, getSEOScore } from "../../utils/seocalculator";
import {modelHttp, plagarismHttp} from "../../utils/http";
import {useNavigate, useParams } from "react-router-dom";
import {error, success} from "../../notify/Notify";
import axios from "axios";

export default function EditContent({ formFields, setFormFields, output, setOutput, props }) {
  const navigate = useNavigate();
  const { toolId } = useParams();
  const searchParams = new URLSearchParams(window.location.search);
  const articleId = searchParams.get('id');
  const { appDataContext } = useContext(AppDataContext);
  const [postID, setPostID] = useState(null);
  const [modal, setModal] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const editorRef = useRef(null);

  const userId = appDataContext.user.id;

  const titleField = formFields?.find(f => f.field.name === "blog_title");
  const title = titleField ? titleField.value : '';

  const subheadingField = formFields?.find(f => f.field.name === "subheading");
  const headlines = subheadingField ? subheadingField.value : [];

  const keywordField = formFields?.find(f => f.field.name === "keyword");
  const keywords = keywordField ? keywordField.value : [];

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);


  let content;


  if (toolId === 'article-generator') {
    const intro = output.find(o => o.type === 'intro');

    // Only modify the output if there is an intro
    if (intro) {
      output = output.filter(o => o.type !== 'intro');
    }

    const contentParts = ["<h2>" + title + "</h2>"];

    if (intro) {
      contentParts.push("<p>" + intro.content + "</p>");
    }

    headlines.forEach((headline, idx) => {
      contentParts.push("<h3>" + headline + "</h3>");
      if (output.hasOwnProperty(idx)) {
        contentParts.push("<p>" + output[idx].content + "</p>");
      }
    });

    content = contentParts.join('\n');
  } else {
    const contentParts = ["<h2>" + title + "</h2>"];

    headlines.forEach((headline, idx) => {
      contentParts.push("<h3>" + headline + "</h3>");
      if (output.hasOwnProperty(idx)) {
        contentParts.push("<p>" + output[idx].content + "</p>");
      }
    });

    content = contentParts.join('\n');
  }

  let initSeoAnalysis = keywords?.length
      ? getSEOAnalysis(content, headlines, title, keywords)
      : getSEOAnalysis(content, headlines, title);

  const [isSaving, setSaving] = useState(false);
  const [SEOScore, setSEOScore] = useState(getSEOScore(initSeoAnalysis));
  const [editorContent, setEditorContent] = useState(content);
  const [selected, setSelected] = useState(null);
  const [SEOAnalysis, setSEOAnalysis] = useState(initSeoAnalysis);



  function getColor(value) {
    switch (true) {
      case value <= 10:
        return "bg-red-500";
      case value >= 75:
        return "bg-green-600";
      default:
        return "bg-primary-500";
    }
  }

  function copyContent(type) {
    let data;
    if (type === "html") {
      data = editorRef.current.getContent();
    } else {
      data = editorRef.current.getContent({ format: "text" });
    }
    navigator.clipboard.writeText(data);
  }

  // Fetch article data by ID
  useEffect(() => {
    const fetchArticleContent = async () => {
      if (!articleId) return;

      const url = `https://copymatic.ai/wp-json/copymatic/post/${articleId}`;

      const requestData = {
        user_id: userId,
        secret: "e5e9fa1ba31ecd",
      };

      try {
        const response = await axios.post(url, requestData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const data = response.data;
        if (!data) {
          throw new Error('Data not found');
        }

        setEditorContent(data.content || '');
        setLoading(false);
    } catch (error) {
      console.error('Error fetching content:', error);
      setError(error);
      setLoading(false);
    }
  };

  fetchArticleContent();
}, [articleId, userId, formFields, setFormFields]);



  async function saveNewHandler(event) {
    console.log("starting save handler...");
    setSaving(true);

    // Retrieve content from the editor
    let content = editorRef.current.getContent();

    // Sanitize the content
    content = content.replace(/^\s*"*\s*/, '');

    const request = {
      jwt: appDataContext.jwt,
      env: "copymatic",
      key: appDataContext.user.meta.cf_key,
      type: "article-generator",
      post_content: content.replace(/\n/g, " "),
      post_title: content.split("\n")[0].trim(),
      language: "",
      keyword: "",
    };

    const url = config(appDataContext.ENV).copymatic_api + "save";

    try {
    if (content !== "" && !postID) {
      const response = await axios.post(url, request);
        if (response.data.post_id) {
          setPostID(response.data.post_id);
          setSaving(false);
          navigate("/content");
          success("Content saved", true);
        } else {
          error("Could not save content.", false);
        }
    } else if (postID) {
      request.post_id = postID;
      const response = await axios.post(url, request);
        if (response.data.post_id) {
          setSaving(false);
          success("Content saved", true);
        } else {
          error("Could not save content", false);
        }
    } else {
      setSaving(false);
      error("Could not save content", false);
    }
  } catch (error) {
      setSaving(false);
      console.error('error while saving content:', error);
        error("Could not save content", false);
    }
  }

// updates article after editing
    async function updateHandler () {
      if (!articleId) return;

      const url = `https://copymatic.ai/wp-json/copymatic/update/${articleId}`;

      const updatedContent = editorRef.current.getContent();

      const requestData = {
        user_id: userId,
        secret: "e5e9fa1ba31ecd",
        title: title,
        content: updatedContent,
      };

      try {
        const response = await axios.post(url, requestData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const data = response.data;

        navigate("/content");

        if (!data) {
          throw new Error('Data not found');
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching content:', error);
        setError(error);
        setLoading(false);
      }
    }

    // do we want to save a new article or update and existing one
    const saveHandler = () => {
    if (articleId) {
      updateHandler();
    } else {
      saveNewHandler();
    }
    }


  //
  // async function paraphraseHandler() {
  //   const url = config(appDataContext.ENV).copymatic_api + "proxy_submit_request";
  //   const request = {
  //     jwt: appDataContext.jwt,
  //     env: "copymatic",
  //     key: appDataContext.user.meta.cf_key,
  //     model: "rewrite-paragraph",
  //     text: editorRef.current.selection.getContent(),
  //     language: "english", // get the right language from the post metadata
  //     n: 1,
  //   };

  //   setLoadingModalTitle("Paraphrasing...");
  //   setIsLoading(true);

  //   axios.post(url, request).then((response) => {
  //     const data = JSON.parse(response.data);
  //     setCredits(data.balance);
  //     if (data.results.hasOwnProperty(1)) {
  //       editorRef.current.selection.setContent(data.results[1]);
  //     }
  //     setIsLoading(false);
  //   });
  // }

  async function generateHandler() {
    const request = {
      key: appDataContext.user.cf_key,
      model: 'rewrite-paragraph',
      language: formFields?.find(f => f.field.name == "language").value,
      text: selected,
    }

    modelHttp(JSON.stringify(request))
      .then((response) => {
        let newContent = editorContent.replace(selected, response.results[1]);
        setEditorContent(newContent);

      })
  }

  async function checkPlagiarismHandler(toolFormData) {
    let content = editorContent;
    const cfKey = appDataContext.user.meta.cf_key;
    const jwtToken = appDataContext.jwt;
    setIsLoading(true);

    try {
      const response = await plagarismHttp(jwtToken, cfKey, content);

      let title;
      let message;
      let percent;

          if (response.data) {
            percent = response.data.rate;

            switch (true) {
              case percent === 0:
                title = "Perfect Rate";
                message = `Congrats! The plagiarism rate of this text is 0%. This means that your text is completely original. ${response.data.results.toString()} results found for ${response.data.wordcount.toString()} words.`
                break;
              case percent <= 5:
                title = "Good Rate";
                message = `The plagiarism rate of this text is ${percent}%. ${response.data.results.toString()} results found for ${response.data.wordcount.toString()} words.`
                break;
              case percent > 5 && percent <= 10:
                title = "Reasonable Rate";
                message = `The plagiarism rate of this text is ${percent}%. ${response.data.results.toString()} results found for ${response.data.wordcount.toString()} words.`
                break;
              default: {
                title = "High Plagiarism Detected";
                message = `The plagiarism rate of this text is ${percent}%. Use the rewrite tool to bring the plagiarism score lower. ${response.data.results.toString()} results found for ${response.data.wordcount.toString()} words.`
              }
            }
          } else {
            title = "High Plagiarism Detected";
            message = "Use the rewrite tool to bring the plagiarism score lower."
          }
          setIsLoading(false)

      setModal({
        title: title,
        message: message,
      });

      // props.seoAnalysisHandler(seoAnalysis => ({
      //   ...seoAnalysis,
      //   plagiarismScore: (percent <= 5)
      // }));

    } catch (error) {
      console.log('Plagiarism check error:', error);
      setModal({
        title: "Error",
        message: "Error checking plagiarism.",
      });
    } finally {
      setIsLoading(false);
    }
  }


  const modalHandler = () => {
    setModal(null);
  };

  return (
    <>
      <div className="min-h-screen h-screen w-full">
        <div className="grow h-[calc(100vh_-_4rem)]">
          <div className="mx-auto h-full flex w-full border-0 border-green-500 gap-0">
            <div className="border-l border-r h-[calc(100vh_-_4rem)] flex flex-col bg-white grow">
              <main className="grow scroll overflow-y-hidden h-[calc(100vh_-_12rem)] border-green-500">
                <div className="max-w-5xxl mxx-auto">
                  <Editor
                    apiKey="uky8jl7ndgtd9hhprequ6yzavxlz5mqo062x0m9mmnbxiyi4"
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    initialValue={editorContent}
                    content_style="body {padding: 100px}"
                    init={{
                      menubar: false,
                      statusbar: false,
                      inline_boundaries: false,
                      branding: false,
                      toolbar_sticky: true,
                      plugins: ["advlist", "autolink", "lists", "link", "image", "charmap", "preview", "anchor", "searchreplace", "visualblocks", "code", "fullscreen", "insertdatetime", "media", "table", "code", "help", "wordcount"],
                      toolbar: "undo redo | blocks | " + "bold italic link | alignleft aligncenter " + "alignright alignjustify | outdent indent | ",
                      content_style: "body { }",
                      setup: function (editor) {
                        editor.on("NodeChange", function (e) {
                          if (editor.selection.getContent().length > 0) {
                            setSelected(editor.selection.getContent());
                          } else {
                            setSelected(null);
                          }
                        });
                      },
                    }}
                  />
                </div>
              </main>
              {selected &&
                <div >
                  <footer className="flex justify-center space-x-4 px-6 items-center border-t border-gray-200 h-16">
                    <Button onClick={generateHandler} buttonSize="sm" buttonStyle="primary" className="w-48">
                      Rewrite
                    </Button>
                  </footer>
                </div>
              }
            </div>
            {/* Right col */}
            <aside className="hidden min-w-[200px] max-w-[300px] 2xl:w-[300px]  2xl:max-w-[500px] 3xl:min-w-[600px] lg:block">
              <div className="sticky top-6">
                <div className="px-6 py-6 pb-4 flex flex-col space-y-3">
                  {<SaveButton onClick={saveHandler} isLoading={isSaving} buttonStyle="primary" buttonSize="lg" className="w-full">
                    Save
                  </SaveButton>}
                  <CopyContentButton copyContent={copyContent}></CopyContentButton>
                </div>
                {SEOScore && (
                  <div className="px-6 py-6 pb-4 flex flex-col text-center space-y-4">
                    <div className="font-bold">
                      SEO score: {SEOScore}
                      <span className="text-xs font-normal">/100</span>
                    </div>
                    <div>
                      <ProgressBar bgColor={getColor(SEOScore)} completed={SEOScore} />
                    </div>
                  </div>
                )}

                <div id="content-stats" className="px-6 py-6 flex flex-col space-y-4">
                  <div>
                    <dl className="grid grid-cols-2 gap-8 mx-auto">
                      <div className="flex flex-col text-center">
                        <dt className="order-1 text-xs font-bold text-gray-400 mb-1 uppercase">Delivery</dt>
                        <dd className="order-2 text-2xl font-bold text-gray-900 sm:text-2xl sm:tracking-tight">{countWords(editorContent)}</dd>
                      </div>
                      <div className="flex flex-col text-center">
                        <dt className="order-1 text-xs font-bold text-gray-400 mb-1 uppercase">Headings</dt>
                        <dd className="order-2 text-2xl font-bold text-gray-900 sm:text-2xl sm:tracking-tight">{SEOAnalysis.countHeadlines}</dd>
                      </div>
                      <div className="flex flex-col text-center">
                        <dt className="order-1 text-xs font-bold text-gray-400 mb-1 uppercase">Paragraph</dt>
                        <dd className="order-2 text-2xl font-bold text-gray-900 sm:text-2xl sm:tracking-tight">{SEOAnalysis.countParagraphs}</dd>
                      </div>
                      <div className="flex flex-col text-center">
                        <dt className="order-1 text-xs font-bold text-gray-400 mb-1 uppercase">Links</dt>
                        <dd className="order-2 text-2xl font-bold text-gray-900 sm:text-2xl sm:tracking-tight">{SEOAnalysis.countLinks}</dd>
                      </div>
                    </dl>
                  </div>
                </div>
                {/* Your content */}
                <Accordion
                    editorRef={editorRef}
                    keywords={keywords}
                    seoAnalysis={SEOAnalysis}
                    seoAnalysisHandler={setSEOAnalysis}
                    checkPlagiarism={checkPlagiarismHandler}
                >
                </Accordion>
                {isLoading && (
                    <LoadingModal
                        title="Checking Plagiarism"
                        content="Please wait, this can take a few seconds."
                        onClose={modalHandler}
                    />
                )}
                {modal && (
                    <Modal
                        onClose={modalHandler}
                        title={modal.title}
                        content={modal.message}
                        buttonLabel="OK"
                    />
                )}
              </div>
            </aside>
          </div>
        </div>
      </div>
    </>)

}

