export default function Unauthorized() {
    return (
        <div className="grid h-screen place-items-center">
            <div className="">
                <div className="text-center border-solid border-2 border-blue-300 bg-slate-100 rounded-xl p-8">
                    Unauthorized Access
                </div>
                <br />
                <div className="mt-15" >If this problem persists please contact support</div>
            </div>
        </div>
    );
}