import { LANGUAGES, CREATIVITY_VALUES, TONE_OF_VOICE_VALUES, COMPANY_TYPE_VALUES, COLOR_VALUES, PAGE_STRUCTURE_VALUES, IMAGE_STYLE_VALUES, IMAGE_MEDIUM_VALUES, IMAGE_ARTIST_VALUES, IMAGE_MOOD_VALUES, IMAGE_DETAILS_VALUES } from "./languages";

const TONE_OF_VOICE_CONFIG = (idx, colSpan) => {
  return {
    order: idx,
    type: "dropdown",
    name: "tone",
    label: "Tone of voice",
    required: true,
    values: TONE_OF_VOICE_VALUES,
    colSpan: colSpan ? colSpan : 1,
  };
};

const CREATIVITY_CONFIG = (idx, colSpan) => {
  return {
    order: idx,
    type: "dropdown",
    name: "creativity",
    label: "Creativity",
    required: true,
    values: CREATIVITY_VALUES,
    colSpan: colSpan ? colSpan : 1,
  };
};

const LANGUAGES_CONFIG = (idx, colSpan) => {
  return {
    order: idx,
    type: "dropdown",
    name: "language",
    label: "Language",
    required: true,
    values: LANGUAGES,
    colSpan: colSpan ? colSpan : 1,
  };
};

const AUDIENCE_CONFIG = (idx, colSpan) => {
  return {
    order: idx,
    type: "input",
    name: "audience",
    label: "Audience",
    required: true,
    placeholder: "Freelancers, Designers,...",
    colSpan: colSpan ? colSpan : 1,
  };
};

const PROUDCT_NAME_CONFIG = (idx, colSpan) => {
  return {
    order: idx,
    type: "input",
    name: "productname",
    label: "Product Name",
    required: true,
    placeholder: "Netflix, Spotify, Uber...",
    colSpan: colSpan ? colSpan : 1,
  };
};

const PROUDCT_DESCRIPTION_CONFIG = (idx, colSpan) => {
  return {
    order: idx,
    type: "textarea",
    name: "description",
    label: "Product Description",
    maxLength: 400,
    required: true,
    placeholder: "Explain here to the AI what your product (or service) is about. Rewrite to get different results.",
    colspan: colSpan ? colSpan : 1,
    rows: 3,
  };
};

const COMPANY_NAME_CONFIG = (idx, colSpan) => {
  return {
    order: idx,
    type: "input",
    name: "company_name",
    label: "Company Name",
    required: true,
    placeholder: "Amazon",
    colSpan: colSpan ? colSpan : 1,
  };
};

const COMPANY_DESCRIPTION_CONFIG = (idx, colSpan) => {
  return {
    order: idx,
    type: "textarea",
    name: "business_description",
    label: "Company Description",
    required: true,
    placeholder: "Briefly describe what your company is about.",
    colSpan: colSpan ? colSpan : 1,
  };
};

const TOPIC_CONFIG = (idx, colSpan) => {
  return {
    order: idx,
    type: "textarea",
    name: "topic",
    label: "What is your blog post about?",
    placeholder: "e.g. a blog article about the best tools to increase your website traffic",
    required: true,
    colSpan: colSpan ? colSpan : 1,
    rows: 5,
  };
};

const INSTAGRAM_TOPIC_CONFIG = (idx, colSpan) => {
  return {
    order: idx,
    type: "textarea",
    name: "topic",
    label: "What is this post about?",
    placeholder: "e.g. Heading to Los Angeles on weekend",
    required: true,
    colSpan: colSpan ? colSpan : 1,
    rows: 5,
  };
};

const KEYWORD_CONFIG = (idx, colSpan) => {
  return {
    order: idx,
    type: "tagsInput",
    name: "keyword",
    label: "Keyword to include",
    placeholder: "cryptocurrencies",
    colSpan: colSpan ? colSpan : 1,
    rows: 3,
  };
};

const BLOG_TITLE_CONFIG = (idx, colSpan) => {
  return {
    order: idx,
    type: "textarea",
    name: "blog_title",
    label: "Title of your blog article",
    required: true,
    colSpan: colSpan ? colSpan : 1,
    rows: 2,
    placeholder: "e.g. 5 ways to boost your sales with copywriting",
    defaultValue: "Why you should use an AI writer in 2022",
  };
};

const BLOG_DESCRIPTION = (idx, colSpan) => {
  return {
    order: idx,
    type: "textarea",
    name: "blog_description",
    label: "What is your article about?",
    required: true,
    maxLength: 200,
    minLength: 40,
    colSpan: colSpan ? colSpan : 1,
    rows: 5,
    placeholder: "e.g. a blog article explaining how copywriting can drive more traffic to your website.",
    defaultValue: "An article about using an AI-powered content writer to generate blog articles.",
  };
};

const ARTICLE_CONFIG = (idx, colSpan) => {
  return {
    order: idx,
    type: "textarea",
    name: "article",
    label: "What would you like to rewrite?",
    required: true,
    colSpan: 2,
    rows: 3,
    placeholder: "Paste the content or the URL of the article that you want to rewrite",
    defaultValue: "An article comparing the quality of life and cost of living of the major cities in the U.S.",
  };
};

const TEXT_CONFIG = (idx, colSpan) => {
  return {
    order: idx,
    type: "textarea",
    name: "text",
    label: "What would you like to rewrite?",
    required: true,
    colSpan: 2,
    rows: 3,
    placeholder: "Paste the content of the article that you want to rewrite",
    defaultValue: "An article comparing the quality of life and cost of living of the major cities in the U.S.",
  };
};

/* TOOL FORM CONFIGS */
export const FORM_FIELDS_ARTICLE_REWRITER = [LANGUAGES_CONFIG(1, 2), TEXT_CONFIG(2, 2)];

export const FORM_FIELDS_WEBSITE_HEADLINES = [LANGUAGES_CONFIG(1, 2), CREATIVITY_CONFIG(2), TONE_OF_VOICE_CONFIG(3), AUDIENCE_CONFIG(4), PROUDCT_NAME_CONFIG(5), PROUDCT_DESCRIPTION_CONFIG(6, 2)];

export const FORM_FIELDS_PERSONAL_BIO = [
  LANGUAGES_CONFIG(1),
  CREATIVITY_CONFIG(2),
  {
    order: 3,
    type: "input",
    name: "full_name",
    label: "Full Name",
    required: true,
    placeholder: "e.g. Elon Musk",
    colSpan: 1,
    defaultValue: "Elon Musk",
  },
  {
    order: 4,
    type: "input",
    name: "current_position",
    label: "Current Position",
    required: true,
    placeholder: "e.g. CEO at Tesla",
    defaultValue: "CEO at Tesla",
    colSpan: 1,
  },
  {
    order: 5,
    type: "input",
    name: "current_industry",
    label: "Current Industry",
    required: true,
    placeholder: "e.g. Cars",
    colSpan: 1,
    defaultValue: "Rockets",
  },
  {
    order: 6,
    type: "input",
    name: "current_city",
    label: "Current City",
    required: true,
    placeholder: "e.g.Austin, Texas",
    defaultValue: "Austin, Texas",
    colSpan: 1,
  },
  {
    order: 7,
    type: "input",
    name: "childhood",
    label: "Childhood",
    required: true,
    placeholder: "e.g. Born and raised in South Africa",
    defaultValue: "Born and raised in South Africa",
    colSpan: 2,
  },
  {
    order: 8,
    type: "input",
    name: "hobbies",
    label: "Hobbies",
    required: true,
    placeholder: "e.g. French food",
    defaultValue: "French food",
    colSpan: 1,
  },
  {
    order: 9,
    type: "input",
    name: "interests",
    label: "Interests",
    required: true,
    placeholder: "e.g. Phyiscs",
    defaultValue: "e.g. Phyiscs",
    colSpan: 1,
  },
  {
    order: 10,
    type: "input",
    name: "skills",
    label: "Skills",
    required: true,
    placeholder: "e.g. Innovator and Leader",
    defaultValue: "Innovator and Leader",
    colSpan: 1,
  },
  {
    order: 11,
    type: "input",
    name: "traits",
    label: "Character Traits",
    required: true,
    placeholder: "e.g. Hard worker",
    defaultValue: "Hard worker",
    colSpan: 1,
  },
  {
    order: 12,
    type: "dropdown",
    name: "pov",
    label: "Point of View",
    required: true,
    values: [
      {
        id: 1,
        name: "First Person",
      },
      {
        id: 2,
        name: "Third Person",
      },
    ],
    colSpan: 2,
  },
];

export const FORM_FIELDS_WEBSITE_SUBHEADERS = [LANGUAGES_CONFIG(1, 2), CREATIVITY_CONFIG(2), TONE_OF_VOICE_CONFIG(3), AUDIENCE_CONFIG(4), PROUDCT_NAME_CONFIG(5), PROUDCT_DESCRIPTION_CONFIG(6, 2)];

export const FORM_FIELDS_IMAGE_GENERATOR = [
  {
    order: 1,
    type: "textarea",
    name: "image_description",
    label: "Image Description",
    labelHelpToolTip: "Add as many details as possible about the character, place, position, angle of the photo, colors, etc...",
    required: true,
    maxLength: 200,
    colSpan: 2,
    rows: 3,
    placeholder: "Photography of an astronaut driving a sports car on the moon",
    defaultValue: "An astronaut driving a futuristic car on the surface of the moon",
  },
  {
    order: 2,
    type: "dropdown",
    name: "image_engine",
    label: "Engine",
    labelHelpToolTip: "<strong>Realistic:</strong> Perform better for photos of real objects (humans, dogs,...) but has a stricter content policy.<br><strong>Fictional:</strong> Perform better for fictional characters, art, drawings, etc...<br><strong>Switch the engine to get the best results.</strong>",
    required: false,
    values: [
      {
        id: 1,
        name: "Realistic",
      },
      {
        id: 2,
        name: "Fictional",
      },
    ],
    colSpan: 2,
  },
  {
    order: 3,
    type: "dropdown",
    name: "image_style",
    label: "Style",
    required: false,
    values: IMAGE_STYLE_VALUES,
    colSpan: 2,
  },
  {
    order: 4,
    type: "dropdown",
    name: "image_medium",
    label: "Medium",
    required: false,
    values: IMAGE_MEDIUM_VALUES,
    colSpan: 1,
  },
  {
    order: 5,
    type: "dropdown",
    name: "image_artist",
    label: "Artist",
    required: false,
    values: IMAGE_ARTIST_VALUES,
    colSpan: 1,
  },
  {
    order: 6,
    type: "dropdown",
    name: "image_mood",
    label: "Mood",
    required: false,
    values: IMAGE_MOOD_VALUES,
    colSpan: 1,
  },
  {
    order: 7,
    type: "dropdown",
    name: "image_details",
    label: "Details",
    required: false,
    values: IMAGE_DETAILS_VALUES,
    colSpan: 1,
  },
];

export const FORM_FIELDS_BLOG_IDEAS = [
  LANGUAGES_CONFIG(1),
  CREATIVITY_CONFIG(2),
  {
    order: 5,
    type: "textarea",
    name: "topic",
    label: "What is your blog post about?",
    required: true,
    maxLength: 200,
    colSpan: 2,
    rows: 5,
    placeholder: "e.g. 5 ways to boost your sales with copywriting",
    defaultValue: "A blog article about increasing your website traffic with AI-powered writers",
  },
];

export const FORM_FIELDS_BLOG_OUTLINES = [LANGUAGES_CONFIG(1), CREATIVITY_CONFIG(2), BLOG_TITLE_CONFIG(3, 2), BLOG_DESCRIPTION(4, 2)];

export const FORM_FIELDS_LANDING_PAGE = [
  LANGUAGES_CONFIG(1),
  CREATIVITY_CONFIG(2),
  {
    order: 3,
    type: "input",
    name: "company_name",
    label: "Company Name",
    required: true,
    placeholder: "Netflix, Spotify, Uber...",
    colSpan: 1,
  },
  {
    order: 4,
    type: "dropdown",
    name: "company_type",
    label: "Company Type",
    required: true,
    values: COMPANY_TYPE_VALUES,
    colSpan: 1,
  },
  AUDIENCE_CONFIG(5),
  {
    order: 6,
    type: "dropdown",
    name: "main_color",
    label: "Main Color",
    required: true,
    values: COLOR_VALUES,
    colSpan: 1,
  },
  {
    order: 7,
    type: "textarea",
    name: "company_description",
    label: "Company Description",
    required: true,
    maxLength: 200,
    minLength: 40,
    colSpan: 2,
    rows: 3,
    placeholder: "Briefly describe what your company is about.",
  },
  {
    order: 8,
    type: "dynamicDropdownList",
    name: "page_structure",
    label: "Page Structure",
    itemType: "section", // used in label / error messages
    required: true,
    minItems: 3,
    maxItems: 8,
    colSpan: 2,
    values: PAGE_STRUCTURE_VALUES,
  },
];

export const FORM_FIELDS_ARTICLE_GENERATOR = [
  LANGUAGES_CONFIG(1, 2),
  BLOG_TITLE_CONFIG(2, 2),
  KEYWORD_CONFIG(3, 2),
  {
    order: 8,
    type: "dynamicInputList",
    name: "subheading",
    label: "Article subheadings",
    helperText: "(minimum 3)",
    itemType: "subheading", // used in label / error messages
    required: true,
    minItems: 3,
    colSpan: 2,
  },
];

/* Not used because it's the 5 step layout */
export const FORM_FIELDS_BLOG_WRITER = [
  LANGUAGES_CONFIG(1),
  CREATIVITY_CONFIG(2),
  {
    order: 3,
    type: "textarea",
    name: "about_text",
    label: "What do you want to write about?",
    required: true,
    minLength: 40,
    maxLength: 200,
    colSpan: 2,
    rows: 3,
    placeholder: "Explain what is your blog post about (min. 40 characters). e.g. A blog article explaining how copywriting can drive more traffic to your website.",
  },
  {
    order: 3,
    type: "input",
    name: "keyword",
    label: "Targeted Keyword (optional)",
    required: true,
    placeholder: "e.g. ai copywriting",
    colSpan: 2,
  },
  {
    order: 8,
    type: "dynamicInputList",
    name: "subheadings",
    label: "Article subheadings",
    helperText: "(minimum 3)",
    required: true,
    minItems: 3,
    colSpan: 2,
  },
];

export const FORM_FIELDS_ESSAY_INTROS = [
  {
    order: 1,
    type: "dropdown",
    name: "creativity",
    label: "Creativity",
    required: true,
    values: CREATIVITY_VALUES,
    colSpan: 2,
  },
  {
    order: 2,
    type: "textarea",
    name: "topic",
    label: "What is your essay about?",
    required: true,
    minLength: 40,
    maxLength: 200,
    colSpan: 2,
    rows: 5,
    placeholder: "Explain here to the AI what your essay is about. I.E. An essay about...",
  },
];

export const FORM_FIELDS_SENTENCE_REWRITER = [
  {
    order: 1,
    type: "dropdown",
    name: "languages",
    label: "Languages",
    required: true,
    values: LANGUAGES,
    colSpan: 1,
  },
  {
    order: 2,
    type: "dropdown",
    name: "creativity",
    label: "Creativity",
    required: true,
    values: CREATIVITY_VALUES,
    colSpan: 2,
  },
  {
    order: 3,
    type: "textarea",
    name: "sentence",
    label: "What would you like to rewrite?",
    required: true,
    maxLength: 200,
    colSpan: 2,
    rows: 5,
    placeholder: "Start typing or paste content here...",
  },
];

export const FORM_FIELDS_META_DESCRIPTIONS = [
  LANGUAGES_CONFIG(1),
  {
    order: 2,
    type: "dropdown",
    name: "creativity",
    label: "Creativity",
    required: true,
    values: CREATIVITY_VALUES,
    colSpan: 1,
  },
  {
    order: 3,
    type: "dropdown",
    name: "page_type",
    label: "Type of Page",
    required: true,
    values: [
      { id: 1, name: "landing page" },
      { id: 2, name: "product page" },
      { id: 3, name: "category page" },
      { id: 4, name: "blog article" },
    ],
    colSpan: 2,
  },
  {
    order: 4,
    type: "input",
    name: "website_name",
    label: "Website Name",
    required: true,
    placeholder: "Google, Uber",
    colSpan: 2,
  },
  {
    order: 5,
    type: "textarea",
    name: "business_description",
    label: "Website Description",
    maxLength: 200,
    required: true,
    placeholder: "Briefly describe what your website or business is about.",
    colspan: 2,
    rows: 3,
  },
  {
    order: 6,
    type: "input",
    name: "keyword",
    label: "Targeted Keyword",
    required: true,
    placeholder: "car insurance, nyc business lawyer,...",
    colSpan: 2,
  },
];

export const FORM_FIELDS_PRODUCT_DESCRIPTIONS = [LANGUAGES_CONFIG(1), CREATIVITY_CONFIG(2), PROUDCT_NAME_CONFIG(3), AUDIENCE_CONFIG(4), PROUDCT_DESCRIPTION_CONFIG(5, 2)];

export const FORM_FIELDS_EMAIL_SUBJECT_LINES = [LANGUAGES_CONFIG(1), CREATIVITY_CONFIG(2), TONE_OF_VOICE_CONFIG(3), AUDIENCE_CONFIG(4), PROUDCT_NAME_CONFIG(5, 2), PROUDCT_DESCRIPTION_CONFIG(6, 2)];

export const FORM_FIELDS_CONFIRMATION_EMAIL = [
  LANGUAGES_CONFIG(1),
  CREATIVITY_CONFIG(2),
  TONE_OF_VOICE_CONFIG(3),
  AUDIENCE_CONFIG(4),
  {
    order: 5,
    type: "input",
    name: "confirmation_of",
    label: "Confirmation of",
    required: true,
    placeholder: "Sale, Sign-up,...",
    colSpan: 1,
  },
  PROUDCT_NAME_CONFIG(6),
  PROUDCT_DESCRIPTION_CONFIG(7, 2),
];

export const FORM_FIELDS_CANCELLATION_EMAIL = [LANGUAGES_CONFIG(1), CREATIVITY_CONFIG(2), TONE_OF_VOICE_CONFIG(3), AUDIENCE_CONFIG(4), PROUDCT_NAME_CONFIG(6, 2), PROUDCT_DESCRIPTION_CONFIG(7, 2)];

export const FORM_FIELDS_WELCOME_EMAIL = [LANGUAGES_CONFIG(1), CREATIVITY_CONFIG(2), TONE_OF_VOICE_CONFIG(3), AUDIENCE_CONFIG(4), PROUDCT_NAME_CONFIG(6, 2), PROUDCT_DESCRIPTION_CONFIG(7, 2)];

export const FORM_FIELDS_FOLLOW_UP_EMAIL = [
  LANGUAGES_CONFIG(1),
  CREATIVITY_CONFIG(2),
  TONE_OF_VOICE_CONFIG(3),
  AUDIENCE_CONFIG(4),
  {
    order: 5,
    type: "input",
    name: "following_up_after",
    label: "Following up after",
    required: true,
    placeholder: "Free trial, seminar, call,...",
    colSpan: 1,
  },
  PROUDCT_NAME_CONFIG(6),
  PROUDCT_DESCRIPTION_CONFIG(7, 2),
];

export const FORM_FIELDS_COLD_EMAIL = [
  LANGUAGES_CONFIG(1),
  CREATIVITY_CONFIG(2),
  TONE_OF_VOICE_CONFIG(3),
  {
    order: 4,
    type: "dropdown",
    name: "email_goal",
    label: "Goal of the email",
    values: [
      { id: 1, name: "start free trial" },
      { id: 2, name: "sales" },
      { id: 3, name: "demo" },
      { id: 4, name: "schedule a call" },
      { id: 5, name: "sign up" },
      { id: 6, name: "collaboration" },
      { id: 7, name: "sponsorship" },
      { id: 8, name: "backlink" },
      { id: 9, name: "job offer" },
    ],
    colSpan: 1,
  },
  {
    order: 5,
    type: "input",
    name: "sender_name",
    label: "Sender's Name",
    required: true,
    placeholder: "Elon Musk",
    colSpan: 1,
  },
  {
    order: 6,
    type: "input",
    name: "recipient_name",
    label: "Recipient's Name",
    required: true,
    placeholder: "Jeff Bezos",
    colSpan: 1,
  },
  {
    order: 7,
    type: "textarea",
    name: "sender_information",
    label: "Sender's Information",
    required: true,
    placeholder: "e.g. Owner of Tesla, an electric car company",
    colSpan: 2,
    rows: 3,
  },
  {
    order: 8,
    type: "textarea",
    name: "recipient_information",
    label: "Recipient's Information",
    required: true,
    placeholder: "e.g. Owner of Amazon, an e-commerce retailer",
    colSpan: 2,
    rows: 3,
  },
];

export const FORM_FIELDS_NEWSLETTER = [
  LANGUAGES_CONFIG(1),
  CREATIVITY_CONFIG(2),
  {
    order: 3,
    type: "input",
    name: "topic_email",
    label: "Subject",
    required: true,
    placeholder: "Discover our new tools",
    colSpan: 2,
  },
  TONE_OF_VOICE_CONFIG(4),
  {
    order: 5,
    type: "input",
    name: "company_name",
    label: "Company Name",
    required: true,
    placeholder: "Amazon",
    colSpan: 1,
  },

  // Business Description
  {
    order: 6,
    type: "textarea",
    name: "business_description",
    label: "Business Description",
    maxLength: 200,
    required: true,
    placeholder: "Briefly describe what your website or business is about.",
    colspan: 2,
    rows: 3,
  },
];

export const FORM_FIELDS_VIDEO_SCRIPT_SECTION = [
  LANGUAGES_CONFIG(1),
  CREATIVITY_CONFIG(2),
  TONE_OF_VOICE_CONFIG(3),
  AUDIENCE_CONFIG(4),
  {
    order: 5,
    type: "input",
    name: "video_section_title",
    label: "Section title",
    required: true,
    colSpan: 2,
    placeholder: "e.g. Benefits of using an AI Copywriter",
  },
  {
    order: 6,
    type: "textarea",
    name: "video_description",
    label: "What is your video about?",
    required: true,
    maxLength: 200,
    placeholder: "Explain here what your video is about, include as many details as possible. I.e. A video tutorial about boosting your traffic with an AI Writer.",
    colSpan: 2,
    rows: 5,
  },
];

export const FORM_FIELDS_VIDEO_SCRIPT_INTROS = [
  LANGUAGES_CONFIG(1),
  CREATIVITY_CONFIG(2),
  TONE_OF_VOICE_CONFIG(3),
  AUDIENCE_CONFIG(4),
  {
    order: 5,
    type: "textarea",
    name: "video_description",
    label: "What is your video about?",
    required: true,
    maxLength: 200,
    placeholder: "Explain here what your video is about, include as many details as possible. I.e. A video tutorial about boosting your traffic with an AI Writer.",
    colSpan: 2,
    rows: 5,
  },
];

export const FORM_FIELDS_PRESS_RELEASES = [
  LANGUAGES_CONFIG(1),
  CREATIVITY_CONFIG(2),
  TONE_OF_VOICE_CONFIG(3),
  {
    order: 3,
    type: "input",
    name: "company_name",
    label: "Company Name",
    required: true,
    placeholder: "Netflix, Spotify, Uber...",
    colSpan: 1,
  },
  {
    order: 5,
    type: "textarea",
    name: "business_description",
    label: "Company Description",
    required: true,
    maxLength: 200,
    minLength: 40,
    colSpan: 2,
    rows: 3,
    placeholder: "Briefly describe what your company is about.",
  },
  {
    order: 6,
    type: "textarea",
    name: "announcement",
    label: "What is this press release about?",
    required: true,
    maxLength: 200,
    minLength: 40,
    colSpan: 2,
    rows: 3,
    placeholder: "e.g. Release of our iOS app",
  },
];

export const FORM_FIELDS_CTA = [LANGUAGES_CONFIG(1), CREATIVITY_CONFIG(2), PROUDCT_NAME_CONFIG(3, 1), AUDIENCE_CONFIG(4), PROUDCT_DESCRIPTION_CONFIG(5, 2)];

export const FORM_FIELDS_ABOUT_US = [LANGUAGES_CONFIG(1, 2), CREATIVITY_CONFIG(2), TONE_OF_VOICE_CONFIG(3), PROUDCT_NAME_CONFIG(4), AUDIENCE_CONFIG(5), PROUDCT_DESCRIPTION_CONFIG(6, 2)];

export const FORM_FIELDS_HOW_IT_WORKS = [LANGUAGES_CONFIG(1), CREATIVITY_CONFIG(2), PROUDCT_NAME_CONFIG(4), AUDIENCE_CONFIG(5), PROUDCT_DESCRIPTION_CONFIG(6, 2)];

export const FORM_FIELDS_TESTIMONIALS = [LANGUAGES_CONFIG(1, 2), CREATIVITY_CONFIG(2), PROUDCT_NAME_CONFIG(4), PROUDCT_DESCRIPTION_CONFIG(6, 2)];

export const FORM_FIELDS_QUEST = [LANGUAGES_CONFIG(1), CREATIVITY_CONFIG(2), PROUDCT_NAME_CONFIG(4), AUDIENCE_CONFIG(5), PROUDCT_DESCRIPTION_CONFIG(6, 2)];

export const FORM_FIELDS_AIDA = [LANGUAGES_CONFIG(1), CREATIVITY_CONFIG(2), PROUDCT_NAME_CONFIG(4), AUDIENCE_CONFIG(5), PROUDCT_DESCRIPTION_CONFIG(6, 2)];

export const FORM_FIELDS_PAS = [LANGUAGES_CONFIG(1), CREATIVITY_CONFIG(2), PROUDCT_NAME_CONFIG(4), AUDIENCE_CONFIG(5), PROUDCT_DESCRIPTION_CONFIG(6, 2)];

export const FORM_FIELDS_WFM = [];

// An article comparing the quality of life and cost of living of the major cities in the U.S.
export const FORM_FIELDS_BLOG_INTROS = [
  LANGUAGES_CONFIG(1),
  CREATIVITY_CONFIG(2),
  {
    order: 3,
    type: "textarea",
    name: "blog_title",
    label: "Title of your blog article",
    required: true,
    placeholder: "e.g. 5 ways to boost your sales with copywriting",
    colSpan: 2,
    rows: 2,
    defaultValue: "",
  }
];

export const FORM_FIELDS_JOB_DESCRIPTIONS = [
  LANGUAGES_CONFIG(1),
  CREATIVITY_CONFIG(2),
  {
    order: 3,
    type: "input",
    name: "position",
    label: "Position",
    placeholder: "Web developer",
    colSpan: 2,
  },
  COMPANY_NAME_CONFIG(4, 2),
  COMPANY_DESCRIPTION_CONFIG(5, 2),
];

export const FORM_FIELDS_PROJECT_TEXT_PROPOSAL = [
  LANGUAGES_CONFIG(1),
  CREATIVITY_CONFIG(2),
  {
    order: 3,
    type: "input",
    name: "project_title",
    label: "Project Title",
    placeholder: "Need help with HTML",
    colSpan: 2,
  },
  {
    order: 4,
    type: "textarea",
    name: "project_description",
    label: "Project Description",
    placeholder: "Copy paste the project description",
    colSpan: 2,
    minLength: 40,
    maxLength: 600,
    rows: 4,
    required: true,
  },
  {
    order: 5,
    type: "input",
    name: "required_skills",
    label: "Required Skills",
    placeholder: "Required Skills (comma separated)",
    required: true,
  },
  // {
  //   order:
  // }
];

export const FORM_FIELDS_ESSAY_OUTLINES = [
  CREATIVITY_CONFIG(1, 2),
  {
    order: 2,
    type: "textarea",
    name: "topic",
    label: "What is your essay about?",
    maxLength: 200,
    required: true,
    placeholder: "Explain here to the AI what your essay is about. I.e. An essay about...",
    colspan: 2,
    rows: 8,
  },
];

export const FORM_FIELDS_STORIES = [
  LANGUAGES_CONFIG(1),
  CREATIVITY_CONFIG(2),
  TONE_OF_VOICE_CONFIG(3, 2), // newly added
  {
    order: 4,
    type: "textarea",
    name: "description",
    label: "What is the story about?",
    required: true,
    placeholder: "In The Hunger Games, Katniss must represent her district in the games after she takes the place of her younger sister.",
    colSpan: 2,
    rows: 5,
  },
  {
    order: 5,
    type: "dropdown",
    name: "point_of_view",
    label: "Point of View:",
    values: [
      { id: 1, name: "First Person" },
      { id: 2, name: "Third Person" },
    ],
  },
];

export const FORM_FIELDS_GRAMMAR_REWRITER = [
  {
    order: 1,
    type: "textarea",
    name: "sentence",
    maxLength: 600,
    required: true,
    label: "What would you like to rewrite?",
    rows: 5,
    colSpan: 2,
  },
];

export const FORM_FIELDS_REVIEW_RESPONDER = [
  LANGUAGES_CONFIG(1),
  CREATIVITY_CONFIG(2),
  COMPANY_NAME_CONFIG(3),
  {
    order: 4,
    type: "input",
    name: "reviewer_name",
    label: "Reviewer Name",
    placeholder: "Karen",
    colSpan: 1,
    required: true,
  },
  {
    order: 5,
    label: "Rating (1 to 5)",
    name: "rating",
    type: "input",
    placeholder: "1",
    required: true,
    colSpan: 1,
  },
  TONE_OF_VOICE_CONFIG(6),
  {
    order: 6,
    name: "customer_review",
    label: "Customer Review",
    type: "textarea",
    placeholder: "Copy paste the full customer review",
    required: true,
    colSpan: 2,
    rows: 8,
  },
];

export const FORM_FIELDS_VISION_STATEMENT = [LANGUAGES_CONFIG(1), CREATIVITY_CONFIG(2), COMPANY_NAME_CONFIG(3, 2), COMPANY_DESCRIPTION_CONFIG(4, 2)];

export const FORM_FIELDS_MISSION_STATEMENT = [LANGUAGES_CONFIG(1), CREATIVITY_CONFIG(2), COMPANY_NAME_CONFIG(3, 2), COMPANY_DESCRIPTION_CONFIG(4, 2)];

export const FORM_FIELDS_STARTUP_NAME_GENERATOR = [
  CREATIVITY_CONFIG(1),
  {
    order: 2,
    name: "seedwords",
    type: "input",
    label: "Seed words",
    placeholder: "e.g. fit, flow, app",
    required: true,
    colSpan: 1,
  },
  PROUDCT_DESCRIPTION_CONFIG(3),
];

export const FORM_FIELDS_TLDR = [
  CREATIVITY_CONFIG(1, 2),
  {
    order: 2,
    name: "text_to_summarize",
    label: "What would you like to summarize?",
    type: "textarea",
    placeholder: "Start typing or paste content here...",
    colSpan: 2,
    rows: 8,
  },
];

export const FORM_FIELDS_SENTENCE_EXPANDER = [
  LANGUAGES_CONFIG(1),
  CREATIVITY_CONFIG(2),
  {
    order: 3,
    name: "sentence",
    label: "Content to expand",
    type: "textarea",
    placeholder: "Start typing or paste content here...",
    required: true,
    colSpan: 2,
    rows: 8,
  },
];

export const FORM_FIELDS_REWRITE_WITH_KEYWORD = [
  LANGUAGES_CONFIG(1),
  CREATIVITY_CONFIG(2),
  {
    order: 3,
    name: "keyword",
    type: "input",
    label: "Keyword",
    placeholder: "e.g. AI copywriting",
    required: true,
  },
  {
    order: 4,
    name: "text_to_rewrite",
    type: "textarea",
    label: "What would you like to rewrite?",
    placeholder: "Start typing or paste content here...",
    colSpan: 2,
    rows: 8,
  },
];

export const FORM_FIELDS_ECOM_PRODUCT_NAMES = [CREATIVITY_CONFIG(1, 2), TONE_OF_VOICE_CONFIG(2), AUDIENCE_CONFIG(3), PROUDCT_DESCRIPTION_CONFIG(4)];

export const FORM_FIELDS_ECOM_CATEGORY_DESCRIPTIONS = [
  LANGUAGES_CONFIG(1),
  CREATIVITY_CONFIG(2),
  {
    order: 3,
    name: "business_name",
    label: "Business Name",
    type: "input",
    placeholder: "Amazon",
    colSpan: 1,
    required: true,
  },
  AUDIENCE_CONFIG(4),
  {
    order: 5,
    name: "business_description",
    label: "Business Description",
    type: "textarea",
    placeholder: "Briefly describe what your business is about.",
    colSpan: 2,
    rows: 5,
    required: true,
  },
  {
    order: 6,
    name: "category_name",
    label: "Category Name",
    type: "input",
    placeholder: "Shoes, Clothing",
    colSpan: 1,
    required: true,
  },
  {
    order: 7,
    name: "keyword",
    label: "Keyword",
    type: "input",
    placeholder: "e.g. men's shoes",
    colSpan: 1,
    required: true,
  },
];

export const FORM_FIELDS_ECOM_PRODUCT_DESCRIPTIONS = [LANGUAGES_CONFIG(1), CREATIVITY_CONFIG(2), TONE_OF_VOICE_CONFIG(3), AUDIENCE_CONFIG(4), PROUDCT_NAME_CONFIG(5, 2), PROUDCT_DESCRIPTION_CONFIG(6)];

export const FORM_FIELDS_PROS_CONS = [LANGUAGES_CONFIG(1), CREATIVITY_CONFIG(2), PROUDCT_NAME_CONFIG(3, 2), PROUDCT_DESCRIPTION_CONFIG(4, 2)];

export const FORM_FIELDS_TALKING_POINTS = [
  LANGUAGES_CONFIG(1),
  CREATIVITY_CONFIG(2),
  {
    order: 3,
    type: "textarea",
    name: "article_title",
    label: "Article title",
    placeholder: "e.g. 5 ways to boost your sales with copywriting",
    required: true,
    colSpan: 2,
    rows: 3,
  },
  {
    order: 4,
    type: "textarea",
    name: "subhead",
    label: "Subheading",
    placeholder: "e.g. Why you should use an AI writer",
    required: true,
    colSpan: 2,
    rows: 3,
  },
];

export const FORM_FIELDS_BLOG_CONCLUSIONS = [
  LANGUAGES_CONFIG(1),
  CREATIVITY_CONFIG(2),
  {
    order: 3,
    type: "textarea",
    name: "article_title",
    label: "Article title or topic",
    placeholder: "e.g. 5 ways to boost your sales with copywriting",
    required: true,
    colSpan: 2,
    rows: 5,
  },
];

export const FORM_FIELS_BLOG_TITLES_LISTICLES = [LANGUAGES_CONFIG(1), CREATIVITY_CONFIG(2), TOPIC_CONFIG(3, 2)];

export const FORM_FIELDS_BLOG_TITLES = [LANGUAGES_CONFIG(1), CREATIVITY_CONFIG(2), TOPIC_CONFIG(3, 2)];

export const FORM_FIELDS_BLOG_SECTIONS = [
  LANGUAGES_CONFIG(1),
  CREATIVITY_CONFIG(2),
  BLOG_TITLE_CONFIG(3, 2),
  {
    order: 4,
    type: "textarea",
    name: "subheading",
    label: "Subheading / Bullet Point",
    placeholder: "e.g. boost conversions by writing the best content",
    required: true,
    colSpan: 2,
    rows: 3,
  },
];

export const FORM_FIELDS_PARAGRAPH_GENERATOR = [LANGUAGES_CONFIG(1), CREATIVITY_CONFIG(2), TOPIC_CONFIG(3, 2), KEYWORD_CONFIG(4, 2), TONE_OF_VOICE_CONFIG(5, 2)];

export const FORM_FIELDS_SUBHEADING_PARAGRAPH = [
  LANGUAGES_CONFIG(1),
  CREATIVITY_CONFIG(2),
  BLOG_TITLE_CONFIG(3, 2),
  {
    order: 4,
    type: "textarea",
    name: "subheading",
    label: "Subheading / Bullet Point",
    placeholder: "e.g. boost conversions by writing the best content",
    required: true,
    colSpan: 2,
    rows: 3,
  },
];

export const FORM_FIELDS_FAQ_ANSWERS = [
  LANGUAGES_CONFIG(1),
  CREATIVITY_CONFIG(2),
  PROUDCT_NAME_CONFIG(3),
  AUDIENCE_CONFIG(4),
  PROUDCT_DESCRIPTION_CONFIG(5, 2),
  {
    order: 6,
    type: "input",
    name: "question",
    label: "What is the question you are generating answers for?",
    required: true,
    placeholder: "Your question here...",
  },
];

export const FORM_FIELDS_FAQ = [LANGUAGES_CONFIG(1), CREATIVITY_CONFIG(2), PROUDCT_NAME_CONFIG(3), AUDIENCE_CONFIG(4), PROUDCT_DESCRIPTION_CONFIG(4, 2)];

export const FORM_FIELDS_META_DESCRIPTIONS_URL = [
  CREATIVITY_CONFIG(1, 2),
  {
    order: 2,
    type: "input",
    name: "website_name",
    label: "Website Name",
    colSpan: 2,
    required: true,
    placeholder: "Google, Uber",
  },
  {
    order: 3,
    type: "input",
    name: "website_url",
    label: "Website Url",
    colSpan: 2,
    required: true,
    placeholder: "https://...",
  },
  {
    order: 4,
    type: "input",
    name: "keyword",
    label: "Targeted Keyword",
    required: true,
    placeholder: "e.g. ai copywriting",
    colSpan: 2,
  },
];

export const FORM_FIELDS_META_TITLES = [
  CREATIVITY_CONFIG(1, 2),
  {
    order: 2,
    type: "input",
    name: "website_name",
    label: "Website Name",
    colSpan: 2,
    required: true,
    placeholder: "Google, Uber",
  },
  {
    order: 3,
    type: "input",
    name: "website_url",
    label: "Website Url",
    colSpan: 2,
    required: true,
    placeholder: "https://...",
  },
  {
    order: 4,
    type: "input",
    name: "keyword",
    label: "Targeted Keyword",
    required: true,
    placeholder: "e.g. ai copywriting",
    colSpan: 2,
  },
];

export const FORM_FIELDS_FEATURES_TO_BENEFIT = [LANGUAGES_CONFIG(1, 2), CREATIVITY_CONFIG(2), TONE_OF_VOICE_CONFIG(3), AUDIENCE_CONFIG(4), PROUDCT_NAME_CONFIG(5), PROUDCT_DESCRIPTION_CONFIG(6, 2)];

export const FORM_FIELDS_VALUE_PROPOSITION = [LANGUAGES_CONFIG(1, 2), CREATIVITY_CONFIG(2), TONE_OF_VOICE_CONFIG(3), AUDIENCE_CONFIG(4), PROUDCT_NAME_CONFIG(5), PROUDCT_DESCRIPTION_CONFIG(6, 2)];

export const FORM_FIELDS_WEBSITE_SUBHEADLINES = [LANGUAGES_CONFIG(1, 2), CREATIVITY_CONFIG(2), TONE_OF_VOICE_CONFIG(3), AUDIENCE_CONFIG(4), PROUDCT_NAME_CONFIG(5), PROUDCT_DESCRIPTION_CONFIG(6, 2)];

export const FORM_FIELDS_QUORA_ANSWERS = [
  LANGUAGES_CONFIG(1),
  CREATIVITY_CONFIG(2),
  {
    order: 3,
    type: "textarea",
    name: "quora_question",
    label: "Quora Question",
    required: true,
    placeholder: "e.g. What is something that rich people know that the rest of us would benefit from?",
    rows: 8,
    colSpan: 2,
  },
];

export const FORM_FIELDS_LINKEDIN_POST = [
  LANGUAGES_CONFIG(1, 2),
  AUDIENCE_CONFIG(2, 2),
  {
    order: 3,
    type: "input",
    name: "topic_social",
    label: "What is this post about?",
    placeholder: "e.g. Release of our app",
  },
];

export const FORM_FIELDS_TWEETS = [
  {
    order: 1,
    type: "textarea",
    name: "topic",
    label: "What is this tweet about?",
    placeholder: "Tesla cars",
    colSpan: 2,
    rows: 5,
  },
];

export const FORM_FIELDS_TWITTER_THREADS = [
  {
    order: 1,
    type: "textarea",
    name: "topic",
    label: "What is this thread about?",
    placeholder: "Tesla cars",
    colSpan: 2,
    rows: 5,
  },
];

export const FORM_FIELDS_YOUTUBE_TAG_GENERATOR = [
  {
    order: 1,
    type: "input",
    name: "video_title_keyword",
    label: "Enter your video title or a keyword",
    placeholder: "e.g. yoga",
    required: true,
  },
];

export const FORM_FIELDS_YOUTUBE_DESCRIPTIONS = [
  LANGUAGES_CONFIG(1),
  CREATIVITY_CONFIG(2),
  {
    order: 3,
    type: "textarea",
    name: "video_title",
    label: "What is the title of your video?",
    required: true,
    maxLength: 100,
    placeholder: "e.g. learn how to boost your traffic with an AI-Powered content writing tool",
    rows: 5,
  },
];

export const FORM_FIELDS_YOUTUBE_TITLES = [
  LANGUAGES_CONFIG(1),
  CREATIVITY_CONFIG(2),
  {
    order: 3,
    name: "video_description",
    type: "textarea",
    label: "What is your video about?",
    required: true,
    placeholder: "Explain here what your video is about, include as many details as possible. I.e. A video tutorial about boosting your traffic with an AI Writer.",
    colSpan: 2,
    rows: 5,
  },
];

export const FORM_FIELDS_GOOGLE_DESCRIPTIONS = [LANGUAGES_CONFIG(1, 2), CREATIVITY_CONFIG(2), TONE_OF_VOICE_CONFIG(3), AUDIENCE_CONFIG(4), PROUDCT_NAME_CONFIG(5), PROUDCT_DESCRIPTION_CONFIG(6, 2)];

export const FORM_FIELDS_GOOGLE_HEADLINES = [LANGUAGES_CONFIG(1, 2), CREATIVITY_CONFIG(2), TONE_OF_VOICE_CONFIG(3), AUDIENCE_CONFIG(4), PROUDCT_NAME_CONFIG(5), PROUDCT_DESCRIPTION_CONFIG(6, 2)];

export const FORM_FIELDS_FACEBOOK_HEADLINES = [LANGUAGES_CONFIG(1, 2), CREATIVITY_CONFIG(2), TONE_OF_VOICE_CONFIG(3), AUDIENCE_CONFIG(4), PROUDCT_NAME_CONFIG(5), PROUDCT_DESCRIPTION_CONFIG(6, 2)];

export const FORM_FIELDS_FACEBOOK_PRIMARY = [LANGUAGES_CONFIG(1, 2), CREATIVITY_CONFIG(2), TONE_OF_VOICE_CONFIG(3), AUDIENCE_CONFIG(4), PROUDCT_NAME_CONFIG(5), PROUDCT_DESCRIPTION_CONFIG(6, 2)];

export const FORM_FIELDS_INSTAGRAM_HASHTAG_GENERATOR = [
  {
    order: 1,
    type: "input",
    name: "instagram_keyword",
    label: "Enter a keyword",
    required: true,
    placeholder: "e.g. yoga",
  },
];

export const FORM_FIELDS_INSTAGRAM_CAPTIONS = [LANGUAGES_CONFIG(1), TONE_OF_VOICE_CONFIG(2), INSTAGRAM_TOPIC_CONFIG(3, 2)];

export const FORM_FIELDS_SOCIAL_MEDIA_POST_PERSONAL = [
  {
    order: 3,
    type: "input",
    name: "topic",
    label: "What is this post about?",
    placeholder: "e.g. Heading to Los Angeles on weekend",
  },
];

export const FORM_FIELDS_SOCIAL_MEDIA_POST_BUSINESS = [
  LANGUAGES_CONFIG(1),
  CREATIVITY_CONFIG(2),
  TONE_OF_VOICE_CONFIG(3),
  COMPANY_NAME_CONFIG(4),
  COMPANY_DESCRIPTION_CONFIG(5, 2),
  {
    order: 6,
    type: "input",
    name: "topic_social",
    label: "What is this post about?",
    placeholder: "e.g. Release of our app.",
    colSpan: 2,
    required: true,
  },
];
// {
//   order: 3,
//   type: "dropdown",
//   name: "page_type22",
//   label: "Type of Page",
//   required: true,
//   values: [{id: 1, name: "Landing Page"}, {id: 2, name: "Product Page"}, {id: 3, name: "Category Page"}, , {id: 3, name: "Blog Article"}],
//   colSpan: 2,
// },
// {
//   order: 4,
//   type: "input",
//   name: "website_name",
//   label: "Website Name",
//   required: true,
//   placehoder: "Google, Uber",
//   colSpan: 2,
// },
// {
//   order: 5,
//   type: "textarea",
//   name: "business_description",
//   label: "Website Description",
//   maxLength: 200,
//   required: true,
//   placeholder: "Briefly describe what your website or business is about.",
//   colspan: 2,
//   rows: 3,
// },
// {
//   order: 5,
//   type: "input",
//   name: "keyword",
//   label: "Targeted Keyword",
//   required: true,
//   placeholder: "car insurance, nyc business lawyer,...",
//   colSpan: 2,
// }
