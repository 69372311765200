import { Fragment, useState, useRef, useEffect, useContext } from "react";
import Tippy from "@tippyjs/react";
import { ArrowPathIcon, ChevronDownIcon, ChevronUpIcon, MagnifyingGlassIcon, TrashIcon } from "@heroicons/react/20/solid";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import Button from "../../Components/ui/Button";
import LoadingModal from "../../Components/ui/LoadingModal";
import Modal from "../../Components/ui/Modal";
import { info, success, warning, error } from "../../notify/Notify";
import axios from "axios";
import { config } from "../../config";
import { AppDataContext } from "../../data/AppHelpers";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const outputContent = "";

export default function ArticleRewriterSection(props) {

  const [currentInputValue, setCurrentInputValue] = useState(props.content);
  const [currentOutputValue, setCurrentOutputValue] = useState(props.generated);

  const [isRewriting, setIsRewriting] = useState(false);
  const [modal, setModal] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // Check Plagiarism

  const textareaRefInput = useRef(null);
  const textareaRefOutput = useRef(null);

  const isIntro = props.isIntro;
  const { appDataContext } = useContext(AppDataContext);
  const editorRef = useRef(null);


  // Resize the text areas when it's contents are changed
  useEffect(() => {
    textareaRefOutput.current.style.height = "0px";
    const scrollHeightOutput = textareaRefOutput.current.scrollHeight;
    textareaRefOutput.current.style.height = scrollHeightOutput + "px";
  }, [currentOutputValue]);

  useEffect(() => {
    textareaRefInput.current.style.height = "0px";
    const scrollHeightInput = textareaRefInput.current.scrollHeight;
    textareaRefInput.current.style.height = scrollHeightInput + "px";
  }, [currentInputValue]);

  // Resize the text areas when the window is resized to prevent textarea scrollbars
  function handleResize() {
    textareaRefInput.current.style.height = "0px";
    const scrollHeightInput = textareaRefInput.current.scrollHeight;
    textareaRefInput.current.style.height = scrollHeightInput + "px";

    textareaRefOutput.current.style.height = "0px";
    const scrollHeightOutput = textareaRefOutput.current.scrollHeight;
    textareaRefOutput.current.style.height = scrollHeightOutput + "px";
  }

  function countWords(value) {
    const regex = /\s+/gi;
    let wordCount = currentOutputValue.trim().replace(regex, " ").split(" ").length;
    return wordCount;
  }

  // TODO: API call
  // [INPUT]: Current textareaRefInput
  // [OUTPUT]: Put result in textareaRefOutput
  async function rewriteHandler() {

    const url = config(appDataContext.ENV).copymatic_api + "proxy_submit_request";

    setIsRewriting(true);
    const request = {
      model: 'rewrite-paragraph',
      jwt: appDataContext.jwt,
      key: appDataContext.user.meta.cf_key,
      user_id: appDataContext.user.id,
      text: currentInputValue,
      n: 1,
    };

    const response = await axios.post(url, request)
      .then((response) => {
        return JSON.parse(response.data);
      })
      .catch((error) => {
        return { error: error }
        this.setState({ error: error });
      });

    props.onRewrite(currentInputValue, response.results["1"]);
    setCurrentOutputValue(response.results["1"]);
    setIsRewriting(false);
  }

  useEffect(() => {
    if (currentInputValue.trim() && !currentOutputValue) {
      rewriteHandler()
          .then(() => console.log('Rewrite done'))
          .catch(error => console.error('Error during rewriting:', error));
    }
  }, []);


  useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);

  const modalHandler = () => {
    setModal(null);
  };

  return (
    <>
      {isLoading && <LoadingModal title="Checking Plagiarism" content="Please wait, this can take a few seconds." onClose={modalHandler} />}
      {modal && <Modal onClose={modalHandler} title={modal.title} content={modal.message} buttonLabel="OK" />}

      <section className="relative articleSection group mx-auto hover:py-4 duration-500 hover:border border border-transparent xl:border-0 xl:hover:border-0 duration-400">
        <div className="grid grid-cols-3 grid-cols-[minmax(0,_1fr)_120px_minmax(0,_1fr)] lg:grid-cols-[minmax(0,_1fr)_200px_minmax(0,_1fr)]">
          {/* Left col: Input textarea */}
          <div className="bg-white group-hover:shadow-sm border-transparent border hover:rounded-md overflow-hidden hover:border-gray-200 pb-3 pt-5 px-3 xl:px-6 group duration-500">
            <textarea
              ref={textareaRefInput}
              style={{ resize: "none" }}
              onChange={(e) => {
                setCurrentInputValue(e.target.value);
                //to do something with value, maybe callback?
              }}
              name={props.name}
              className="p-2 h-auto focus:ring-0 text-base w-full outline-0 border-0"
              value={currentInputValue}
            ></textarea>
          </div>

          {/* Menu holder */}
          <div className="grid group-hover:opacity-100 opacity-0 duration-300 place-items-center p-4">
            <div className="flex-col flex gap-4 w-full">
              {!isRewriting && (
                <Button onClick={rewriteHandler} buttonSize="sm" buttonStyle="primary" className="block w-full">
                  <ArrowPathIcon className="h-4 w-4 mr-2 hidden lg:inline" /> Rewrite
                </Button>
              )}
              {isRewriting && (
                <Button buttonSize="sm" disabled buttonStyle="primary" className="block bg-gray-500 hover:bg-gray-500 w-full">
                  <ArrowPathIcon className="h-4 w-4 my-0.5 animate-spin" />
                </Button>
              )}
              {/*<Button*/}
              {/*    onClick={checkPlagiarismHandler}*/}
              {/*      buttonSize="sm"*/}
              {/*    buttonStyle="outlined"*/}
              {/*    className="block w-full">*/}
              {/*  <MagnifyingGlassIcon className="h-4 w-4 mr-2 hidden lg:inline" /> Check Plagiarism*/}
              {/*</Button>*/}
            </div>
          </div>

          {/* Right col: Output textarea */}
          <div className={classNames(isRewriting ? "bg-gray-100" : "opacity-100", "bg-white group-hover:shadow-sm pb-3 pt-5 px-3 xl:px-6 group duration-500")}>
            {isRewriting && (
              <div className="grid place-items-center w-full h-48 my-auto">
                <div className="relative flex py-4 items-center">
                  <div className="w-fit mt-4 mx-auto scale-[1.7]">
                    <div className="dot-pulse"></div>
                  </div>
                </div>
              </div>
            )}
            <textarea
              ref={textareaRefOutput}
              style={{ resize: "none" }}
              onChange={(e) => {
                setCurrentOutputValue(e.target.value);
                //to do something with value, maybe callback?
              }}
              name={props.name}
              className={classNames(isRewriting ? "hidden" : "block", "p-2 h-auto bg-transparent focus:ring-0 text-base w-full outline-0 border-0")}
              value={currentOutputValue}
            ></textarea>
          </div>
        </div>
      </section>
    </>
  );
}
