export default function Loading() {

    return (
        <div className="grid h-screen place-items-center">
            <div className="grid place-items-center">
                <object className="object-fill object-cover h-48 w-96" type="image/svg+xml" data="/images/copymatic-loading.svg"></object>
                <p className="p-15 m-10">Loading...</p>
            </div>
        </div>
    );
}