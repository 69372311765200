import { MinusIcon, PlusIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional

export default function QuantityInput(props) {

  const maxQuantity = (props.maxQuantity && parseInt(props.maxQuantity) !== 0) ? parseInt(props.maxQuantity) : 8;

  const [count, setCount] = useState(props.value);

  function plusHanlder() {
    if (count < maxQuantity) {
      setCount((prev) => { 
        const newVal = prev + 1;
        props.onChange(newVal)
        return newVal;
      });
    }
  }
  function minusHanlder() {
    if (count > 1) {
      setCount((prev) => {
        const newVal = prev - 1;
        props.onChange(newVal)
        return newVal;
      });
    }

  }

  return (
    <>
      <Tippy theme={"copymatic"} content={`Number of results (max. ${maxQuantity})`} interactive={true} hideOnClick={false}>
        <div className="grid w-16 grid-cols-3 grid-rows-2 bg-gray-100 rounded-md border border-gray-300 shadow-sm focus-within:ring-primary-500 overflow-hidden focus-within:outline-none focus-within:ring-2 focus-within:ring-primary-500 focus-within:ring-offset-0">
          <input style={{ MozAppearance: "none" }} onChange={props.onChange} type="number" name="n" id="quantityInput" className="block col-span-2 border-0 focus:ring-0 ring-0 border-r focus:border-gray-200 border-gray-200 row-span-2 h-full text-center placeholder:text-gray-400 bg-transparent placeholder:font-medium sm:text-sm" placeholder={props.placeholder} value={count} aria-describedby="title-required" ref={props.ref} />
          <div onClick={plusHanlder} className="grid place-items-center text-center text-gray-400 duration hover:cursor-pointer hover:text-gray-600">
            <PlusIcon className="h-4 w-4 mt-1" />
          </div>
          <div onClick={minusHanlder} className="grid place-items-center text-center text-gray-400 duration hover:cursor-pointer hover:text-gray-600">
            <MinusIcon className="h-4 w-4" />
          </div>
        </div>
      </Tippy>
    </>
  );
}
