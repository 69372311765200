import Label from "../ui/Label";
import Dropdown from "../ui/Dropdown";
import DragDropList from "../ui/DragDropList";
import { BoltIcon } from "@heroicons/react/24/solid";
import MyTagsInput from "../ui/TagsInput";
import { LANGUAGES } from "./../../data/languages";

export default function ArticleGeneratorForm(props) {

  const subheadingsDefault = [
    { id: "0", value: "" },
    { id: "1", value: "" },
    { id: "2", value: "" },
  ];

  function submitHandler(event) {

    event.preventDefault();

    const language = "English (US)"; // these need to be formatted correctly
    const title = event.target.title.value;
    const keywords = event.target.keywords.value;
    const subheadings = Array.from(event.target.subheadings).map((input) => input.value);

    // take form data mapping directly from https://copymatic.ai/article-generator/
    //
    const formData = {
      model: "blog-intros-new",
      blog_title: title,
      language: language,
      keywords_tag: keywords,
      subheadings: subheadings
    };

    //
    // submit the data
    //
    props.onFormSubmit(formData);
  }

  return (
    <>
      <form onSubmit={submitHandler}>
        <div className="px-4 pt-5 flow-root">
          <div className="mb-6">
            <div className="mb-1 block">
              <Label name="language">Language</Label>
            </div>
            <Dropdown onChange={props.onChange} values={LANGUAGES}></Dropdown>
          </div>
          <div className="mb-6">
            <div className="mb-1 block">
              <Label name="title">Article title*</Label>
            </div>
            <input onChange={props.onChange} type="text" name="title" id="title" className="block w-full bg-gray-100  placeholder:text-gray-400 placeholder:font-medium rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm dark:bg-slate-800 dark:text-white dark:border-slate-700 dark:placeholder:text-slate-500" aria-describedby="title-required" placeholder="e.g. The 25 best places to live in the US in 2022" />
          </div>
          <div className="mb-6">
            <div className="mb-1 block">
              <Label name="focusKeywords">
                Focus keywords <span className="text-gray-400">(separated with a comma)</span>
              </Label>
            </div>
            <MyTagsInput onChange={props.onChange} name="keywords" placeholder="Add keyword" />
          </div>
          <div className="mb-6">
            <div className="mb-1 flex items-center justify-between">
              <Label name="subheaders" onChange={(input) => console.log(input)}>
                Article subheadings* <span className="text-gray-400">(minimum 3)</span>
              </Label>

              <button className="text-xs px-2 py-1.5 hover:bg-gray-50 group duration-200 rounded-md border border-gray-200 text-gray-500 hover:text-primary-500 font-bold flex items-center">
                <BoltIcon className="h-4 w-4 group-hover:text-primary-400 duration-200 text-gray-400 mr-1" />
                Write subheadings for me
              </button>
            </div>
            <DragDropList onChange={props.onChange} items={subheadingsDefault} prefix="subheadings" />
          </div>
        </div>

        <div className="justify-stretch px-4 pb-5 flex flex-col">
          <button disabled={props.isLoading} type="submit" className="inline-flex disabled:opacity-50 items-center justify-center rounded-md border border-transparent bg-primary-500 px-4 py-2 text-normal font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 ">
            {props.isLoading && (
              <span>
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 inline text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Generating...
              </span>
            )}
            {!props.isLoading && <span>Generate</span>}
          </button>
        </div>
      </form>
    </>
  );
}
