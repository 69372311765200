
const BASE_CONFIG = {
    cloudflare: "https://copymatic.ai/temp-api",
};

const CONFIG = {
    development: {
        copymatic_api: "https://copymatic.ai/wp-json/copymatic/",
        log: true,
        ...BASE_CONFIG,
    },
    production: {
        copymatic_api: "https://copymatic.ai/wp-json/copymatic/", // until we're in prod the new routes we need to use dev
        log: false,
        ...BASE_CONFIG,
    }
};

export const config = (path) => {
    return CONFIG[path];
}
