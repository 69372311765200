import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import React, { useRef, useState } from "react";

export function ScrollableDiv(props) {
  const divRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (event) => {
    setIsDragging(true);
    setStartX(event.pageX - divRef.current.offsetLeft);
    setScrollLeft(divRef.current.scrollLeft);
  };

  const handleMouseMove = (event) => {
    if (!isDragging) return;
    event.preventDefault();
    const x = event.pageX - divRef.current.offsetLeft;
    const walk = (x - startX) * 2; // scroll-fast
    divRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleScrollLeft = () => {
    divRef.current.scrollBy({ left: -50, behavior: "smooth" });
  };

  const handleScrollRight = () => {
    divRef.current.scrollBy({ left: 50, behavior: "smooth" });
  };

  const isAtStart = divRef.current ? divRef.current.scrollLeft === 0 : false;
  const isAtEnd = divRef.current ? divRef.current.scrollLeft === divRef.current.scrollWidth - divRef.current.clientWidth : false;

  return (
    <div className="flex w-full">
      <button onClick={handleScrollLeft} disabled={isAtStart} className="disabled:opacity-10 text-gray-500 hover:text-gray-700 rounded-md bg-gray-50 mr-1 mb-4">
        <ChevronLeftIcon className="w-5 h-5" />
      </button>
      <div ref={divRef} className="ui-scroll w-full horizontal overflow-x-scroll mb-4" onMouseDown={handleMouseDown} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp} onMouseLeave={handleMouseUp}>
        {props.children}
      </div>
      <button onClick={handleScrollRight} disabled={isAtEnd} className="disabled:opacity-10 text-gray-500 hover:text-gray-700 rounded-md bg-gray-50 ml-1 mb-4">
        <ChevronRightIcon className="w-5 h-5" />
      </button>
    </div>
  );
}
