import { useEffect, useState } from "react";
import { ArrowDownTrayIcon, ArrowsPointingInIcon, MagnifyingGlassIcon, TrashIcon } from "@heroicons/react/20/solid";
import { CheckIcon, DocumentDuplicateIcon, HeartIcon } from "@heroicons/react/24/outline";
import Modal from "./ui/Modal";
import { classNames } from "./../data/AppHelpers";

export default function OutputGalleryItem(props) {

  const [modal, setModal] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaved, setIsSaved] = useState(false);
  const { ClipboardItem } = window;

  useEffect(() => {
    loadImageSimulator();
  }, []);

  async function loadImageSimulator() {
    setIsLoading(false);
  }

  async function copyHandler(url, e) {

    e.stopPropagation();

    try {
      const image = await fetch('https://copymatic.ai/image-fetcher/', {
        mode: "no-cors",
        method: "POST",
        body: JSON.stringify({ "url": url })
      });

      console.log(image.blog());

      await navigator.clipboard.write([
        new ClipboardItem({
          ['image/png']: image.blob()
        })
      ])
    } catch (error) {
      setModal({ title: "error", message: "cannot copy", type: "error" });
    }

  };

  const modalHandler = () => {
    setModal(null);
  };

  // async function fetchImage(url) {
  //   const fetchedImageData = await fetch('https://copymatic.ai/image-fetcher/', {
  //     method: "POST",
  //     body: JSON.stringify({ "url": url })
  //   }).then((response) => {
  //     return response;
  //   });
  //   return fetchedImageData;
  // }

  function saveHandler(e) {
    e.stopPropagation();

    if (!isSaved) {
      props.onSave(e)
        .then((response) => {
          if (response.data.image_id) {
            console.log(response.data)
            setIsSaved(true)
          } else {
            setModal({ title: "Error saving content" })
          }
        })
    }
  }

  function downloadHandler(url) {
    fetch('https://copymatic.ai/image-fetcher/', {
      method: "POST",
      body: JSON.stringify({ "url": url })
    }).then(response => {
      response.arrayBuffer().then(function (buffer) {
        console.log(buffer)
        const blobUrl = window.URL.createObjectURL(new Blob([buffer]));
        console.log(blobUrl)
        const link = document.createElement("a");
        link.href = blobUrl;
        link.setAttribute("download", "image.png");
        document.body.appendChild(link);
        link.click();
      });
    })
  };

  return (
    <>
      {modal && <Modal onClose={modalHandler} title={modal.title} content={modal.message} type="error" buttonLabel="OK" />}

      <article onClick={props.onClick} className={classNames(props.isSelected && "border-2 border-primary-700", "aspect-square border-2 border-transparent relative group h-full overflow-hidden hover:opacity-100 duration-300 leading-relaxed font-bold text-gray-700 bg-white rounded-md border border-gray-200 hover:scale-[1.02]")}>
        <img id="image" className="h-full w-full object-cover" src={props.source} alt={props.title} />

        {props.isExpanded && (
          <div className={classNames("top-0 left-0 text-left absolute p-4")}>
            <button onClick={props.onClose} type="button" className="inline-grid shadow-sm active:scale-[0.98] duration-300 place-items-center opacity-75 rounded-full bg-slate-800 px-4 py-4 text-white hover:opacity-100 hover:scale-[1.02]">
              <ArrowsPointingInIcon className="h-6 w-6" />
            </button>
          </div>
        )}

        <div className={classNames(props.isExpanded ? "top-0 right-0" : "bottom-0 right-0 ", "text-right absolute p-4 opacity-0 duration-200 pointer-events-none group-hover:pointer-events-auto group-hover:opacity-100 flex space-x-3")}>
          {/* <button onClick={copyHandler.bind(this,props.source)} type="button" className="inline-flex shadow-sm active:scale-[0.98] duration-100 items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50">
            {isCopied ? (
              <>
                <svg className="animate-spin mr-1 h-3 w-3 inline text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                <span>Copy</span>
              </>
            ) : (
              <>
                <DocumentDuplicateIcon className="h-3 w-3 mr-1" />
                <span>Copy</span>
              </>
            )}
          </button> */}

          <button onClick={() => { downloadHandler(props.source) }} type="button" className="inline-flex shadow-sm active:scale-[0.98] duration-100 items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50">
            <ArrowDownTrayIcon className="h-3 w-3 mr-1" />
            <span>Download</span>
          </button>

          {/* in the new tab, items can be saved */}
          <button onClick={saveHandler} type="button" value={props.id} id={props.id} className="inline-flex shadow-sm active:scale-[0.98] duration-100 items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50">
            {isSaved ? (
              <>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-3 w-3 mr-1 text-red-500">
                  <path d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z" />
                </svg>
                <span>Saved</span>
              </>
            ) : (
              <>
                <HeartIcon className="h-3 w-3 mr-1" />
                <span id={props.id}>Save</span>
              </>
            )}
          </button>
        </div>
      </article>
    </>
  );
}
