export default function ProgressBar(props) {
  const { completed } = props;

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const fillerStyles = {
    width: `${completed}%`,
    /*backgroundColor: color,*/
  };

  return (
    <div className="h-3 bg-gray-200 rounded-full w-full dark:bg-slate-700">
      <div className={classNames(props.bgColor, "rounded-full text-right h-full")} style={fillerStyles}>
        <span className="hidden">{`${completed}%`}</span>
      </div>
    </div>
  );
}
