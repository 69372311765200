import { ArrowUpRightIcon, EnvelopeIcon, StarIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import Badge from "../Components/ui/Badge";
import ToolIcon from "./ToolIcon";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ToolPanel(props) {
  const { id, category, icon, customIconColor, url, name, description, favorite, isNew, isBeta, key } = props.tool;

  return (
    <li>
      <Link to={url ? url : "/tool-details"}>
        <div key={id} className={classNames(category === "dev" && "border-4 border-amber-400", "col-span-1 group flex min-h-full flex-col divide-y divide-gray-300 rounded-lg bg-white text-left shadow hover:drop-shadow-lg hover:scale-[1.03] hover:cursor-pointer duration-200 dark:bg-slate-800 dark:hover:bg-slate-700")}>
          <div className="flex flex-1 flex-col p-6">
            <div className="flex justify-between items-center">
              <ToolIcon size="md" icon={icon} category={category} customColor={customIconColor} />

              {/* If favorite: show the star icon*/}
              {favorite && <StarIcon className="fill-yellow-500 h-6 w-6" aria-hidden="true" />}
              {!favorite && (
                <div className="flex space-x-3">
                  {/* If not favorite show badges */}
                  {isBeta && <Badge color="light">Beta</Badge>}
                  {isNew && <Badge color="primary">New</Badge>}
                </div>
              )}
              {/* If no badges or favorite, show arrow */}
              {!favorite && !isNew && !isBeta && <ArrowUpRightIcon className="fill-gray-200 group-hover:fill-gray-400 duration-200 -mt-2 h-7 w-7 dark:fill-gray-700 dark:group-hover:fill-gray-400" aria-hidden="true" />}
            </div>

            <h3 className="mt-5 text-md font-bold text-gray-900 dark:text-gray-100">{name}</h3>
            <dl className="mt-2 flex flex-grow flex-col justify-between">
              <dd className="text-sm text-gray-500 font-medium dark:text-slate-400 line-clamp-3 dark:group-hover:text-slate-300">{description}</dd>
              <dt className="sr-only">Description</dt>
            </dl>
          </div>
        </div>
      </Link>
    </li>
  );
}
