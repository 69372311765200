import { Fragment, useState, useContext } from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import KeywordsInput from "../../Components/KeywordsInput";
import StatusList from "../../Components/StatusList";
import Button from "../../Components/ui/Button";
import Modal from "../../Components/ui/Modal";
import LoadingModal from "../../Components/ui/LoadingModal";
import { AppDataContext, classNames } from "../../data/AppHelpers";
import { plagarismHttp } from "../../utils/http";

export default function Accordion(props) {

  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { appDataContext } = useContext(AppDataContext);

  const accordionEntries = [
    {
      title: "Content Analysis",
      content: (
        <div>
          <StatusList seoAnalysis={props.seoAnalysis} seoAnalysisHandler={props.seoAnalysisHandler} />
        </div>
      ),
    },
    {
      title: "Plagiarism Checker",
      content: (
        <div className="p-5 text-sm font-medium text-gray-700 pt-0">
          <p className="mb-4 block">A plagiarism rate below 5% is really good, between 5 and 10% is considered to be reasonable.</p>
          <Button
              onClick={props.checkPlagiarism}
              type="button"
              className="w-full"
              buttonSize="sm"
              buttonStyle="outlined"
          >
            Check Plagiarism
          </Button>
        </div>
      ),
    },
  ];

  // async function checkPlagiarismHandler(toolFormData) {
  //   const content = props.editorRef.current.getContent();
  //   setIsLoading(true);
  //
  //   plagarismHttp(content, appDataContext.user.meta.cf_key)
  //     .then((response) => {
  //       let title;
  //       let message;
  //       let type;
  //       let percent;
  //       console.log('plagiarism response: ', response)
  //       if (response.data) {
  //         percent = response.data.rate;
  //
  //         switch (true) {
  //             case percent === 0:
  //                 title = "Perfect Rate";
  //                 message = `Congrats! The plagiarism rate of this text is 0%. This means that your text is completely original. ${response.data.results.toString()} results found for ${response.data.wordcount.toString()} words.`
  //                 break;
  //             case percent <= 5:
  //                 title = "Good Rate";
  //                 message = `The plagiarism rate of this text is ${percent}%. ${response.data.results.toString()} results found for ${response.data.wordcount.toString()} words.`
  //                 break;
  //           case percent > 5 && percent <= 10:
  //               title = "Reasonable Rate";
  //               message = `The plagiarism rate of this text is ${percent}%. ${response.data.results.toString()} results found for ${response.data.wordcount.toString()} words.`
  //               break;
  //           default: {
  //               title = "High Plagiarism Detected";
  //               message = `The plagiarism rate of this text is ${percent}%. Use the rewrite tool to bring the plagiarism score lower. ${response.data.results.toString()} results found for ${response.data.wordcount.toString()} words.`
  //           }
  //         }
  //       } else {
  //         type = 'error';
  //         title = "High Plagiarism Detected";
  //         message = "Use the rewrite tool to bring the plagiarism score lower."
  //       }
  //       setIsLoading(false)
  //
  //       setModal({
  //         title: title,
  //         message: message,
  //       });
  //       props.seoAnalysisHandler(seoAnalysis => {
  //         return {
  //           ...seoAnalysis,
  //           plagiarismScore: (percent <= 5)
  //         }
  //       })
  //     }).catch((error) => {
  //       console.log(error)
  //       setIsLoading(false)
  //     })
  // }

  const modalHandler = () => {
    setModal(null);
  };

  return (
    <>
      {isLoading && <LoadingModal title="Checking Plagiarism" content="Please wait, this can take a few seconds." onClose={modalHandler} />}
      {modal && <Modal onClose={modalHandler} title={modal.title} content={modal.message} buttonLabel="OK" />}

      <div className="mx-auto divide-gray-200">
        <dl className="border-b border-gray-100">
          {accordionEntries.map((accordionEntry) => (
            <Disclosure as="div" key={accordionEntry.title}>
              {({ open }) => (
                <>
                  <dt className="text-base">
                    <Disclosure.Button className="flex items-center justify-between w-full px-6 py-4 font-bold text-left duration-200 border-b-0 border-gray-200 hover:bg-gray-100 border-t border-gray-100 text-gray-900">
                      <span className="font-bold text-gray-900">{accordionEntry.title}</span>
                      <span className="ml-6 flex h-7 items-center">
                        <ChevronDownIcon className={classNames(open ? "-rotate-180" : "rotate-0", "h-6 w-6 transform")} aria-hidden="true" />
                      </span>
                    </Disclosure.Button>
                  </dt>
                  <Disclosure.Panel as="dd" className="pt-2 font-normal border border-b-0 border-t-0 border-gray-50">
                    <p className="text-base text-gray-500">{accordionEntry.content}</p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </dl>
      </div>
    </>
  );
}
