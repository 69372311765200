import { Fragment, useState, useRef } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function MetaData(props) {

  const data = [
    {name: "Language", value: props.post_language},
    {name: "Creativity", value: props.post_creativity},
    {name: "Keyword", value: props.post_keyword},
    {name: "Description", value: props.post_description}
  ];
  return (
    <>
      <div className="flex flex-wrap lg:flex-nowrap space-x-1">
        {data.map((item) => (
          <div key={item.name} className={"block rounded-md px-2 lg:py-0 py-2 text-base font-medium"} title={item.value}>
            <div className="text-[11px] mr-2 -mb-0.5 tracking-wide font-semibold uppercase text-gray-400">{item.name}</div>
            <div className={classNames(item.width > 1 ? "w-64" : "max-w-24", "text-xs tracking-wide font-bold text-gray-700 whitespace-nowrap overflow-hidden text-ellipsis bg-gray-100 border border-gray-200 rounded py-1 px-1.5")}>{item.value}</div>
          </div>
        ))}
      </div>
    </>
  );
}
