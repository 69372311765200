import React, { useContext, useEffect, useReducer } from "react";
import ToolsTabs from "../Partials/ToolsTabs";
import ToolsGrid from "../Partials/ToolsGrid";
import HeaderBanner from "../Partials/HeaderBanner";
import TOOLS from "../data/tools";
import { AppDataContext } from "./../data/AppHelpers";
import MyTagsInput from "./../Components/ui/TagsInput";

const setCategories = () => {
  const categories = { all: TOOLS };
  TOOLS.map((tool) => {
    const category = tool.category;
    if (categories.hasOwnProperty(category)) {
      const soFar = categories[category];
      categories[category] = [...soFar, tool];
    } else {
      categories[category] = [tool];
    }
  });

  return categories;
}

const categories = setCategories();
const filter = (state, newSearchParams) => {
  console.log(state)
  let newToolList;
  if (newSearchParams.hasOwnProperty('category')) {
    switch (newSearchParams.category) {
      case "website": newToolList = [...categories["website"], ...categories["copywriting"]]; break;
      case "marketing": newToolList = [...categories["marketing"], ...categories["ecommerce"]]; break;
      case "blog":
      case "social":
      case "copywriting":
      case "more":
        newToolList = categories[newSearchParams.category];
        break;
      case "all":
        newToolList = TOOLS;
        break;
    }

    return {
      ...state,
      tools: [...newToolList],
      category: newSearchParams.category,
    }
  }
  else if (newSearchParams.hasOwnProperty('keywords')) {

    const newKeywords = newSearchParams.keywords;
    if (newKeywords.length === 0) {
      return {
        ...state,
        tools: categories[state.category],
        keywords: [],
      }
    }
    newToolList = state.tools.filter(t => {
      const matches = newKeywords.map(kw => t.name.toLowerCase().includes(kw.toLowerCase()))
      if (matches.length === matches.filter(m => m === true).length) {
        return true;
      }
    });

    return {
      ...state,
      tools: newToolList,
      keywords: newKeywords,
    }
  }

  // return state?
};

function AllTools(props) {

  const [toolSearch, setToolSearch] = useReducer(filter, { tools: TOOLS, category: "all", keywords: [] });
  const { appDataContext, paidUserStatus } = useContext(AppDataContext);

  useEffect(() => {
    let screenHeight;

    if (appDataContext.isDependencyLoaded) {
      const body = document.body;
      const html = document.getElementById('root');

      screenHeight = Math.max(body.scrollHeight, body.offsetHeight,
        html.clientHeight, html.scrollHeight, html.offsetHeight);
      /* global SM */
      SM.client('resizeWindow', { height: screenHeight });
    }
  });

  const searchToolHandler = (newSearchKeywords) => {
    setToolSearch({ keywords: newSearchKeywords })
  }

  const toggleHanlder = (newCategory) => {
    setToolSearch({ category: newCategory });
  };

  return (
    <>
      <div className="mx-auto max-w-7xl w-full px-4 sm:px-6 md:px-8 ">
        <div className="w-full">
          {!paidUserStatus ? <HeaderBanner appData={appDataContext}/> : <></>}

          <h1 className="text-3xl font-extrabold text-gray-900 mb-4 dark:text-white">All Tools</h1>

          <ToolsTabs categories={categories} onToggle={toggleHanlder} />

          <br />

          <MyTagsInput
            name={"search keyword"}
            value={toolSearch.keywords}
            onChange={searchToolHandler}
            background={"bg-white"} />

          <div id="TabContent" className="py-8">
            <ToolsGrid toolList={toolSearch.tools} />
          </div>
        </div>
      </div>
    </>
  );
}

export default AllTools;
