import { CheckCircleIcon, QuestionMarkCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";

const listItems = [
  {
    key: "contentOver800Words",
    text: "Content is over 800 words long"
  },
  {
    key: "titleContainsKeyword",
    text: "Main heading contains keyword"
  },
  {
    key: "shortParagraphs",
    text: "Use short paragraphs"
  },
  {
    key: "minimumHeadlines",
    text: "Use a minimum of 4 subheadings"
  },
  {
    key: "subheadingKeyword",
    text: "Subheadings contain keyword"
  },
  {
    key: "keywordInContent",
    text: "Keyword found in the content"
  },
  {
    key: "keywordInFirstParagraphs",
    text: "Keyword is in the first 2 paragraphs"
  },
  {
    key: "keywordDensity",
    text: "Keyword density is 0.58 %"
  },
  {
    key: "threeKeywords",
    text: "Focus on less than 3 keywords"
  }, // needed?
  {
    key: "titleContainsNumber",
    text: "Title contains a number"
  },
  {
    key: "plagiarismScore",
    text: "Plagiarism rate is less than 5 %"
  },
  {
    key: "nineParagraphs",
    text: "Use a minimum of 9 paragraphs"
  },
  {
    key: "fiveLinks",
    text: "Use a minimum of 5 links"
  }
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function StatusList(props) {

  console.log(props)

  const isFixedHeight = props.isFixedHeight; // TODO: setup and use

  function renderIcon(state) {
    switch (state) {
      case true:
        return <CheckCircleIcon className="h-5 w-5 flex-shrink-0 text-green-300" aria-hidden="true" />;
      case false:
        return <XCircleIcon className="h-5 w-5 flex-shrink-0 text-red-300" aria-hidden="true" />;
      default:
        return <QuestionMarkCircleIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />;
    }
  }

  return (
    <>
      <ul className={classNames(!isFixedHeight && "", "h-48 overflow-y-scroll scroll space-y-3 mr-1 h-[11rem] px-5 pb-5  overflow-x-hidden")}>
        {listItems.map((item, idx) => (
          <li key={"seoanalysis-" + idx} className="flow-root">
            <span className="-m-3 flex items-center rounded-md p-3 text-sm font-medium text-gray-900 transition duration-150 ease-in-out">
              {props.seoAnalysis && props.seoAnalysis.hasOwnProperty(item.key) && renderIcon(props.seoAnalysis[item.key])}
              {!props.seoAnalysis && renderIcon("broken")}
              <span className="ml-1">{item.text}</span>
            </span>
          </li>
        ))}
      </ul>
    </>
  );
}
