import { Bars3Icon, ExclamationTriangleIcon, MinusIcon, PlusIcon } from "@heroicons/react/20/solid";
import { BoltIcon } from "@heroicons/react/24/solid";
import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Label from "./Label";

export default function DragDropList(props) {
  const listPrefix = props.prefix ? props.prefix : "list-item";
  const placeholder = props.placeholder ? props.placeholder : "Subheading";
  const [itemList, setItemList] = useState(props.items);
  const [nextId, setNextId] = useState(itemList.length + 1); // Keep track of the new IDs (start at the lenght of the list)
  const maxItems = props.maxItems ? props.maxItems : 99; // if no max configured, set max to 99

  // Function to update list on drop
  const handleDrop = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    var updatedList = [...itemList];
    // Remove dragged itemn
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State
    setItemList(updatedList);
  };

  function addEmptyItem() {
    var updatedList = [...itemList];
    let newIdString = nextId.toString();
    setNextId(nextId + 1);
    updatedList.push({ id: newIdString, value: "" });
    setItemList(updatedList);
  }

  function removeItemHandler(index) {
    var updatedList = [...itemList];
    updatedList.splice(index, 1);
    setItemList(updatedList);
  }

  function handleChange(idx, event) {
    const updatedList = itemList;
    updatedList[idx] = { id: idx, value: event.target.value };
    setItemList(updatedList);
    props.onChange(updatedList.map((i) => i.value));
  }

  return (
    <>
      {/* Label */}
      <div className="mb-0 flex items-center justify-start gap-1">
        <Label name={props.name} required={props.required} labelHelpToolTip={props.labelHelpToolTip}>
          {props.label}
        </Label>
        {props.minItems && props.maxItems && (
          <div className="text-sm text-gray-400 font-semibold">
            (min {props.minItems} / max {props.maxItems})
          </div>
        )}
        {/* TODO plugin subheading generation...
          <button className="text-xs px-2 py-1.5 hover:bg-gray-50 group duration-200 rounded-md border border-gray-200 text-gray-500 hover:text-primary-500 font-bold flex items-center float-right">
          <BoltIcon className="h-4 w-4 group-hover:text-primary-400 duration-200 text-gray-400 mr-1" />
          Write subheadings for me
        </button>*/}
      </div>

      <div className="flex items-center  justify-center text-center">
        <DragDropContext onDragEnd={handleDrop}>
          <Droppable droppableId="list-container" className="relative">
            {(provided) => (
              <div className="flex w-full flex-col" {...provided.droppableProps} ref={provided.innerRef}>
                {itemList.map((item, index) => (
                  <Draggable draggableId={item.id.toString()} key={item.id} index={index}>
                    {(provided) => (
                      <div className="flex group items-center w-100 my-1" ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps}>
                        {itemList.length > 1 && <Bars3Icon className="h-5 w-5 mr-3" aria-hidden="true" />}
                        <div className="grow">
                          <div className="relative w-100 rounded-md shadow-sm">
                            <input autoFocus onChange={handleChange.bind(this, index)} type="text" name={props.name} id="menu-item" placeholder={placeholder} className="block w-full placeholder:text-gray-400 placeholder:font-medium rounded-md border-gray-300 bg-gray-100 pr-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" defaultValue={item.value} />
                            <div className="absolute inset-y-0 right-0 flex items-center pr-2">
                              <div className="flex">
                                {index === itemList.length - 1 && index < maxItems - 1 && (
                                  <button type="button" className="inline-flex items-center rounded border border-transparent p-1 text-gray-800 hover:cursor-pointer hover:bg-gray-200 hover:text-gray-900">
                                    <PlusIcon onClick={addEmptyItem} className="h-5 w-5" aria-hidden="true" />
                                  </button>
                                )}

                                {itemList.length > 1 && (
                                  <button type="button" className="inline-flex items-center rounded border border-transparent p-1 text-gray-800 hover:cursor-pointer hover:bg-gray-200 hover:text-gray-900">
                                    <MinusIcon
                                      onClick={() => {
                                        removeItemHandler(index);
                                      }}
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      {/* Minimum entries message */}
      {props.minItems && itemList.length < props.minItems && (
        <div className="flex items-center mt-1 text-gray-500 text-xs font-medium">
          <ExclamationTriangleIcon className="h-4 w-4 text-orange-400 mt-0.5 mr-1" />
          Select minimum {props.minItems} {props.itemType ? props.itemType : "option"}s.
        </div>
      )}
    </>
  );
}
