import axios from "axios";

export async function plagarismHttp(jwtToken, cfKey, content, url) {
    console.log('Before calling plagiarismHttp: cfKey', cfKey, 'content', content);
    console.log('Before API Call: content', typeof content, content);
    console.log('Before API Call: cfKey', typeof cfKey, cfKey);

    const request = {
        model: 'plagiarism-checker',
        key: cfKey,
        query: content
    }
    console.log('plagarism request', request);
    console.log('cfKey', cfKey);
    return axios.post("https://copymatic.ai/temp-api/", request);
}

// the n value is fixed tho...why
export async function imageHttp(cfKey, { description, style, medium, mood, details }) {

    const request = {
        model: "image-generator",
        key: cfKey,
        image_description: description,
        image_engine: "realistic",
        image_style: style,
        image_medium: medium,
        image_artist: "none",
        image_mood: mood,
        image_details: details,
        n: 4
    };
    try {
        const response = await axios.post("https://copymatic.ai/temp-api/", request);

        if (response.data && response.data.results) {
            return response.data.results;
        } else {
            throw new Error('Unexpected API response');
        }
    } catch (error) {
        console.error('Error fetching images:', error);
        throw error;
    }
}

export async function modelHttp(request, isArticleGenerator = false) {
    if (isArticleGenerator) {
        const numRequests = request.outline.length;
        let balance;
        let plan_id;
        let results = [];
        for (let i = 0; i < numRequests; i++) {
            const data = await axios.post("https://copymatic.ai/article-api/", request).then(response => response.data);
            // console.log(`data ${i}`, data);
            results = [ ...results, ...data.results ];
            if (i === numRequests - 1) {
                balance = data.balance;
                plan_id = data.plan_id;
            }
            request.step++;
        }
        return { results, balance, plan_id };
    }
    return await axios.post("https://copymatic.ai/temp-api/", request).then(response => response.data);
}

export async function saveHttp(request) {
    return await axios.post("https://copymatic.ai/wp-json/copymatic/save", request);
}

export async function statsHttp(request) {
    return await axios.post("https://copymatic.ai/temp-api/", request);
}

function modelNameMap(toolId) {
    // Not sure what the intent was with this switch case but I removed it in implementing the correct logic for the article generator on 1/10/24 - Becca

    // switch (toolId) {
    //     case "article-generator": return "blog-intros-new";
    // }

    return toolId;
}

export function buildRequest(appDataContext, formDataFields, tool) {

    const request = {
        model: modelNameMap(tool.id),
        key: appDataContext.user.meta.cf_key,
    };

    const errors = [];

    formDataFields.map((f, i) => {

        const fieldName = f.field.name;
        let fieldVal;

        if (f.field.minItems) {
            if (f.value && f.value.length >= f.field.minItems) {
                fieldVal = f.value;
            } else if (f.value && f.value.length < f.field.minItems) {
                errors.push(`${f.field.label} requires ${f.field.minItems} values. Has ${f.value.length}.`);
            } else {
                errors.push(`${f.field.label} is empty`);
            }
        } else if (tool.id === 'article-generator' && fieldName === "n") {
            const subheadings = formDataFields.find(f => f.field.name === 'subheading');
            fieldVal = subheadings ? subheadings.value.length : 3;
        } else if (fieldName === "n" && f.value === -1) {
            fieldVal = 1;
        } else {
            fieldVal = f.value;
        }
        request[fieldName] = formatApiValue(fieldName, fieldVal);
    });


    if (tool.id === 'article-generator') {
        request.title = request.blog_title;
        delete request.blog_title;
        request.step = 1;
        request.outline = request.subheading;
        delete request.subheading;
        if (request.keyword) {
            request.keyword = request.keyword.length > 1 ? request.keyword.join(', ') : request.keyword[0];
        }
        request.is_retry = false;
    }

    return [request, errors];
}

export function formatApiValue(fieldName, fieldValue) {
    switch (fieldName) {
        case "language": return fieldValue.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '').trim();
        case "creativity": return fieldValue.toLowerCase();
        default: return fieldValue;
    }
}
