import { useState } from "react";
import OutputGalleryItem from "./OutputGalleryItem";

export default function OutputGallery(props) {
  const [openItem, setOpenItem] = useState(null);

  function itemClickHandler(i) {
    setOpenItem(props.outputList[i]);
  }

  return (
      <>
        {/* Viewing details */}
        {openItem && (
            <div>
              <OutputGalleryItem
                  onClose={() => setOpenItem(null)}
                  isExpanded={true}
                  id={openItem.id}
                  alt={openItem.title}
                  source={openItem.content?.url || openItem.content} // Safely access url
              />
            </div>
        )}

        {/* List of output items */}
        {!openItem && (
            <>
              <ul className="grid grid-cols-2 gap-4">
                {props.outputList.map((image, i) => {
                  return (
                      <OutputGalleryItem
                          isLoading={props.isLoading}
                          onClick={() => itemClickHandler(i)}
                          onCopy={() => console.log("copy")}
                          onDownload={() => console.log("download")}
                          onSave={props.eventHandlers.saveHandler}
                          eventHandlers={props.eventHandlers}
                          isSelected={props.isSelected}
                          isSaved={props.isSaved}
                          key={i + "-image"}
                          id={i}
                          alt={image.title}
                          source={image.content?.url || image.content} // Safely access url
                      />
                  );
                })}
              </ul>
            </>
        )}
      </>
  );
}
