// TODO: Generalize dropdown as UI element

import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ExportButton(props) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex group w-full justify-between items-center rounded-md border border-gray-200 bg-white px-5 py-2 text-[13px] font-semibold text-gray-700 shadow-sm duration-200 hover:bg-gray-50">
          <span>Export</span>
          <ChevronDownIcon className="h-5 w-5 -mr-2 ml-1 duration-200 text-gray-300 group-hover:text-gray-500" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
        <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>{({ active }) => <div onClick={props.copyContent} className={classNames(active ? "bg-gray-100 text-gray-900" : "text-gray-700", "block text-left px-4 py-2 text-sm hover:bg-gray-50 duration-100 hover:cursor-pointer")}>Copy content</div>}</Menu.Item>
            <Menu.Item>{({ active }) => <div onClick={props.copyHtml} className={classNames(active ? "bg-gray-100 text-gray-900" : "text-gray-700", "text-left block px-4 py-2 text-sm hover:bg-gray-50 duration-100 hover:cursor-pointer")}>HTML code</div>}</Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
