import { useRef, useState } from "react";
import Label from "../ui/Label";
import FormDropdown from "../ui/FormDropdown";
import Tippy from "@tippyjs/react";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import Button from "../ui/Button";
import {CREATIVITY_VALUES, LANGUAGES} from "../../data/languages";
import MyTagsInput from "../ui/TagsInput";

export default function BlogWriterForm(props) {

  const [isValidForm, setIsValidForm] = useState(false);

  const [enteredContent, setEnteredContent] = useState("");
  const [isTooShort, setIsTooShort] = useState(true);


  const language = props.formFields.find(ff => ff.field.id === "language");
  const creativity = props.formFields.find(ff => ff.field.id === "creativity");
  const blogDescription = props.formFields.find(ff => ff.field.id === "blog_description");
  const keywords = props.formFields.find(ff => ff.field.id === "keyword");

  function handleDropdownChange(name, data) {
    const event = {target: {value: data, name: name}};
    props.onChange(event)
  }

  return (
    <>
      <div className="border-t px-4 pt-5 flow-root">
        {/* 2 COL GRID LAYOUT */}
        <div className="grid grid-cols-2 gap-x-6">
          {/* Language dropdown */}
          <div className="mb-6">
            <div className="mb-1 block">
              <Label name="language">Language</Label>
            </div>
            <FormDropdown onChange={handleDropdownChange.bind(this, "language")} values={LANGUAGES} name="language"  value={language.value}/>
          </div>

          {/* Creativity */}
          <div className="mb-6">
            <div className="mb-1 block">
              <div className="flex items-center">
                <Label name="creativity">Creativity</Label>
                <Tippy
                  allowHTML={true}
                  placement="top"
                  content={
                    <div className="px-2 py-2 text-left">
                      <strong>Controls randomness:</strong> the creativity represents the imagination of the Ai. Lower creativity means lower random results. Increase the creativity if you notice that the results are too repetitive.
                    </div>
                  }
                >
                  <QuestionMarkCircleIcon className="h-5 w-5 ml-1 hover:cursor-pointer hover:text-gray-500 text-gray-300" />
                </Tippy>
              </div>
            </div>
            <FormDropdown onChange={handleDropdownChange.bind(this, "creativity")} values={CREATIVITY_VALUES} name="creativity" value={creativity.value}/>
          </div>
        </div>

        <div className="mb-6">
          <div className="mb-1 block">
            <div className="flex justify-between">
              <Label name="blog_description">What do you want to write about?</Label>
              <div className="text-xs text-gray-400">{enteredContent.length}/200</div>
            </div>
          </div>
          <Tippy allowHTML={true} delay={[2000, 0]} placement="right" content={<div className="px-2 py-2 text-left">Please lengthen this text to 40 characters or more (you are currently using 35 characters).</div>}>
            <textarea value={blogDescription.value ? blogDescription.value : ""} type="text" onChange={props.onChange} name="blog_description" className="block w-full bg-gray-100 rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm placeholder:text-gray-400 placeholder:font-medium" rows="4" aria-describedby="focus-keywords" placeholder="Explain what is your blog post about (min. 40 characters). e.g. A blog article explaining how copywriting can drive more traffic to your website." />
          </Tippy>
          {isTooShort && (
            <div className="flex items-center mt-1 text-gray-500 text-xs font-medium">
              <ExclamationTriangleIcon className="h-4 w-4 text-orange-400 mt-0.5 mr-1" />
              {40} more characters needed in your description.
            </div>
          )}
        </div>
        <div className="mb-6">
          {/*<input type="text" name="targetedKeyword" id="targetedKeyword" className="block w-full bg-gray-100 rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm placeholder:text-gray-400 placeholder:font-medium" aria-describedby="focus-keywords" placeholder="e.g. AI copywriting" />*/}
          <MyTagsInput value={keywords.value} onChange={handleDropdownChange.bind(this, "keyword")} name="keyword" label="Targeted Keyword"/>
        </div>
      </div>
    </>
  );
}
