import { useState, useEffect, useContext } from "react";
import { Popover } from "@headlessui/react";
import { ArrowLeftIcon, ArrowPathIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import { Bars3Icon, BellIcon, XMarkIcon, PlusCircleIcon, DocumentDuplicateIcon, QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import Tippy from "@tippyjs/react";
import "tippy.js/themes/light.css";
import StickyHeader from "./StickyHeader";
import ExportButton from "./ExportButton";
import StatusList from "../../Components/StatusList";
import ProgressBar from "../../Components/ui/ProgressBar";
import MetaData from "./MetaData";
import ArticleSection from "./ArticleSection";
import SaveButton from "../../Components/ui/SaveButton";
import { useSearchParams } from "react-router-dom";
import { AppDataContext, countWords, classNames } from "./../../data/AppHelpers";
import { config } from "./../../config";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { info, success, warning, error } from "../../notify/Notify";
import { useCreditsContext } from "../../data/CreditsContext";
import * as ReactDOMServer from "react-dom/server";

function getSessionData(sessionId) {
  const jsonContent = JSON.parse(localStorage.getItem(sessionId));
  const blogData = {};
  const blogContent = [];
  blogContent.push({ content: jsonContent.find((item) => item.field.id === "blog_intro").value });
  jsonContent
    .find((item) => item.field.id === "blog_outline")
    .value.split("\n")
    .map((heading, i) => {
      blogContent.push({ headline: heading, content: "" });
    });
  blogData.post_title = jsonContent.find((item) => item.field.id === "blog_title").value;
  blogData.post_content = blogContent;

  // TODO these need to change to match what the db has so I can just read from the
  // db instead of having to interpret...
  blogData.post_intro = blogContent[0];
  blogData.post_language = jsonContent.find((item) => item.field.id === "language").value.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, "");
  blogData.post_creativity = jsonContent.find((item) => item.field.id === "creativity").value;
  blogData.post_description = jsonContent.find((item) => item.field.id === "blog_description").value;
  blogData.post_keyword = jsonContent.find((item) => item.field.id === "keyword").value.join(", ");
  return blogData;
}

function shouldGenerate(searchParams, sections) {
  if (searchParams.has("sessionId")) {
    const sessionId = searchParams.get("sessionId");
    const jsonContent = JSON.parse(localStorage.getItem(sessionId));
    const generate = jsonContent.find((item) => item.field.id === "generate").value;
    return generate === "yes";
  }
  return false;
}

// TODO: Add copy content and help button to header for small screens
export default function BlogWriter(props) {
  const [searchParams] = useSearchParams();
  const [sections, setSections] = useState([]); // should probably be sections passed in from props;
  const [wordcount, setWordcount] = useState(0);
  const generateContent = shouldGenerate(searchParams, sections);
  const [isLoading, setLoading] = useState(generateContent);
  // use a default state
  const [blogData, setBlogData] = useState({
    post_title: "",
    post_content: [],
    post_keyword: [],
    post_description: "",
    post_language: "",
    post_creativity: "",
  }); // default to session data but will be null
  const [postID, setPostID] = useState(searchParams.get("id"));
  // const [SEOAnalysis, setSEOAnalysis] = useState({
  //   shortParagraphs: false,
  //   minimumHeadlines: false,
  //   subheadingKeyword: false,
  //   keywordInContent: false,
  //   keywordInFirstParagraphs: false,
  //   keywordDensity: 0.0,
  //   titleContainsNumber: false,
  //   plagarismScore: 100.00,
  // });
  const [isSaving, setSaving] = useState(false);
  // const [SEOScore, setSEOScore] = useState(0);
  const {appDataContext} = useContext(AppDataContext);
  const navigate = useNavigate();
  const { credits } = useCreditsContext();

  function contentHandler(index, content, headline) {
    const headlineCount = blogData.post_content.length - 1; // -1 because the intro is included in the payload;
    const currentSections = sections;
    currentSections[index] = { headline: headline, content: content };
    const newSections = currentSections;
    setWordcount((wordcount) => wordcount + countWords(content));
    setSections(currentSections);

    if (headlineCount === newSections.filter((e) => e !== undefined).length) {
      setLoading(false);
      const title = blogData.post_content[0];
      const currentBlogData = blogData;
      currentBlogData.post_content = [title, ...currentSections];
      setBlogData((db) => {
        console.log(db);
        console.log(sections);
        return currentBlogData;
      });
    }
  }

  function addArticleSectionHandler() {
    setSections([...sections, { headline: "", content: "" }]);
  }

  // TODO why doesn't this work
  function moveUpHandler(index, event) {
    const newSections = [...sections];
    const currentElement = sections.at(index);
    const replaceThisElement = sections.at(index - 1);
    newSections[index - 1] = currentElement;
    newSections[index] = replaceThisElement;
    setSections(newSections);
  }

  // TODO why doesn't this work
  function moveDownHandler(index, event) {
    setSections((sections) => {
      const currentElement = sections.at(index);
      const replaceThisElement = sections.at(index + 1);
      let newSections = [...sections];
      newSections[index + 1] = currentElement;
      newSections[index] = replaceThisElement;
      return newSections;
    });
  }

  function deleteHandler(index, event) {
    setSections((sections) => sections.filter((e, i) => i !== index));
  }

  async function saveHandler() {
    setBlogData((bd) => {
      console.log(bd);
      return bd;
    });
    const request = {
      model: "blog-writer",
      jwt: appDataContext.jwt,
      cf_key: appDataContext.user.meta.cf_key,
      user_id: appDataContext.user.id,
      project_id: "",
      env: "copymatic",
      type: "user_articles",
      ...blogData,
    };

    // update the post
    if (postID) {
      request.post_id = postID;
    }

    const url = config(appDataContext.ENV).copymatic_api + "save";
    setSaving(true);
    const response = await axios.post(url, request).then((response) => {
      // console.log(response.data);
      const postId = response.data.post_id;
      setPostID(postId);
      setSaving(false);
      navigate("/tools/blog-writer/?id=" + response.data.post_id);
    });
  }

  useEffect(() => {
    if (searchParams.has("sessionId")) {
      const session = getSessionData(searchParams.get("sessionId"));
      console.log(session);
      setBlogData(session);
      setSections(session.post_content);
    } else if (searchParams.has("id")) {
      const postId = searchParams.get("id");
      setPostID(postId);
      const url = config(appDataContext.ENV).copymatic_api + "content";
      const request = {
        jwt: appDataContext.jwt,
        env: "copymatic",
        post_id: postId,
      };

      axios.post(url, request).then((response) => {
        const savedContent = JSON.parse(response.data.post_content);
        const savedBlogData = {};
        console.log(savedContent[0].content);
        savedBlogData.post_title = response.data.post_title;
        savedBlogData.post_intro = savedContent[0].content;
        savedBlogData.post_language = response.data.blog_language;
        savedBlogData.post_description = response.data.blog_description;
        savedBlogData.post_creativity = response.data.blog_creativity;
        savedBlogData.post_keyword = response.data.blog_keyword;
        savedBlogData.post_content = JSON.parse(response.data.post_content);

        setBlogData(savedBlogData);
        setSections(savedContent);
      });
    }
  }, []);

  useEffect(() => {}, [blogData]);

  function copyContentHandler() {
    const data = toTxt(blogData, sections);
    navigator.clipboard.writeText(data);
  }

  function copyHtmlHandler() {
    navigator.clipboard.writeText(ReactDOMServer.renderToString(toHTML(blogData, sections)));
  }

  function toHTML(content, blogSections) {
    return (
      <>
        <h1>{content.post_title}</h1>
        <p>{content.post_content[0].content}</p>
        {content.post_content.slice(1, -1).map((s) => {
          return (
            <>
              <h2>{s.headline && s.headline}</h2>
              <p>{s.content && s.content}</p>
            </>
          );
        })}
      </>
    );
  }

  function toTxt(content, blogSections) {
    let txt = "";
    txt += content.post_title + "\n";
    txt += content.post_content[0].content + "\n";
    content.post_content.slice(1, -1).forEach((s) => {
      txt += s.headline + "\n";
      txt += s.content + "\n";
    });

    return txt;
  }

  return (
    <div className="border-0 bg-gray-100 md:bg-gray-50 border-red-500 Too">
      <Popover as="div" className={({ open }) => classNames(open ? "status inset-0 z-40 overflow-y-auto" : "", "bg-white shadow-sm lg:static lg:overflow-y-visible")}>
        {({ open }) => (
          <>
            <div className="mx-auto flex flex-col justify-center h-16 bg-gray-100 px-4 sm:px-4 lg:px-8">
              <div className="relative flex items-center justify-between lg:gap-8">
                {/* regular logo */}
                {appDataContext.user.type !== "semrush" && (
                  <Link to="/">
                    <img className="h-8 w-auto" src="/images/copymatic-logo.svg" alt="Copymatic" />
                  </Link>
                )}

                {/* generic logo placeholder for semrush users */}
                {appDataContext.user.type === "semrush" && (
                  <Link to="/">
                    <img className="h-8 w-auto mx-auto text-center" src="/images/placeholder-logo.svg" alt="AI Writing Assistant" />
                  </Link>
                )}

                <div className="flex items-center md:absolute md:inset-y-0 md:right-0 lg:hidden">
                  {/* Mobile menu button */}
                  <Popover.Button className="-mx-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-primary-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Open menu</span>
                    {open ? <XMarkIcon className="block h-6 w-6" aria-hidden="true" /> : <Bars3Icon className="block h-6 w-6" aria-hidden="true" />}
                  </Popover.Button>
                </div>
                <div className="hidden lg:flex lg:items-center lg:justify-end xl:col-span-4">
                  <Tippy content="Number of words left">
                    <div className="text-primary-500 flex items-center text-sm rounded-full border border-primary-200 bg-primary-50 font-bold py-0.5 px-3.5">
                      <ArrowPathIcon className="-ml-0.5 mr-2 h-4 w-4 text-primary-400" aria-hidden="true" />
                      {credits.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                  </Tippy>

                  {/* Profile dropdown */}
                  {/*<ProfileMenuDropdown user={props.user} />*/}
                </div>
              </div>
            </div>

            {/*<Popover.Panel as="nav" className="lg:hidden" aria-label="Global">
              <div className="mx-auto max-w-3xl space-y-1 px-2 pt-2 pb-3 sm:px-4">
                {navigation.map((item) => (
                  <a key={item.name} href={item.href} aria-current={item.current ? "page" : undefined} className={classNames(item.current ? "bg-gray-100 text-gray-900" : "hover:bg-gray-50", "block rounded-md py-2 px-3 text-base font-medium")}>
                    {item.name}
                  </a>
                ))}
              </div>
              <div className="border-t border-gray-200 pt-4 pb-3">
                <div className="mx-auto flex max-w-3xl items-center px-4 sm:px-6">
                  <div className="flex-shrink-0">
                    <img className="h-10 w-10 rounded-full" src={user.imageUrl} alt="" />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-gray-800">{user.name}</div>
                    <div className="text-sm font-medium text-gray-500">{user.email}</div>
                  </div>
                  <button type="button" className="ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mx-auto mt-3 max-w-3xl space-y-1 px-2 sm:px-4">
                  {userNavigation.map((item) => (
                    <a key={item.name} href={item.href} className="block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900">
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
            </Popover.Panel>*/}
          </>
        )}
      </Popover>
      <section className="h-48 hidden" style={{ background: "white repeating-linear-gradient( 45deg, #EEE, #EEE 5px, #FFF 5px, #FFF 10px )" }}></section>
      <StickyHeader>
        <div className="flex bg-white z-40 relative items-center justify-between border-b border-gray-200 px-6 py-3">
          <div className="flex items-end justify-start">
            <Link to="/blog-writer">
              <Tippy theme="copymatic" arrow="false" placement="bottom" content="Start over">
                <button className="inline-flex group rounded-md border border-gray-200 bg-white px-2 py-2.5 text-gray-900 mb-0.5 shadow-sm duration-200 hover:bg-gray-50 mr-3">
                  <ArrowLeftIcon className="h-4 w-4 duration-200 text-gray-500 group-hover:text-gray-500" aria-hidden="true" />
                </button>
              </Tippy>
            </Link>
            <div className="metadata hidden xl:flex space-x-2">{blogData && <MetaData {...blogData} />}</div>
          </div>
          <div className="flex items-center justify-end divide-x divide-gray-200">
            {/* <dl id="ArticleStats" className="flex space-x-4 pr-6 justify-center items-center">
              <Tippy
                allowHTML={true}
                placement="bottom"
                interactive={true}
                theme="light"
                content={
                  <div className="py-6">
                    <div className="flex px-6 mb-2 justify-between items-center">
                      <h3 className="font-bold text-sm">SEO score</h3>
                      <div>
                        <span className="text-sm">{SEOScore}</span>
                        <span className="text-xs">/100</span>
                      </div>
                    </div>
                    <div className="flex px-6 mb-5 justify-between items-center">
                      <ProgressBar completed={(SEOScore / 100)} />
                    </div>
                    <StatusList />
                  </div>
                }
              >
                <div id="StateSEOScore" className="flex items-center justify-center flex-col text-center">
                  <dt className="order-2 font-semibold text-gray-400 text-xs">SEO score</dt>
                  <dd className="order-1 font-bold text-green-700 text-sm">{SEOScore}</dd>
                </div>
              </Tippy>
              <div id="StateSEOScore" className="flex flex-col text-center">
                <dt className="order-2 font-semibold text-gray-400 text-xs">words </dt>
                <dd className="order-1 font-bold text-gray-900 text-sm">{wordcount ? wordcount: 0}</dd>
              </div>
            </dl> */}
            <div className="flex items-center pl-6 space-x-2">
              <ExportButton copyContent={copyContentHandler} copyHtml={copyHtmlHandler} />
              <Tippy theme="copymatic" placement="bottom" content="Save this article">
                <span tabIndex="0">
                  <SaveButton onClick={saveHandler} isLoading={isSaving} buttonSize="sm" buttonStyle="primary" className="w-24">
                    Save
                  </SaveButton>
                </span>
              </Tippy>
            </div>
          </div>
        </div>
      </StickyHeader>
      <main className="border-0 z-10 relative px-3 md:px-6 py-6 h-full">
        <div className="mx-auto max-w-3xl">
          <div className="p-0 -ml-2.5 mb-4 metadata xl:hidden space-x-2">
            <MetaData {...blogData} />
          </div>

          {/* Title */}
          <section className="relative max-w-4xl mb-6 w-full mx-auto">
            {/* XL: Copy + Help link ------------ */}
            <div className="menuHolder duration-400 -translate-x-full top-0 left-0 hidden xl:block absolute h-fit w-56 pr-4">
              <div className="rounded-lg h-full flex flex-col space-y-1">
                <Tippy
                  theme="copymatic"
                  allowHTML={true}
                  placement="bottom"
                  content={
                    <div className="px-2 py-2 text-center w-64">
                      <strong>Generate</strong> writes a complete new paragraph based on the subheading and the blog title.The generated content is not based on the current content of the section.
                      <br />
                      <br />
                      <strong>Write more</strong> is based on the current text of the section, not on the section subheading. It completes the current text of the section.
                    </div>
                  }
                >
                  <button className="inline-flex group w-full justify-start items-center  text-gray-400 py-2 text-sm font-semibold duration-200 hover:text-gray-500">
                    <QuestionMarkCircleIcon className="h-5 w-5 mr-2 duration-200 group-hover:text-gray-500 text-gray-300" />
                    Help
                  </button>
                </Tippy>
              </div>
            </div>
            {/* /END: Copy + Help link ------------ */}

            <div id="ArticleTitle" className="bg-white rounded-md p-8">
              <input type="text" className="p-0 border-0 focus:ring-0 text-xl font-bold w-full outline-0 focus:border-white" value={blogData.post_title} />
            </div>
          </section>

          {/* Post intro */}
          {sections && sections.length > 2 && (
            <>
              <div className="uppercase mb-2 text-gray-400 font-bold text-xs z-1">Post Intro</div>
              <ArticleSection name="Section 0" isIntro content={sections[0].content}></ArticleSection>
            </>
          )}

          {/* Article section */}
          {sections.length > 0 &&
            sections.slice(1).map((section, i) => {
              console.log(section);
              return <ArticleSection key={i} index={i} name={"Section " + i} title={section.headline} content={section.content} shouldGenerate={generateContent} {...blogData} onContentCreate={contentHandler.bind(this, i)} onDelete={deleteHandler.bind(this, i)} onMoveUp={moveUpHandler.bind(this, i)} onMoveDown={moveDownHandler.bind(this, i)} />;
            })}

          {/* show a loading gif */}
          {!blogData && <p>loading...</p>}

          <div className="relative p-8 mx-auto text-center">
            <button onClick={addArticleSectionHandler} disabled={isLoading} className="border mx-auto border-gray-300 bg-white flex items-center font-bold text-gray-500 hover:text-gray-700 text-sm py-2 px-8 rounded-md hover:bg-gray-50 duration-200">
              <PlusCircleIcon className="h-5 h-5 mr-2" />
              Add section below
            </button>
          </div>
        </div>
      </main>
    </div>
  );
}
