import React, { useState, createContext, useContext, useEffect } from "react";

export const CreditsContext = createContext();

export const useCreditsContext = () => useContext(CreditsContext);

export function CreditsContextProvider(props) {
  const [credits, setCredits] = useState(parseInt(props.credits));
  const [totalCredits, setTotalCredits] = useState(parseInt(props.totalCredits));

  function subtractCredits(usedCredits) {
    setCredits(credits - usedCredits);
  }

  function currentCredits() {
    return credits;
  }

  return (
    <CreditsContext.Provider value={{credits, totalCredits, setCredits, subtractCredits, currentCredits}}>
      {props.children}
    </CreditsContext.Provider>
  );

}
