import { useImperativeHandle } from "react";

// Style settings
const BASE_STYLE = "inline-flex flex-row items-center justify-center rounded-md duration-200 text-normal shadow-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2";
const PRIMARY_STYLE = "bg-primary-500 text-white shadow-sm font-semibold hover:bg-primary-700 border-transparent dark:hover:bg-primary-500 dark:hover:scale-[1.03]";
const SECONDARY_STYLE = "bg-primary-50 border border-transparent text-primary-600 font-bold shadow-sm hover:bg-primary-100";
const OUTLINED_STYLE = "border-gray-300 bg-white border text-gray-700 font-semibold shadow-sm hover:bg-gray-100";

// Size settings
const SIZE_XS = "px-2 py-1 text-xs rounded";
const SIZE_SM = "px-3 py-2 text-sm rounded-md";
const SIZE_MD = "px-4 py-2 text-base rounded-md";
const SIZE_LG = "px-8 py-3 text-md rounded-lg";
const SIZE_XL = "px-12 py-4 text-xl rounded-xl";

export default function SaveButton({ children, type = "button", buttonStyle = "primary", buttonSize = "md", disabled, className, isLoading, ...buttonProps }) {

  let styleClasses;
  let sizeClasses;

  //"inline-flex disabled:text-gray-400 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:border disabled:border-gray-300 items-center justify-center rounded-md border border-transparent bg-primary-500 px-4 py-2 text-normal font-bold text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2";

  // Set the button style [primary, secondary, outlined, danger, success, tertiary]
  switch (buttonStyle) {
    case "primary":
      styleClasses = PRIMARY_STYLE;
      break;
    case "outlined":
      styleClasses = OUTLINED_STYLE;
      break;
    case "secondary":
      styleClasses = SECONDARY_STYLE;
      break;
    default:
      styleClasses = PRIMARY_STYLE;
  }

  // Set the button size
  switch (buttonSize) {
    case "xs":
      sizeClasses = SIZE_XS;
      break;
    case "sm":
      sizeClasses = SIZE_SM;
      break;
    case "md":
      sizeClasses = SIZE_MD;
      break;
    case "lg":
      sizeClasses = SIZE_LG;
      break;
    case "xl":
      sizeClasses = SIZE_XL;
      break;
    default:
      sizeClasses = SIZE_MD;
  }

  let classNames = [BASE_STYLE, styleClasses, sizeClasses, className].join(" ");

  return (
    <>
      <button disabled={disabled} className={classNames} {...buttonProps}>
        {isLoading && (
          <span>
            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 inline text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Saving...
          </span>
        )}
        {!isLoading && children}
      </button>
    </>
  );
}
