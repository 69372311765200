 import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import Label from "./Label";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function FormDropdown(props) {
  const fromValueProp = props.values.find((elm) => elm.name == props.value);
  const [selected, setSelected] = useState(fromValueProp ? fromValueProp : props.values[0]);

  function onChange(event) {
    const selectedValue = props.values.find((elm) => elm.id == event.target.value);
    setSelected(selectedValue);
    props.onChange(selectedValue.name);
  }

  function getValueIndex(currentSelection) {
    let idx = 0;

    if(currentSelection) {
      props.values.forEach((value, i) => {
        if(value.name == currentSelection.name) {
          idx = i + 1;
        }
      });
    }

    return idx;
  }

  return (
    <Listbox >
      {({ open }) => (
        <>
          <div className="mb-1 block">
            <Label name={props.name} required={props.required} labelHelpToolTip={props.labelHelpToolTip}>
              {props.label}
            </Label>
          </div>
          <div className="relative mt-1">
            <select value={getValueIndex(selected)} onChange={onChange} id="sectionSelect" name={props.name} className="appearance-none mt-1 block w-full rounded-md bg-gray-100 border-gray-300 py-2 pl-3 pr-10 text-base focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm">
              {props.values.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </>
      )}
    </Listbox>
  );
}
