import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Dropdown(props) {
  const values = props.values;
  const [selected, setSelected] = useState(values[0]);

  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <Listbox.Label className="block hidden text-sm font-medium text-gray-700 mb-1">{props.label}</Listbox.Label>
          <div className="relative mt-1">
            <Listbox.Button className="relative w-full cursor-default rounded-md border hover:border-gray-400 duration-200 border-gray-300 bg-gray-100 py-2 pl-3 pr-10 text-left shadow-sm font-medium focus:outline-none hover:cursor-pointer sm:text-sm  dark:bg-slate-800 dark:text-white dark:border-slate-700 dark:placeholder:text-slate-500 dark:hover:border-gray-600">
              <span className="flex items-center">
                <img src={selected.avatar} alt="" className="h-6 w-6 hidden flex-shrink-0 rounded-full" />
                <span className="block truncate">{selected.name}</span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm dark:bg-slate-700 dark:text-white dark:border-slate-700 dark:placeholder:text-slate-500">
                {values.map((item) => (
                  <Listbox.Option key={item.id} className={({ active, selected }) => classNames(active ? "text-gray-900 bg-gray-100 dark:bg-slate-600 dark:text-gray-100" : "text-gray-800 dark:text-gray-200", selected && "bg-gray-100 text-primary-700", "relative cursor-pointer select-none py-2 pl-3 pr-9")} value={item}>
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <img src={item.avatar} alt="" className="h-6 w-6 hidden flex-shrink-0 rounded-full" />
                          <span className={classNames(selected ? "font-bold" : "font-normal", "block truncate")}>{item.name}</span>
                        </div>

                        {selected ? (
                          <span className={classNames(active ? "text-indigo-600" : "text-indigo-600", "absolute inset-y-0 right-0 flex items-center pr-4")}>
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
