import * as Fields from "./toolforms";
import {
  faAlignJustify,
  faArrowsFromLine,
  faArrowsRotate,
  faArrowsToEye,
  faArrowsToLine,
  faBarsFilter,
  faBlockQuote,
  faBriefcase,
  faBrowser,
  faBuilding,
  faBullseyeArrow,
  faBullseyePointer,
  faBusinessTime,
  faCamcorder,
  faCartShopping,
  faCircleEnvelope,
  faCommentsQuestion,
  faDiamond,
  faEnvelopeCircleCheck,
  faEnvelopeDot,
  faEnvelopeOpen,
  faEnvelopeOpenText,
  faEnvelopesBulk,
  faFeatherPointed,
  faFileMagnifyingGlass,
  faFilter,
  faFlag,
  faHandHoldingHeart,
  faHashtag,
  faHeading,
  faIdBadge,
  faImage,
  faKey,
  faLightbulbExclamationOn,
  faLightbulbOn,
  faLineColumns,
  faListOl,
  faListUl,
  faMessageQuote,
  faNewspaper,
  faParagraph,
  faPenFancy,
  faRankingStar,
  faRectangleList,
  faReplyClock,
  faShutters,
  faSpellCheck,
  faSquareList,
  faThumbsUp,
  faVial,
  faWindow,
  faWindowAlt,
} from "@fortawesome/pro-duotone-svg-icons";
import { faAmazon, faFacebook, faFacebookF, faGoogle, faInstagram, faLinkedin, faQuora, faTwitter, faTwitterSquare, faYoutube } from "@fortawesome/free-brands-svg-icons";

export function toolImage(toolName) {
  const tool = TOOLS.find((tool, idx) => tool.name === toolName);
  if (tool) {
    return tool.icon;
  } else {
    return faBriefcase;
  }
}

export const TOOLS = [
  {
    id: "article-generator",
    name: "Article Generator",
    title: "Generate Articles With AI",
    description: "Turn a title and outline into a long and engaging article.",
    descriptionLong: "Turn a description and an outline into a fully SEO-optimized and long article with this AI editor.",
    icon: faNewspaper,
    category: "blog",
    layout: "single",
    defaultQuantity: -1,
    formFields: Fields.FORM_FIELDS_ARTICLE_GENERATOR,
    isNew: true,
    url: "/article-generator",
  },
    // TODO fix this image-generator its not generating an image only a spot that says [object object]
  {
    id: "image-generator",
    name: "Image Generator",
    description: "Generate professional quality images from text for your website or blog.",
    icon: faImage,
    customIconColor: true,
    category: "more",
    isNew: true,
    topMessage: "This tool will deduct 50 word credits per image.",
    formFields: Fields.FORM_FIELDS_IMAGE_GENERATOR,
    hasQuantity: true,
    defaultQuantity: 2,
    maxQuantity: 4,
    showExampleButton: true,
    outputType: "images",
    url: "/image-generator",
  },
  // {
  //   id: "blog-writer",
  //   name: "Blog Writer",
  //   description: "Write a full blog section (few paragraphs) about a subheading of your article.",
  //   title: "Write quality blog posts with AI",
  //   descriptionLong: "Go from a blog idea to an engaging blog post in minutes by following the steps below.",
  //   category: "blog",
  //   layout: "steps",
  //   icon: faBrowser,
  //   formFields: Fields.FORM_FIELDS_BLOG_WRITER,
  //   favorite: true,
  //   url: "/blog-writer",
  //   hasVideo: true,
  //   youtubeEmbedId: "gUKu439keCw",
  // },
  {
    id: "blog-outline",
    name: "Blog Outlines",
    description: "Write the general idea of your next blog post. Outlining made easier and faster.",
    icon: faListUl,
    category: "blog",
    isNew: true,
    formFields: Fields.FORM_FIELDS_BLOG_OUTLINES,
    hasQuantity: true,
    defaultQuantity: 4,
    showExampleButton: true,
    url: "/blog-outline",
  },
  {
    id: "blog-ideas",
    name: "Blog Ideas",
    description: "The perfect tool to start writing great articles. Generate creative ideas for your next post.",
    icon: faLightbulbOn,
    category: "blog",
    isNew: true,
    formFields: Fields.FORM_FIELDS_BLOG_IDEAS,
    url: "/blog-ideas",
    hasQuantity: true,
    defaultQuantity: 4,
    showExampleButton: true,
  },
  {
    id: "rewrite-paragraph",
    name: "Content Rewriter",
    title: "Rewrite content with AI",
    layout: "single",
    description: "The smartest tool to rewrite articles or any content. The content is split into different sections that you can rewrite individually.",
    icon: faParagraph,
    category: "blog",
    formFields: Fields.FORM_FIELDS_ARTICLE_REWRITER,
    hasVideo: true,
    youtubeEmbedId: "I1Grv_SqNPY",
    defaultQuantity: -1,
    submitButtonLabel: "Start rewriting",
    isNew: true,
    url: "/rewrite-paragraph",
  },
  {
    id: "website-headlines",
    name: "Website Headlines",
    description: "Write engaging headlines for your Landing Page or any web page.",
    icon: faWindow,
    category: "website",
    formFields: Fields.FORM_FIELDS_WEBSITE_HEADLINES,
    hasQuantity: true,
    defaultQuantity: 4,
    outputPanelSubtitle: "Your headline is the first thing a reader sees. It must be creative, catchy, and converting. Just how Copymatic has been trained to write for you.",
    url: "/website-headlines",
  },
  // this is commented out because it requires it's own results/edit page
  //
  // {
  //   id: "landing-page",
  //   name: "Landing Page",
  //   title: "AI Landing Page",
  //   layout: "single",
  //   description: "Generate a complete landing page (copywriting and design) in seconds.",
  //   longDescription: "Our AI-powered landing page builder helps you create unique landing pages for your business in seconds — no writing, designing or coding required. This tool will deduct 1 credit per section.",
  //   icon: WindowIcon,
  //   category: "website",
  //   isBeta: true,
  //   isNew: true,
  //   formFields: Fields.FORM_FIELDS_LANDING_PAGE,
  //   url: "/landing-page",
  // },
  {
    id: "website-subheadlines",
    name: "Website Subheadlines",
    description: "Write engaging headlines for your Landing Page or any web page.",
    icon: faBrowser,
    category: "website",
    formFields: Fields.FORM_FIELDS_WEBSITE_SUBHEADERS,
    showExampleButton: false,
    outputPanelSubtitle: "Generate an additional headline that comes immediately after the main headline or title.",
    url: "/website-subheadlines",
  },
  // results valid
  {
    id: "social-media-post-business",
    name: "Social Media Post (Business)",
    description: "Write a post for your business to be published on any social media platform.",
    icon: faThumbsUp,
    category: "social",
    formFields: Fields.FORM_FIELDS_SOCIAL_MEDIA_POST_BUSINESS,
    url: "/social-media-post-business",
  },

  // results valid
  {
    id: "social-media-post-personal",
    name: "Social Media Post (Personal)",
    description: "Write a social media post for yourself to be published on any platform.",
    icon: faThumbsUp,
    category: "social",
    formFields: Fields.FORM_FIELDS_SOCIAL_MEDIA_POST_PERSONAL,
    url: "/social-media-post-personal",
  },
  // results valid
  {
    id: "instagram-captions",
    name: "Instagram Captions",
    description: "Grab attention with catchy captions for your Instagram posts.",
    icon: faInstagram,
    category: "social",
    formFields: Fields.FORM_FIELDS_INSTAGRAM_CAPTIONS,
    url: "/instagram-captions",
  },
  // results valid
  {
    id: "instagram-hashtag-generator",
    name: "Instagram Hashtags Generator",
    description: "Find the best hashtags to use for your Instagram posts.",
    icon: faHashtag,
    category: "social",
    formFields: Fields.FORM_FIELDS_INSTAGRAM_HASHTAG_GENERATOR,
    url: "/instagram-hashtag-generator",
    defaultQuantity: -1,
  },
  // results valid
  {
    id: "facebook-primary",
    name: "Facebook Primary Text",
    description: "Write Facebook ads that engage your audience and deliver a high conversion rate.",
    category: "social",
    icon: faFacebook,
    formFields: Fields.FORM_FIELDS_FACEBOOK_PRIMARY,
    url: "/facebook-primary",
  },
  // results valid
  {
    id: "facebook-headlines",
    name: "Facebook Headlines",
    description: "Write catchy and convincing headlines to make your Facebook Ads stand out",
    icon: faFacebookF,
    category: "social",
    formFields: Fields.FORM_FIELDS_FACEBOOK_HEADLINES,
    url: "/facebook-headlines",
  },
  // results valid - weird {news} tag though
  {
    id: "google-headlines",
    name: "Google Ads Headlines",
    description: "Write catchy 30-character headlines to promote your product with Google Ads.",
    icon: faGoogle,
    formFields: Fields.FORM_FIELDS_GOOGLE_HEADLINES,
    category: "social",
    url: "/google-headlines",
  },
  //results valid
  {
    id: "google-descriptions",
    name: "Google Ads Description",
    description: "Write a Google Ads description that makes your ad stand out and generates leads.",
    category: "social",
    icon: faGoogle,
    formFields: Fields.FORM_FIELDS_GOOGLE_DESCRIPTIONS,
    url: "/google-descriptions",
  },
  // results valid
  {
    id: "youtube-titles",
    name: "Video Titles",
    description: "Write a compelling YouTube video title to catch people's attention.",
    category: "social",
    icon: faYoutube,
    formFields: Fields.FORM_FIELDS_YOUTUBE_TITLES,
    url: "/youtube-titles",
  },
  // results valid
  // {
  //   id: "youtube-descriptions",
  //   name: "Video Descriptions",
  //   description: "Write compelling YouTube descriptions to get people interested in your video.",
  //   descriptionLong: "Write a creative, enticing description of the content you're about to show on your Youtube video to make it more attractive to viewers and make sure they'll want to watch it.",
  //   icon: faYoutube,
  //   category: "social",
  //   formFields: Fields.FORM_FIELDS_YOUTUBE_DESCRIPTIONS,
  //   url: "/youtube-descriptions",
  // },
  // results valid
  {
    id: "youtube-tag-generator",
    name: "Youtube Tags Generator",
    description: "Generate SEO-optimized YouTube tags / keywords for your video.",
    icon: faYoutube,
    category: "social",
    formFields: Fields.FORM_FIELDS_YOUTUBE_TAG_GENERATOR,
    url: "/youtube-tag-generator",
    defaultQuantity: -1,
  },
  // service saturated
  {
    id: "twitter-threads",
    name: "Twitter Threads",
    description: "Easily create long, engaging threads on Twitter about a topic without all the hassle.",
    icon: faTwitter,
    category: "social",
    formFields: Fields.FORM_FIELDS_TWITTER_THREADS,
    url: "/twitter-threads",
    defaultQuantity: -1,
  },
  // results valid
  {
    id: "tweets",
    name: "Tweet Generator",
    description: "Don't know what to tweet? Get ideas for tweets that will engage your followers.",
    icon: faTwitterSquare,
    category: "social",
    formFields: Fields.FORM_FIELDS_TWEETS,
    url: "/tweets",
  },
  // results valid - as html
  {
    id: "linkedin-posts",
    name: "LinkedIn Posts",
    description: "Create content for your LinkedIn profile that will help you stand out from the crowd.",
    icon: faLinkedin,
    category: "social",
    formFields: Fields.FORM_FIELDS_LINKEDIN_POST,
    url: "/linkedin-posts",
  },
  // results valid
  {
    id: "personal-bio",
    name: "Personal Bio",
    description: "Write creative and engaging bios for your social media handles.",
    icon: faIdBadge,
    category: "social",
    isBeta: true,
    formFields: Fields.FORM_FIELDS_PERSONAL_BIO,
    hasQuantity: true,
    defaultQuantity: 2,
    showExampleButton: true,
    url: "/personal-bio",
  },
  // quora-answers
  {
    id: "quora-answers",
    name: "Quora Answers",
    description: "Generate creative answers based on a question from Quora.",
    descriptionLong: "Quora is an excellent way to drive additional traffic to your website by answering questions, use this tool to generate answers ready to be copied.",
    icon: faQuora,
    category: "social",
    formFields: Fields.FORM_FIELDS_QUORA_ANSWERS,
    url: "/quora-answers",
  },
  // results valid
  {
    id: "value-proposition",
    name: "Value Proposition",
    description: "Write a compelling line about what your product is and why your prospects need it.",
    descriptionLong: "How do you get someone to click on your ad in Facebook or on a Google ad? More than anything else, you need to write a message that entices the reader to click.",
    icon: faDiamond,
    category: "marketing",
    formFields: Fields.FORM_FIELDS_VALUE_PROPOSITION,
    url: "/value-proposition",
  },
  // results valid
  {
    id: "feature-to-benefit",
    name: "Feature To Benefit",
    description: "A feature is what something is, and a benefit is what users can do or accomplish with it.",
    descriptionLong: "How do you get someone to click on your ad in Facebook or on a Google ad? More than anything else, you need to write a message that entices the reader to click.",
    icon: faHandHoldingHeart,
    category: "marketing",
    formFields: Fields.FORM_FIELDS_FEATURES_TO_BENEFIT,
    url: "/feature-to-benefit",
  },
  // results valid
  {
    id: "meta-titles",
    name: "Meta Titles (URL)",
    description: "Input a URL to generate SEO-optimized meta titles, higher CTR guaranteed.",
    descriptionLong: "Generate the perfect meta title for your page, this will give you a more SEO-friendly title that will rank better and get a higher CTR.",
    icon: faHeading,
    category: "copywriting",
    formFields: Fields.FORM_FIELDS_META_TITLES,
    url: "/meta-titles",
  },
  // results valid
  {
    id: "meta-descriptions-url",
    name: "Meta Descriptions (URL)",
    description: "Generate SEO-optimized meta descriptions based on the content of a URL.",
    descriptionLong: "Meta descriptions appear in search engine result pages, making it an essential part of your on-page SEO. The AI can generate a good description meta tag that clearly describes the web page's content to the search engines while also displaying a human friendly version that makes your web page stand out.",
    icon: faWindowAlt,
    category: "copywriting",
    formFields: Fields.FORM_FIELDS_META_DESCRIPTIONS_URL,
    url: "/meta-descriptions-url",
  },
  // valid results
  {
    id: "faq",
    name: "FAQs",
    description: "Generate frequently asked questions based on your product description.",
    descriptionLong: "It can be time consuming to think about questions that your visitors might have. Copymatic generates them for you in seconds.",
    category: "copywriting",
    icon: faCommentsQuestion,
    formFields: Fields.FORM_FIELDS_FAQ,
    url: "/faq",
  },
  // results valid
  {
    id: "faq-answers",
    name: "FAQ Answers",
    description: "Write answers to frequently asked questions about your product.",
    icon: faCommentsQuestion,
    category: "social",
    isNew: true,
    isBeta: true,
    formFields: Fields.FORM_FIELDS_FAQ_ANSWERS,
    url: "/faq-answers",
  },
  // results valid
  {
    id: "product-descriptions",
    name: "Product Descriptions",
    description: "Write the description about your product and why it's worth purchasing.",
    icon: faRectangleList,
    category: "social",
    formFields: Fields.FORM_FIELDS_PRODUCT_DESCRIPTIONS,
    url: "/product-descriptions",
  },

  // results valid
  // {
  //   id: "subheading-paragraph",
  //   name: "Blog Paragraph Writer", // should this be Subheading Paragraph?
  //   description: "Write original and human-like paragraphs based on your blog subheading (h2, h3, h4,...).",
  //   descriptionLong: "Write paragraphs about your blog bullet points (or subheadings) in seconds.",
  //   icon: faAlignJustify,
  //   category: "blog",
  //   formFields: Fields.FORM_FIELDS_SUBHEADING_PARAGRAPH,
  //   url: "/subheading-paragraph",
  // },

  // results valid - has html
  {
    id: "paragraph-generator",
    name: "Paragraph Generator",
    description: "Generate paragraphs about any topic including a keyword and in a specific tone of voice.",
    icon: faParagraph,
    category: "blog",
    formFields: Fields.FORM_FIELDS_PARAGRAPH_GENERATOR,
    url: "/paragraph-generator",
  },
  // throws TypeError
  // TypeError: Cannot read properties of undefined (reading 'match')
  {
    id: "blog-sections",
    name: "Blog Section",
    description: "Write a full blog section (few paragraphs) about a subheading of your article.",
    icon: faBlockQuote,
    category: "blog",
    formFields: Fields.FORM_FIELDS_BLOG_SECTIONS,
    url: "/blog-sections",
  },
  // valid results
  {
    id: "blog-titles",
    name: "Blog Titles",
    description: "Nobody wants to read boring blog titles, generate catchy blog titles with this tool.",
    icon: faHeading,
    category: "blog",
    formFields: Fields.FORM_FIELDS_BLOG_TITLES,
    url: "/blog-titles",
  },
  // valid results
  {
    id: "blog-titles-listicles",
    name: "Blog Titles (Listicles)",
    description: "Automatically generate engaging listicle headlines, the most effective type of title.",
    descriptionLong: "A listicle blog title is a short-form of blog writing that uses a list as its thematic structure, it's the most effective type of content out there.",
    icon: faSquareList,
    category: "blog",
    formFields: Fields.FORM_FIELS_BLOG_TITLES_LISTICLES,
    url: "/blog-titles-listicles",
  },
  // valid results
  {
    id: "blog-conclusions",
    name: "Blog Conclusions",
    description: "End your blog articles with an engaging conclusion paragraph.",
    icon: faFlag,
    category: "blog",
    formFields: Fields.FORM_FIELDS_BLOG_CONCLUSIONS,
    url: "/blog-conclusions",
  },
  // valid results -> result set needs formatting (numbered list). has html.
  {
    id: "talking-points",
    name: "Talking Points",
    description: "Write short, simple and informative points for the subheadings of your article.",
    category: "blog",
    icon: faListOl,
    formFields: Fields.FORM_FIELDS_TALKING_POINTS,
    url: "/talking-points",
  },
  // valid results -> result set needs formatting / result set has html
  {
    id: "pros-cons",
    name: "Pros & Cons",
    description: "Write the pros and cons of a product, service or website for your blog article.",
    category: "blog",
    icon: faLineColumns,
    formFields: Fields.FORM_FIELDS_PROS_CONS,
    url: "/pros-cons",
  },
  // valid results -> results set needs formatting
  {
    id: "ecommerce-product-descriptions",
    name: "Ecommerce Product Descriptions",
    description: "Write a unique, creative, and strategic description that's designed to increase sales.",
    descriptionLong: "When describing your products, remember to focus on your ideal buyer, emphasize benefits instead of features, avoid filler copy, and cut through rational barriers with mini-stories.",
    icon: faSquareList,
    category: "ecommerce",
    formFields: Fields.FORM_FIELDS_ECOM_PRODUCT_DESCRIPTIONS,
    url: "/ecommerce-product-descriptions",
  },
  // valid results
  {
    id: "ecommerce-category-descriptions",
    name: "Ecommerce Category Descriptions",
    description: "Generate SEO-optimized descriptions for your store categories.",
    descriptionLong: "Briefly describe your business and what your store is selling. For the audience remember to focus on your ideal buyer.",
    icon: faCartShopping,
    category: "ecommerce",
    formFields: Fields.FORM_FIELDS_ECOM_CATEGORY_DESCRIPTIONS,
    url: "/ecommerce-category-descriptions",
  },
  // valid results
  {
    id: "ecommerce-product-names",
    name: "Ecommerce Product Names",
    description: "Generate a creative and catchy name for your eCommerce products in seconds.",
    descriptionLong: "Get fresh new content without having to write it yourself! Our AI-driven tool automatically rewrites any sentence based on your input to ensure fresh and original content every time.",
    formFields: Fields.FORM_FIELDS_ECOM_PRODUCT_NAMES,
    icon: faCartShopping,
    category: "ecommerce",
    url: "/ecommerce-product-names",
  },
  // valid results
  {
    id: "rewrite-with-keyword",
    name: "Rewrite With Keyword",
    description: "Rewrite the text and include your targeted keyword in the new text (beta).",
    descriptionLong: 'Input any keyword (1 max.) and it will automatically rewrite the text to include the keyword. Please note that you <strong>get better results everytime you click on "Generate More"</strong> as the AI learns about what you are trying to rewrite.<br><strong>Not guaranteed to include the keyword, regenerate if that\'s not the case.</strong>',
    icon: faKey,
    category: "more",
    formFields: Fields.FORM_FIELDS_REWRITE_WITH_KEYWORD,
    url: "/rewrite-with-keyword",
  },
  // valid results
  {
    id: "sentence-expander",
    name: "Sentence Expander",
    description: "Turn a few words or a short sentence into a longer and more engaging one.",
    icon: faArrowsFromLine,
    category: "more",
    formFields: Fields.FORM_FIELDS_SENTENCE_EXPANDER,
    url: "/sentence-expander",
    isNew: true,
  },
  // valid results
  {
    id: "tldr",
    name: "Text Summarizer (TL;DR)",
    description: "Summmarize any text in a short and easy to understand paragraph.",
    descriptionLong: "TLDR is commonly used to summarize lengthy content and can signal a sort of executive summary at the start of articles, emails or SEO meta descriptions.",
    icon: faArrowsToLine,
    category: "more",
    formFields: Fields.FORM_FIELDS_TLDR,
    url: "/tldr",
  },
  // valid results
  {
    id: "startup-name-generator",
    name: "Startup Name Generator",
    description: "Generate cool, creative, and catchy names for your startup in seconds.",
    icon: faLightbulbExclamationOn,
    category: "marketing",
    formFields: Fields.FORM_FIELDS_STARTUP_NAME_GENERATOR,
    url: "/startup-name-generator",
  },
  // valid results
  {
    id: "mission-statement",
    name: "Mission Statement",
    description: "Bring your vision to light! Write a short and concise mission statement for your company.",
    icon: faBullseyeArrow,
    category: "marketing",
    formFields: Fields.FORM_FIELDS_MISSION_STATEMENT,
    url: "/mission-statement",
  },

  // valid results
  {
    id: "vision-statement",
    name: "Vision Statement",
    description: "Write a vision statement that will articulate what your company would like to achieve.",
    icon: faArrowsToEye,
    category: "marketing",
    formFields: Fields.FORM_FIELDS_VISION_STATEMENT,
    url: "/vision-statement",
  },
  // valid results
  {
    id: "review-responder",
    name: "Review Responder",
    description: "Your new customer support favorite tool. Write responses to public customer reviews.",
    icon: faRankingStar,
    category: "more",
    formFields: Fields.FORM_FIELDS_REVIEW_RESPONDER,
    url: "/review-responder",
  },
  // valid results
  {
    id: "grammar-rewriter",
    name: "Grammar Rewriter",
    description: "Make sure your sentences are written in perfect American Standard English.",
    descriptionLong: "There's nothing worse than making mistakes with grammar and spelling in a webpage or blog post- use Copymatic to check your spelling!",
    icon: faSpellCheck,
    category: "more",
    formFields: Fields.FORM_FIELDS_GRAMMAR_REWRITER,
    url: "/grammar-rewriter",
    defaultQuantity: -1,
  },
  // throws TypeError
  {
    id: "stories",
    name: "Creative Stories",
    description: "Looking for a creative boost? This tool is perfect for writers to come up with new story ideas.",
    icon: faFeatherPointed,
    category: "more",
    formFields: Fields.FORM_FIELDS_STORIES,
    url: "/stories",
  },
  // valid results -> html
  {
    id: "essay-outlines",
    name: "Essay Outlines",
    category: "others",
    description: "Generate the structure or talking points of your essay before you start writing.",
    descriptionLong: "Essay outlines involve writing quick summary sentences or phrases for every point you will cover in each paragraph, giving you a picture of how your essay will unfold.",
    icon: faListUl,
    formFields: Fields.FORM_FIELDS_ESSAY_OUTLINES,
    url: "/essay-outlines",
  },
  // valid results
  {
    id: "project-text-proposal",
    name: "Project Text Proposal",
    description: "A tool designed to help freelancers save time when applying to projects.",
    icon: faBusinessTime,
    formFields: Fields.FORM_FIELDS_PROJECT_TEXT_PROPOSAL,
    category: "more",
    url: "/project-text-proposal",
  },
  // valid results
  {
    id: "job-descriptions",
    name: "Job Descriptions",
    description: "Write a tailored and professional job description that will help you attract top talents.",
    icon: faBriefcase,
    formFields: Fields.FORM_FIELDS_JOB_DESCRIPTIONS,
    category: "more",
    url: "/job-descriptions",
    isNew: true,
  },
  // valid results
  {
    id: "blog-intros",
    name: "Blog Intros",
    description: "Write an intro that will entice your visitors to read more about your article.",
    descriptionLong: "Blog intros are the first part of your article, therefore you should make sure to quickly catch your readers attention. Let Copymatic write captivating blog intros for your next article.",
    icon: faShutters,
    category: "blog",
    formFields: Fields.FORM_FIELDS_BLOG_INTROS,
    url: "/blog-intros",
  },

  // IDK ABOUT THIS ONE BOB
  // "write-for-me"=> array(
  //   'title' => "Write for me",
  //   'mini_description' => "Generate coherent text from partial sentences.",
  //   'description' => "End writer's block today with this text completion tool. Best used for blog or website content.",
  //   'icon' => $icons['pen_writer'],
  //   'price' => 1,
  //   'fields' => array($fields['creativity_full_options'],$fields['content'])
  // ),

  // {
  //   id: "write-for-me",
  //   name: "Write for me",
  //   description: "Generate coherent text from partial sentences.",
  //   descriptionLong: "End writer's block today with this text completion tool. Best used for blog or website content.",
  //   icon: ChartBarIcon,
  //   category: "idk",
  //   formFields: Fields.FORM_FIELDS_WFM,
  // },
  // valid results -> result set needs data inserted
  {
    id: "pain-agitate-solution",
    name: "Pain-Agitate-Solution",
    description: "Generate one of most effective copywriting formula for your business.",
    descriptionLong: "Problem: Identify your reader's pain point. Agitate: Stir it up so it hurts to the point of discomfort. Solve: Deliver a solution.",
    icon: faVial,
    category: "copywriting",
    formFields: Fields.FORM_FIELDS_PAS,
    url: "/pain-agitate-solution",
  },
  // valid results -> result set needs data inserted
  {
    id: "attention-interest-desire-action",
    name: "AIDA Formula",
    description: "Generate the oldest copywriting formula used by the biggest brands.",
    descriptionLong: "Attention: Grab the attention of readers and hook them in like a fish. Interest: Create a point of interest for them to continue reading. Desire: Display a benefit which creates desire. Action: Finish it with a call to action.",
    icon: faFilter,
    category: "copywriting",
    formFields: Fields.FORM_FIELDS_AIDA,
    url: "/attention-interest-desire-action",
  },
  // valid results -> result set needs data inserted
  {
    id: "quest",
    name: "QUEST Formula",
    description: "Write a Qualify-Understand-Educate-Stimulate-Transition copy.",
    descriptionLong: "As if your readers are going “on a quest” so to speak, it’s the process your prospects go through when reading your sales copy. This formula guides people as they progress through your copy until they take action.",
    icon: faBarsFilter,
    category: "copywriting",
    formFields: Fields.FORM_FIELDS_QUEST,
    url: "/quest",
  },
  // valid results
  {
    id: "testimonials",
    name: "Testimonials / Reviews",
    description: "Add social proof to your website by generating user testimonials.",
    descriptionLong: "The perfect tool for any new business, add social proof to your website by generating user testimonials.",
    icon: faMessageQuote,
    category: "copywriting",
    formFields: Fields.FORM_FIELDS_TESTIMONIALS,
    url: "/testimonials",
  },
  // valid results
  {
    id: "how-it-works",
    name: "How It Works",
    description: "Write 3 detailed steps about how your product or business works.",
    icon: faListOl,
    category: "copywriting",
    formFields: Fields.FORM_FIELDS_HOW_IT_WORKS,
    url: "/how-it-works",
  },
  // valid results
  {
    id: "about-us",
    name: "About Us",
    description: "Generate content that is unique and creative to represent your business on your about us page.",
    descriptionLong: "An About Us page helps your company make a good first impression, and is critical for building customer trust and loyalty. Please give as many details as possible about your company for better results. Regenerate if you are not satisfied with the outputs.",
    icon: faBuilding,
    category: "copywriting",
    formFields: Fields.FORM_FIELDS_ABOUT_US,
    url: "/about-us",
  },
  // valid results
  {
    id: "cta",
    name: "Call To Action",
    description: "Generate CTA lines (or hooks) about your business for your visitors to take action.",
    descriptionLong: "CTA stands for call to action, and it's the part of a webpage, advertisement, or piece of content that encourages the audience to do something. In marketing, CTAs help a business convert a visitor, or reader into a lead for the sales team.",
    icon: faBullseyePointer,
    category: "copywriting",
    formFields: Fields.FORM_FIELDS_CTA,
    url: "/cta",
  },
  // valid results
  {
    id: "press-releases",
    name: "Press Releases",
    description: "Write a short and compelling press release (news story) to be sent to targeted media.",
    icon: faNewspaper,
    category: "marketing",
    formFields: Fields.FORM_FIELDS_PRESS_RELEASES,
    url: "/press-releases",
  },
  // valid results
  {
    id: "video-script-intros",
    name: "Video Script Intros",
    description: "Write an engaging video intro to hook your audience in the first few seconds.",
    descriptionLong: "To help hook your audience in a video script, use the very first few lines to introduce the narrator (the person on screen) and what the audience is going to learn or accomplish by the end of the video.",
    icon: faCamcorder,
    category: "marketing",
    formFields: Fields.FORM_FIELDS_VIDEO_SCRIPT_INTROS,
    url: "/video-script-section",
  },
  // valid results
  {
    id: "video-script-section",
    name: "Video Script Section",
    description: "Generate content about a section of your video script based on the subtitle.",
    descriptionLong: "A good script makes it easy for the people on camera to get their messages across while sounding and acting naturally.",
    icon: faCamcorder,
    category: "marketing",
    formFields: Fields.FORM_FIELDS_VIDEO_SCRIPT_SECTION,
    url: "/video-script-section",
  },
  // valid results
  {
    id: "newsletters",
    name: "Newsletters",
    description: "Write compelling, engaging and informative content that your subscribers will love.",
    icon: faEnvelopeOpenText,
    category: "marketing",
    formFields: Fields.FORM_FIELDS_NEWSLETTER,
    url: "/newsletters",
  },
  // valid results
  {
    id: "cold-email",
    name: "Cold Email",
    description: "Introduce your company by reaching out to a lead based on your goal.",
    icon: faEnvelopesBulk,
    category: "marketing",
    formFields: Fields.FORM_FIELDS_COLD_EMAIL,
    url: "/cold-email",
  },
  // valid results
  {
    id: "follow-up-email",
    name: "Follow-Up Email",
    description: "Write an email to engage with your leads in response to an action: free trial, meeting, call,...",
    descriptionLong: "A follow-up email is an email or sequence of emails sent in response to the actions of subscribers. It stimulates them to choose a paid plan instead of a free trial, set up a meeting for B2B, leave feedback, buy another product on an e-commerce website, etc.",
    icon: faReplyClock,
    category: "marketing",
    formFields: Fields.FORM_FIELDS_FOLLOW_UP_EMAIL,
    url: "/follow-up-email",
  },
  // valid results
  {
    id: "welcome-email",
    name: "Welcome Email",
    description: "Generate the first email you send to new subscribers, customers or users.",
    descriptionLong: "A welcome email is one of the more important types of emails you'll send to new subscribers or customers.",
    icon: faEnvelopeDot,
    category: "marketing",
    formFields: Fields.FORM_FIELDS_WELCOME_EMAIL,
    url: "/welcome-email",
  },
  // valid results
  {
    id: "cancellation-email",
    name: "Cancellation Email",
    description: "Cancellation emails are triggered by a user’s refusal to prolong a subscription. Although some people easily let their customers go, still the best practices encourage seizing every opportunity to bring them back since it is much more cost-effective to get the old customer back than onboard a new one.",
    icon: faCircleEnvelope,
    category: "marketing",
    formFields: Fields.FORM_FIELDS_CANCELLATION_EMAIL,
    url: "/cancellation-email",
  },
  // valid results
  {
    id: "confirmation-email",
    name: "Confirmation Email",
    description: "Confirmation emails are the messages you send to reassure a user after they've signed up for a service, purchased your product or updated their profile information.",
    icon: faEnvelopeCircleCheck,
    category: "marketing",
    formFields: Fields.FORM_FIELDS_CONFIRMATION_EMAIL,
    url: "/confirmation-email",
  },
  // invalid results
  {
    id: "email-subject-lines",
    name: "Email Subject Lines",
    description: "Generate catchy and optimized email subject lines for your business.",
    category: "marketing",
    icon: faEnvelopeOpen,
    formFields: Fields.FORM_FIELDS_EMAIL_SUBJECT_LINES,
    url: "/email-subject-lines",
  },
  // valid results
  {
    id: "ecommerce-product-features",
    name: "Ecommerce Product Description Bullet Points",
    description: "Generate a list of features or bullet points from a product description.",
    icon: faListUl,
    category: "ecommerce",
    formFields: Fields.FORM_FIELDS_PRODUCT_DESCRIPTIONS,
    url: "/ecommerce-product-features",
  },
  // throws error: TypeError page_type.replace is not a function
  {
    id: "meta-descriptions",
    name: "Meta Descriptions",
    description: "Write engaging headlines for your Landing Page or any web page.",
    icon: faFileMagnifyingGlass,
    category: "more",
    formFields: Fields.FORM_FIELDS_META_DESCRIPTIONS,
    url: "/meta-descriptions",
  },
  // valid results
  {
    id: "sentence-rewriter",
    name: "Sentence Rewriter",
    description: "Rewrite sentences or paragraphs in ways that will help you increase conversions.",
    icon: faArrowsRotate,
    category: "more",
    formFields: Fields.FORM_FIELDS_SENTENCE_REWRITER,
    url: "/sentence-rewriter",
  },
  // valid results
  {
    id: "essay-intros",
    name: "Essay Intros",
    description: "Write an introduction to give your readers a clear idea of what your essay will cover.",
    icon: faPenFancy,
    category: "more",
    formFields: Fields.FORM_FIELDS_ESSAY_INTROS,
    url: "/essay-intros",
  },
  // valid results
    // throws error invalid amazon url
  // {
  //   id: "amazon-product-descriptions",
  //   name: "Amazon Product Descriptions",
  //   description: "A special and exclusive tool for Amazon affiliates. Simply enter the URL of the Amazon product that you would like to generate a description about.",
  //   icon: faAmazon, // amazon icon
  //   category: "ecommerce",
  //   formFields: [
  //     {
  //       order: 1,
  //       type: "input",
  //       name: "amazon_product_url",
  //       label: "Amazon Product Url",
  //       required: true,
  //       colSpan: 2,
  //       placeholder: "https://amazon.com/...",
  //     },
  //   ],
  //   url: "/amazon-product-descriptions",
  // },
  // {
  //   id: "smart-editor",
  //   name: "Smart Editor",
  //   description: "A tool of tools.",
  //   category: "other",
  //   url: "/smart-editor",
  // },
];

export default TOOLS;
