import { useState } from "react";
import Button from "../Components/ui/Button";
import Notification, { Color } from "../notify/Notification";
import { info, success, warning, error } from "../notify/Notify";
import ModalAnyContent from "../Components/ui/ModalAnyContent";

export default function DevButtonOverview() {
  const [modalOpen, setModalOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const createNotification = (color) => setNotifications([...notifications, { color, id: notifications.length }]);
  const deleteNotification = (id) => setNotifications(notifications.filter((notification) => notification.id !== id));
  const message = "This is a notification!";

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  function openModal() {
    setModalOpen(true);
  }

  function closeModal() {
    console.log("closeModal");
    setModalOpen(false);
  }

  const getColorsForCategory = (category) => {
    switch (category) {
      case "blog":
        return {
          background: "#E3FBF3",
          primary: "#20AF7D",
          secondary: "#67E5A4",
        };
      case "website":
        return {
          background: "#E4F4FE",
          primary: "#0D74CC",
          secondary: "#66B9F8",
        };
      case "social":
        return {
          background: "#E7E5FE",
          primary: "#3E37E5",
          secondary: "#B3ABFB",
        };
      case "marketing":
        return {
          background: "#FFF2E9",
          primary: "#FA7937",
          secondary: "#FFAB7A",
        };
      case "more":
        return {
          background: "#FAEDF1",
          primary: "#CD5178",
          secondary: "#E5ABBE",
        };
      case "more2":
        return {
          background: "#F2FEDF",
          primary: "#57823C",
          secondary: "#8EB648",
        };
      case "special":
        return {
          background: "#FBE6C7",
          primary: "#664D2A",
          secondary: "#AE8E60",
        };
      default:
        return {
          background: "#F5F5F5",
          primary: "#525252",
          secondary: "#B0B0B0",
        };
    }
  };

  const colors = getColorsForCategory("special");
  const { background, primary, secondary } = colors;

  const backgroundColor = background;
  const primaryColor = primary;
  const secondaryColor = secondary;

  return (
    <div>
      <ModalAnyContent open={modalOpen} onClose={() => toggleModal()}>
        <div className="text-center pt-8 px-4 pb-4">
          <svg className="mx-auto mb-8" width="80" height="35" viewBox="0 0 167 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.5335 14.3589H20.6409C16.6758 14.3589 13.4614 17.5733 13.4614 21.5384V49.3589C13.4614 53.3241 16.6758 56.5384 20.6409 56.5384H32.5681L22.5335 14.3589Z" fill="#2E16E6" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M139.103 0H17.9487C8.03592 0 0 8.03591 0 17.9487V52.0513C0 61.9641 8.03591 70 17.9487 70H139.103C149.015 70 157.051 61.9641 157.051 52.0513V48.4615H162.436C164.418 48.4615 166.026 46.8544 166.026 44.8718V26.0256C166.026 24.0431 164.418 22.4359 162.436 22.4359H157.051V17.9487C157.051 8.03592 149.015 0 139.103 0ZM8.97436 17.9487C8.97436 12.9923 12.9923 8.97436 17.9487 8.97436H139.103C144.059 8.97436 148.077 12.9923 148.077 17.9487V52.0513C148.077 57.0077 144.059 61.0256 139.103 61.0256H17.9487C12.9923 61.0256 8.97436 57.0077 8.97436 52.0513V17.9487Z" fill="#353535" />
          </svg>

          <h3 className="text-2xl font-bold leading-6 mb-8 text-gray-900">Insufficient Credits</h3>
          <p className="mb-8 text-gray-700">Sorry, it seems that you don't have enough credits to perform this action. Don't worry, you can easily purchase more credits to continue using our service.</p>
          <Button onClick={(event) => openModal(event)} buttonSize="lg">
            Purchase More Credits
          </Button>

          <div className="pt-8">
            <button className="font-bold text-gray-500 hover:underline" onClick={() => closeModal()}>
              Close
            </button>
          </div>
        </div>
      </ModalAnyContent>

      <div className="mx-auto max-w-7xl w-full px-4 sm:px-6 md:px-8 ">
        <div className="w-full">
          {/* ----------------------------------------------------------------------------------------------*/}
          <div className="mx-auto max-w-7xl w-full px-4 sm:px-6 md:px-8 ">
            <div className="mx-auto max-w-7xl pb-8 px-4 pt-2 sm:pb-8 sm:px-6 lg:px-8">
              <div className="text-center">
                <h1 className="mt-1 text-xl font-extrabold tracking-tight mb-8 text-gray-900 sm:text-xl lg:text-4xl">Modal Trigger</h1>
                <Button onClick={(event) => openModal(event)} buttonSize="lg">
                  Open Info Modal
                </Button>
              </div>
            </div>
          </div>

          {/* ----------------------------------------------------------------------------------------------*/}
          <div className="mx-auto max-w-7xl w-full px-4 sm:px-6 md:px-8 ">
            <div className="mx-auto max-w-7xl pb-8 px-4 pt-2 sm:pb-8 sm:px-6 lg:px-8">
              <div className="text-center">
                <h1 className="mt-1 text-xl font-extrabold tracking-tight text-gray-900 sm:text-xl lg:text-4xl">List of different buttons</h1>
                <p className="mx-auto mt-5 max-w-xl font-semibold text-base text-gray-700">Find all your articles and any saved content below.</p>
              </div>
            </div>
          </div>
          {/* ----------------------------------------------------------------------------------------------*/}
          <div className="mx-auto max-w-7xl w-full px-4 sm:px-6 md:px-8 ">
            <div className="mx-auto max-w-7xl pb-8 px-4 pt-2 sm:pb-8 sm:px-6 lg:px-8">
              <div className="text-center">
                <h1 className="mt-1 text-xl font-extrabold tracking-tight text-gray-900 sm:text-xl lg:text-4xl">Icon demo</h1>
                <div
                  style={{
                    "--background-color": backgroundColor,
                    "--primary-color": primaryColor,
                    "--secondary-color": secondaryColor,
                  }}
                  className="text-center justify-center flex border-4 p-8"
                >
                  <svg id="bold" enable-background="new 0 0 32 32" height="512" viewBox="0 0 32 32" width="512" xmlns="http://www.w3.org/2000/svg">
                    <path d="m26 32h-20c-3.314 0-6-2.686-6-6v-20c0-3.314 2.686-6 6-6h20c3.314 0 6 2.686 6 6v20c0 3.314-2.686 6-6 6z" fill="#f5f5f5" class="icon__background" />
                    <path d="m14.667 14.5c-.735 0-1.333.598-1.333 1.333v2.167c0 .276.224.5.5.5s.5-.224.5-.5v-.5h.666v.5c0 .276.224.5.5.5s.5-.224.5-.5v-2.167c0-.735-.598-1.333-1.333-1.333zm.333 2h-.667v-.667c0-.184.149-.333.333-.333s.333.15.333.333v.667z" class="icon__primary" />
                    <path d="m22.167 11.167h-2.833v-1.334c-.001-.735-.599-1.333-1.334-1.333h-4c-.735 0-1.333.598-1.333 1.333v1.333h-2.834c-1.011.001-1.833.823-1.833 1.834v7.667c0 1.011.822 1.833 1.833 1.833h12.333c1.011 0 1.833-.822 1.833-1.833v-7.667c.001-1.011-.821-1.833-1.832-1.833zm-8.167-1.334h4v1.333h-4zm8.667 10.834c0 .276-.224.5-.5.5h-12.334c-.276 0-.5-.224-.5-.5v-7.667c0-.276.224-.5.5-.5h12.333c.276 0 .5.224.5.5v7.667z" class="icon__secondary" />
                    <path d="m18.333 18c0 .276-.224.5-.5.5s-.5-.224-.5-.5v-3c0-.276.224-.5.5-.5s.5.224.5.5z" class="icon__primary" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          {/* ----------------------------------------------------------------------------------------------*/}
          {/* ----------------------------------------------------------------------------------------------*/}

          {/* ----------------------------------------------------------------------------------------------*/}
          <div className="mx-auto max-w-7xl w-full px-4 sm:px-6 md:px-8 ">
            <div className="mx-auto max-w-7xl pb-8 px-4 pt-2 sm:pb-8 sm:px-6 lg:px-8">
              <div className="text-center">
                <h1 className="mt-1 text-xl font-extrabold tracking-tight text-gray-900 sm:text-xl lg:text-4xl">Notification demo</h1>
                <button onClick={() => createNotification(Color.info)}>Info</button>
                <button onClick={() => createNotification(Color.success)}>Success</button>
                <button onClick={() => createNotification(Color.warning)}>Warning</button>
                <button onClick={() => createNotification(Color.error)}>Error</button>
                {notifications.map(({ id, color }) => (
                  <Notification key={id} color={color} autoClose={true} onDelete={() => deleteNotification(id)}>
                    This is a notification!
                  </Notification>
                ))}
              </div>
            </div>
          </div>
          {/* ----------------------------------------------------------------------------------------------*/}
          {/* ----------------------------------------------------------------------------------------------*/}
          <div className="mx-auto max-w-7xl w-full px-4 sm:px-6 md:px-8 ">
            <div className="mx-auto max-w-7xl pb-8 px-4 pt-2 sm:pb-8 sm:px-6 lg:px-8">
              <div className="text-center">
                <h1 className="mt-1 text-xl font-extrabold tracking-tight text-gray-900 sm:text-xl lg:text-4xl">Imperative demo</h1>
                <div className="flex justify-center space-x-4 py-6">
                  <Button onClick={() => info(message, true)} buttonSize="xs">
                    Info
                  </Button>
                  <Button onClick={() => success(message, true)} buttonSize="xs">
                    Success
                  </Button>
                  <Button onClick={() => warning(message, true)} buttonSize="xs">
                    Warning
                  </Button>
                  <Button onClick={() => error(message, true)} buttonSize="xs">
                    Error
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {/* ----------------------------------------------------------------------------------------------*/}
          <div>
            <h1 className="text-3xl text-primary-500 text-center mb-8 my-theme:italic">No my-theme parent: ITALIC TEST</h1>
          </div>
          <div className="dark">
            <h1 className="text-3xl text-primary-500 text-center mb-8 my-theme:italic">My-theme parent: ITALIC TEST</h1>
          </div>
          {/* ----------------------------------------------------------------------------------------------*/}
          <div className="relative block bg-white w-full rounded-md shadow-sm border-gray-300 text-center focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
            <div className="grid grid-cols-4">
              <div className="p-8 border border-gray-100">
                <Button buttonSize="xs">Button XS</Button>
              </div>
              <div className="p-8 border border-gray-100">
                <Button buttonSize="sm">Button SM</Button>
              </div>
              <div className="p-8 border border-gray-100">
                <Button buttonSize="md">Button MD</Button>
              </div>
              <div className="p-8 border border-gray-100">
                <Button buttonSize="lg">Button LG</Button>
              </div>
              <div className="p-8 border border-gray-100">
                <Button buttonStyle="secondary" buttonSize="xs">
                  Button XS
                </Button>
              </div>
              <div className="p-8 border border-gray-100">
                <Button buttonStyle="secondary" buttonSize="sm">
                  Button SM
                </Button>
              </div>
              <div className="p-8 border border-gray-100">
                <Button buttonStyle="secondary" buttonSize="md">
                  Button MD
                </Button>
              </div>
              <div className="p-8 border border-gray-100">
                <Button buttonStyle="secondary" buttonSize="lg">
                  Button LG
                </Button>
              </div>
              {/* outline */}
              <div className="p-8 border border-gray-100">
                <Button buttonStyle="outlined" buttonSize="xs">
                  Button XS
                </Button>
              </div>
              <div className="p-8 border border-gray-100">
                <Button buttonStyle="outlined" buttonSize="sm">
                  Button SM
                </Button>
              </div>
              <div className="p-8 border border-gray-100">
                <Button buttonStyle="outlined" buttonSize="md">
                  Button MD
                </Button>
              </div>
              <div className="p-8 border border-gray-100">
                <Button buttonStyle="outlined" buttonSize="lg">
                  Button LG
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
