import React,{ Fragment, useState, useContext, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CalendarIcon, ChartBarIcon, FolderIcon, HomeIcon, InboxIcon, UsersIcon, XMarkIcon } from "@heroicons/react/24/outline";
import Sidebar from "../../Partials/Sidebar";
import Topbar from "../../Partials/Topbar";
import SlideOverMenu from "../SlideOverMenu";
import { AppDataContext, classNames } from "../../data/AppHelpers";
import { TOOLS } from "../../data/tools";

export default function MainLayout(props) {
  const [sideBarOpen, setSidebarOpen] = useState(false);
  const {appDataContext} = useContext(AppDataContext);

  useEffect(() => {
    function handleResize() {
      if(appDataContext.isDependencyLoaded) {
        const body = document.body;
        const html = document.getElementById('root');
        const screenHeight = Math.max(body.scrollHeight, body.offsetHeight,
          html.clientHeight, html.scrollHeight, html.offsetHeight);

        /* global SM */
        SM.client('resizeWindow', {height: screenHeight});
      }
    }
    window.addEventListener('resize', handleResize)
  })

  return (
    <>
      <Transition.Root show={sideBarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 md:hidden"
          onClose={() => {
            setSidebarOpen(true);
          }}
        >
          <Transition.Child as={Fragment} enter="transition-opacity ease-linear duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="transition-opacity ease-linear duration-300" leaveFrom="opacity-100" leaveTo="opacity-0">
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child as={Fragment} enter="transition ease-in-out duration-300 transform" enterFrom="-translate-x-full" enterTo="translate-x-0" leave="transition ease-in-out duration-300 transform" leaveFrom="translate-x-0" leaveTo="-translate-x-full">
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white">
                <Transition.Child as={Fragment} enter="ease-in-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in-out duration-300" leaveFrom="opacity-100" leaveTo="opacity-0">
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button type="button" className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" onClick={() => this.setSidebarOpen(false)}>
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4"></div>
                <div className="flex flex-shrink-0 border-t border-gray-200 p-4">
                  <a href="#" className="group block flex-shrink-0">
                    <div className="flex items-center">
                      <div>
                        <img className="inline-block h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
                      </div>
                      <div className="ml-3">
                        <p className="text-base font-medium text-gray-700 group-hover:text-gray-900">{appDataContext.user.niceName}</p>
                        <p className="text-sm font-medium text-gray-500 group-hover:text-gray-700">View profile</p>
                      </div>
                    </div>
                  </a>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0">{/* Force sidebar to shrink to fit close icon */}</div>
          </div>
        </Dialog>
      </Transition.Root>

      <div className={classNames(props.user.type !== "semrush" && "h-[calc(100vh)]", "flex flex-col")}>
        {/* TOP BAR */}
        {/* <Topbar user={appDataContext.user}></Topbar> */}
        <div className="flex flex-1">
          <aside className="hidden sm:block overflow-y-auto">
            <Sidebar account={appDataContext.account} user={appDataContext.user}></Sidebar>
          </aside>

          {/* Dashboard home */}
          <main className="flex overflow-x-hidden flex-1 bg-gray-100 dark:bg-black paragraph px-4 h-min">
            <div className="py-6 w-full">{props.children}</div>
          </main>
        </div>
      </div>
    </>
  );
}
